import { MultipleButtonProps } from 'components/form/fields/multiple/MultipleTableRowActions';
import { addRowAt } from 'components/form/fields/utils/multiple-utils';
import { setStepIconTabIndices } from 'components/form/utils/task-utils';
import { useFieldInfo } from 'hooks/field';
import { useValues } from 'hooks/values';
import { useTranslation } from 'react-i18next';

import { Add as AddIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

const AddRowButton = ({rows, index, setSelected, selected}: MultipleButtonProps) => {
  const { augProps, info } = useFieldInfo()
  const { createValues }   = useValues()
  const { t }              = useTranslation()

  function getNewRow() {
    const row = {...createValues(info.rpath, false, false), __original_index: undefined }
    return row
  }

  const handleClick = () => {
    const at = index + 1
    const newRows = addRowAt(rows, getNewRow(), at)

    augProps.setValue(newRows)
    const newSelected = selected.map(id => id >= at ? id+1 : id) // adjust all ids higher than id
    setSelected(newSelected)
    setStepIconTabIndices(1000)
  }

  return (
    <Tooltip disableInteractive title={t('multiple.addrow')}>
      <span>
        <IconButton tabIndex={-1} id={'row-' + index + '-add-button'} size="small" onClick={handleClick} >
          <AddIcon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default AddRowButton
