import { Autocomplete, TextField } from "@mui/material"
import { useTranslation }          from "react-i18next"
import { useConfig }               from "hooks/config"
import { selectOption }            from "utils/option-utils"

const ThemeSelector = () => {
  const { t }     = useTranslation()
  const { props } = useConfig()
  
  const { themes, addDefaultTheme, themeKey, setThemeKey } = props
  const includeDefault = addDefaultTheme || themeKey == 'default'
  const selectableThemes = includeDefault ? themes : themes.filter(theme => theme.key != 'default')
  const options = selectableThemes.map(theme => ({value: theme.key, label: theme.label}))

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.value === value?.value}
      options={options}
      style={{ width: 300 }}
      value={selectOption(options, themeKey)}
      autoHighlight
      disableClearable
      onChange={(event, newOption) => {
        event.preventDefault()
        event.stopPropagation()
        setThemeKey(newOption.value)
      }}

      renderInput={(params) => (
        <TextField autoComplete='new-password' {...params} label={t('theme')} fullWidth />
      )}
    />
  )
}

export default ThemeSelector