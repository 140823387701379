import _ from 'lodash'

export function decomposeRefPath(path) {
  const domains = _.isString(path) ? path.split(":") : []
  const key     = domains.pop()

  return {
    domain: _.first(domains),
    domains,
    key
  }
}

export function decomposeKeyPath(path) {
  const elements = _.isString(path) ? path.split(".") : []
  const head     = elements.find(() => true)
  const tail     = elements.slice(1).join('.')

  return { 
    elements,
    head,
    tail
  }

} 

