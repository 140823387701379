import { Box, Divider, Typography } from '@mui/material';
import { useTranslator }            from 'hooks/translator';
import { F }                        from 'helpers/formatter';

const ListSectionTitle = ({ schema, section }) => {
  const { translator } = useTranslator()

  //const label = translator.toListTitle(section.key, section.label)
  const label = translator.translate(
    `${schema.translationBaseKey}.sections.${section.key}`,
    { user: true, fallback: section.label, format: F.toTitleCase }
  )

  return (
    <>
      <Box sx={{padding: '8px'}} >
        <Typography variant="h3">
          {label}
        </Typography>
      </Box>
      <Divider/>
    </>
  )
}

export default ListSectionTitle
