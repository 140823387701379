import HandleResult from 'components/common/HandleResult';
import PageLoader from 'components/common/PageLoader';
import StickyBannerLayout from 'components/layout/common/StickyBannerLayout';
import ProcessOptionBar from 'components/process/ProcessOptionBar';
import ProcessRenderer from 'components/process/ProcessRenderer';
import ProcessRenderProvider from 'contexts/ProcessRenderContext';
import { useConfig } from 'hooks/config';
import { ProcessInfo, useTranslatedProcesses } from 'hooks/process';
import { useProcessRenderContext } from 'hooks/render';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Start = () => {
  const { t }                          = useTranslation()
  const { project }                    = useConfig()
  const {result, loading, processInfo} = useTranslatedProcesses()

  return (
    <>
      <Helmet>
        <title>{t('menu.start')} | {project}</title>
      </Helmet>
      <HandleResult result={result} >
        <PageLoader size='large' loading={loading}>
          <ProcessPage processInfo={processInfo!} />
        </PageLoader>
      </HandleResult>
    </>
  )
}

const ProcessPage = ({processInfo}: {processInfo: ProcessInfo}) => {
  return (
    <ProcessRenderProvider processInfo={processInfo}>
      <ProcessBaseRenderer/>
    </ProcessRenderProvider>
  )
}

const ProcessBaseRenderer = () => {
  const { processRendering } = useProcessRenderContext()

  return (
    <StickyBannerLayout
      banner={<ProcessOptionBar />}
      bodySx={{ maxHeight: processRendering == 'table' ? "100%" : undefined, overflow: processRendering == 'table' ? "auto" : undefined }}
    >
      <ProcessRenderer />
    </StickyBannerLayout>
  )
}

export default Start;
