import Settings from "helpers/settings"

export function createDetailCrumbKey(detail, id) {
  return `crumbs.${detail}.${id}`
}

export function createDetailUrl(detail, id) {
  return `/gears/details/${detail}/${id}`
}

export function getCurrentDetailCrumbs(detail, id) {
  return detail ? Settings.session.read(createDetailCrumbKey(detail, id), [])  : []
}

