import { SetState } from 'types/react';
import { TableCell } from '@mui/material';

import { CopyRowButton, DeleteRowButton } from 'components/form/fields/multiple';
import AddRowButton from 'components/form/fields/multiple/AddRowButton';
import { MultipleRows, MultipleSelected } from 'types/multiple';
import { MultipleFeatures } from '../InputMultipleField';

export interface MultipleButtonProps {
  index: number
  rows: MultipleRows
  selected: MultipleSelected
  setSelected: SetState<MultipleSelected>
}

export interface MultipleTableRowActionsProps extends MultipleButtonProps {
  features: MultipleFeatures
}

const MultipleTableRowActions = (props: MultipleTableRowActionsProps) => {
  const { add, remove } = props.features
  return add || remove ? (
    <TableCell align={"right"} key={"actions"}  style={{width: "1%", whiteSpace: 'nowrap', padding: 0, paddingRight: "8px"}} >
      {add ? <>
        <AddRowButton    {...props} />
        <CopyRowButton   {...props} />
      </> : null}
      {remove ? (
        <DeleteRowButton {...props} />
      ) : null}
    </TableCell>
  ) : null
}

export default MultipleTableRowActions
