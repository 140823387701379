import { Field, Trait, TraitName } from 'types/graphql';

export class TraitHelper {
  static hasTrait(fields: Field[] | Field, traitName: TraitName): boolean {
    if (Array.isArray(fields)) {
      return fields.some(field => {
        const hit = TraitHelper.containsTrait(field?.traits, traitName)
        return hit || ((field.fields && TraitHelper.hasTrait(field.fields, traitName)))
      })
    }
    else {
      return TraitHelper.hasTrait([fields], traitName)
    }
  }

  static containsTrait(traits: Trait[] | undefined, traitName: TraitName): boolean {
    const t = Array.isArray(traits) ? traits : []
    return t.some(trait => trait.name.startsWith(traitName))
  }

};
