import { useState } from 'react';

import { Box, SxProps } from '@mui/material';

import { WindowManagerProvider } from '../contexts/WindowManagerContext';
import { WindowManagerConfig, WindowProps } from '../types';
import { configToWindowProps } from '../utils';
import Window from './Window';

const WindowManager = (config: WindowManagerConfig): JSX.Element => {
  const [windows, setWindows] = useState<WindowProps[]>(configToWindowProps(config))

  if (windows.length > 1)
    window.alert("Multiple windows is not implemented yet")

  return (
    <WindowManagerProvider {...config}>
      <Box id='window-manager' sx={windowManagerSx} >
        <Box id='windows-container' sx={windowManagerContainerSx}>
          { windows
              .map( (windowProps, index) => 
                <Window key={index} index={index} {...windowProps}/>
              ) 
          }
        </Box>
      </Box>
    </WindowManagerProvider>
  )
}

const windowManagerSx: SxProps = {
  display: "flex", 
  width: "100%", 
  height: "100%", 
  maxHeight: "100%", 
  overflow: "auto", 
  justifyContent: "center",
  opacity: 1,
  '@keyframes slideInFromRight': {
    '0%': {
      transform: 'translateX(100%)'
    },
    '100%': {
      transform: 'translateX(0)'
    }
  },
  '@keyframes opacitySlide': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 0
    }
  },
  animation: "0.3s linear 0s 1 opacitySlide, 0.3s ease 0.3s 1 slideInFromRight",
} 

const windowManagerContainerSx: SxProps = {
  display: "flex", 
  width: "100%", 
  height: "100%"
} 

export default WindowManager
