import FirstPageIcon      from '@mui/icons-material/FirstPage';
import { useTranslation } from 'react-i18next';
import PageButton from 'components/table/page/PageButton';

const FirstPage = ({onClick, loading}) => {
  const { t }    = useTranslation()

  return <PageButton
    disabled={!onClick}
    onClick={onClick}
    loading={loading}
    tip={t('table.first')}
    Icon={FirstPageIcon}
  />
}

export default FirstPage