import { Box } from '@mui/material';

import Loader, { LoaderSize } from './Loader';

const CenteredLoader = ({size}: {size?: LoaderSize}) => (
  <Box sx={{display: "flex", flexGrow: 1, width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
		<Loader size={size}/>
  </Box>
);

export default CenteredLoader;