import { Box, Divider, Typography } from '@mui/material';
import { useTranslator } from 'hooks/translator';

const PageStats = ({data, pageable}) => {
  const { t } = useTranslator()
  const {rows, page, count} = data

  if (pageable) {
    const entriesStr  = `${(page.pageNum * page.pageSize) + 1}..${(page.pageNum * page.pageSize) + rows.length}`
    const pageStat    = t('table.stat', {variables: { entries: entriesStr, cnt: count}})
    const pageStatStr = count == 0 || !count ? entriesStr : pageStat

    return (
      <Box sx={{flexShrink: 0}}>
        <Divider/>
        <Box style={{display: "flex", flexDirvection: "row" }}>
          <Box style={{flexGrow: 1}}/>
          <Typography fontSize={'small'} sx={{color: "text.secondary", padding: "2px", paddingRight: "8px"}} fontWeight={500}>
            {pageStatStr}
          </Typography>
        </Box>
      </Box>
    )
  }
  else return null
}

export default PageStats
