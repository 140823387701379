import { useEffect, useRef, useState } from 'react';

export type TimerProps = {
  timer: NodeJS.Timer
  stop: () => void 
  disabled: boolean
}

const useCountDown = (seconds: number, onZero: () => void) => {
  const [counter, setCounter] = useState<number>(seconds);
  const ref = useRef<TimerProps>({disabled: false, stop: () => {}} as TimerProps)

  useEffect(() => {
    if (counter > 0) {
      ref.current.timer = setInterval(() => setCounter(counter - 1), 1000) 
      ref.current.stop  = () => clearInterval(ref.current.timer)

      return ref.current.stop
    } else {
      onZero()
    }
  }, [counter]);

  return {counter, reset: () => setCounter(seconds), ref }
}

export default useCountDown