import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation }           from 'react-i18next'
import { useDropzone }              from 'react-dropzone'
import { Delete as DeleteIcon }     from '@mui/icons-material'
import { Download as DownloadIcon } from '@mui/icons-material'
import {
  Box,
  Typography,
  Card,
  Tooltip,
  IconButton,
  FormControl,
  FormLabel,
  FormHelperText
} from '@mui/material'

import ContentPreview          from 'components/form/fields/content/ContentPreview'
import ContentViewer           from 'components/form/fields/content/ContentViewer'
import InputError              from 'components/form/fields/InputError'
import { validateField }       from 'components/form/utils/validate-utils'
import { fieldMinWidthStyle }  from 'components/form/utils/field-utils'
import { RenderValue }         from 'components/render'
import { useFieldInfo }        from 'hooks/field'
import { useNotifier }         from 'hooks/notification'
import { useFormInfo }         from 'hooks/form'
import { useConfig }           from 'hooks/config'
import { useAutoSubmitSignal } from 'hooks/autosubmit'
import { download }            from 'utils/download'
import { getExtension, removeExtension } from 'utils/files'
import Types from 'types/types'

const InputFileField = ({multiple}) => (
  <InputError>
    <InputFileFieldContent multiple={Boolean(multiple)}/>
  </InputError>
)

const InputFileFieldContent = ({multiple, ...props}) => {
  const { setFocus }                   = props
  const { augProps, fieldProps, info } = useFieldInfo()

  const createInitialValue = (value) => {
    const defaultMultipleValue = Array.isArray(value) ? value : []
    const defaultSingularValue = !Array.isArray(value) && value ? [value] : []
    const valueArray           = (multiple ? defaultMultipleValue : defaultSingularValue)

    return valueArray
      .map(Types.toContent)
      .filter(content => content != null)
  }

  const [contents, setContents]  = useState(() => createInitialValue(fieldProps.value))
  const notifier                 = useNotifier()
  const formInfo                 = useFormInfo()
  const [hover, setHover]        = useState(false)
  const {signal}                 = useAutoSubmitSignal()
  const {props: {taskRendering}} = useConfig()

  const getRuntimeValue = () => multiple ? contents : contents.length ? contents[0] : null

  // add preview to dropped files
  const onDrop = useCallback(acceptedFiles => {
    setContents(contents => {
      const isUnique = (file) => !contents.some(content => content.file?.name === file.name)

      // notify on bad drop
      acceptedFiles
        .forEach(file => {
          if (!isUnique(file))
            notifier.error(`File '${file.name}' is already in the list`)
        })

      const newFiles = acceptedFiles.filter(isUnique)
      if (multiple)
        return [...contents, ...newFiles.map(file=> Types.toContent(file,{type: 'local'} ))]
      else {
        if (contents.length > 0)
          notifier.info("You can only add 1 file")

        return newFiles.map(file => Types.toContent(file, {type: 'local'}))
      }
    })
  }, [])

  const {
    getRootProps,
    getInputProps,
    fileRejections,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({onDrop,  validator: validator, ...(multiple ? {} : {maxFiles:1})})

  const fieldSx = useStyles({isDragActive, isDragAccept, isDragAccept, isDragReject, isFocused, isError: augProps.error, inMultiple: info.inMultiple})

  // make sure to revoke the data uris to avoid memory leaks; will run on unmount
  useEffect(() => {
    return () => contents.forEach(revoke);
  }, []);

  // show custom errors as notifications
  useEffect(() => {
    fileRejections
      .forEach(rejection =>
        rejection.errors
          .forEach(error => notifier.error("Upload failed for " + rejection.file.path + ": " + error.message))
      )
  }, [fileRejections])

  function handleValidate(e) {
    if (!augProps.touched)
      return

    const value = getRuntimeValue()
    const type = multiple ? "multiple file" : "file"
    augProps.setError(validateField(type, fieldProps.required, e, value))
  }

  const dndProps = getRootProps()

  function handleChange (e) {
    fieldProps.onChange(e)
    handleValidate(e)
	}

  // on change
  useEffect(() => {
    const value = getRuntimeValue()
    handleChange(null)
    augProps.setValue(value)
  }, [contents])

  function handleBlur (e) {
    setFocus(false)
    fieldProps.onBlur(e)
    dndProps.onBlur(e)
    handleValidate(e)
    signal()
  }

  function handleFocus(e) {
    setFocus(true)
    fieldProps.onFocus(e)
    dndProps.onFocus(e)
  }

  const dndRootProps = {
    ...dndProps,
    onBlur: handleBlur,
    onFocus: handleFocus
  }

  const style = taskRendering == 'standard' ? fieldMinWidthStyle(formInfo, info.field) : undefined
  return (
    <>
      { !info.inMultiple ? <Box sx={{mt:2}}/> : null }
      <FormControl id={fieldProps.id} component="fieldset" required={fieldProps.required} error={fieldProps.error} sx={{...style, width: "100%"}}>
        {!info.inMultiple ? <FormLabel component="legend">{fieldProps.label}</FormLabel> : null}
        <Box {...dndRootProps}>
          <Card elevation={0} sx={fieldSx} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
            <input {...getInputProps()} />
            <InputMessage isDragActive={isDragActive} hover={hover} contents={contents}/>
            <Files contents={contents} setContents={setContents} />
          </Card>
        </Box>
        { !info.inMultiple ? <FormHelperText >{fieldProps.error ? fieldProps.helperText : " "}</FormHelperText> : null }
      </FormControl>
    </>
  )
}

const InputMessage = ({isDragActive, hover, contents}) => {
  const { t }          = useTranslation()
  const { fieldProps } = useFieldInfo()

  if (contents.length > 0)
    return null

  const dropPlaceholder = contents.length > 1 ? t("placeholder.MULTIPLE FILE DROP") : t("placeholder.FILE DROP")
  const placeholder     = isDragActive ? dropPlaceholder : hover ? fieldProps.placeholder : ""
  return (
    <Box height={"30px"} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
      <Typography sx={{userSelect: "none"}}>
        { placeholder }
      </Typography>
    </Box>
  )
}

const revoke = (file) => URL.revokeObjectURL(file.preview)

const DownloadFile = ({file}) => {
  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    download(file.path, file.name)
  }

  return (
    <Tooltip disableInteractive title={"download"}>
      <span>
        <IconButton tabIndex={-1} size="small" onClick={onClick}  >
          <DownloadIcon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const DeleteFile = ({index, setContents}) => {
  const removeFile = (contents, index) => {
    revoke(contents[index])
    return contents.slice(0, index).concat(contents.slice(index+1))
  }

  const onClick = (e) => {
    setContents(contents => removeFile(contents, index))
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <Tooltip disableInteractive title={"remove file"}>
      <span>
        <IconButton tabIndex={-1} size="small" onClick={onClick}  >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const Files = ({contents, setContents}) => (
  contents.map((content, index) => 
    <File setContents={setContents} content={content} key={index} index={index}/>
  )
)

const File = ({content, setContents, index}) => {
  const [open, setOpen] = useState(null)

  return (
    <Box key={"file_" + index} sx={{ maxHeight: "30px", marginTop: index ? "5px" : "0",  height: "30px", display: "flex", flexFlow: "row", width: "100%", alignItems: "center" }}>
      <Box sx={{ flexGrow: 1, flexShrink: 1, display: "flex", flexFlow: "row", alignItems: "center", minWidth: 0, }}>
        <Box
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setOpen(e)
          }}
          key={"file_" + index}
          sx={{ minWidth: 0, flexShrink: 1, cursor: 'pointer', display: "flex", flexFlow: "row", alignItems: "center" }}
        >
          <Box style={{ marginLeft: "5px", marginRight: "5px", display: "flex", flexShrink: 0, alignItems: "center", minWidth: "20px", width: "20px", height: "30px", maxHeight: "30px", overflow: "hidden" }}>
            <ContentViewer  open={open} setOpen={setOpen} content={content} />
            <ContentPreview content={content} />
          </Box>
          <Box sx={{ flexShrink: 1, minWidth: 0, display: "flex", flexDirection: "row", alignItems: "center"}}>
            <RenderValue value={{__kind: 'filename', __value: content.filename}} />
          </Box>
        </Box>
      </Box>

      <Box sx={{ flexShrink: 0, display: "flex", flexDirection: "row" }}>
        <DeleteFile index={index} setContents={setContents} />
      </Box>
    </Box>
  )}

const validator = (file) => {
  const maxMb   = 20
  const maxSize = 1000000 * maxMb

  if (file.size > maxSize) {
    return {
      code: "file-too-large",
      message: `Maximum file size of ${maxMb}Mb exceeded`
    };
  }

  return null
}

const useStyles = (props) => ({
  marginTop: props.inMultiple ? "0px" : "10px",
  padding: props.inMultiple ?  "4px 5px 4px 14px" : "13.5px 5px 13.5px 14px",
  width: "100%",
  cursor: "text",
  background:  theme => getBackgroundColor(theme, props),
  borderStyle: props.isDragActive || props.isFocused ? 'solid' : 'dashed',
  borderWidth: "1px",
  borderColor: theme => getBorderColor(theme, props),
  //transition: "all .2s ease-in-out",
  "&:hover": {
    backgroundColor: 'rgb(0, 0, 0, 0.10)',
    borderColor:     "#000000",
    borderStyle:     "solid"
  }
})

const getBorderColor = (theme, props) => {
  if (props.isError)
    return theme.palette.error.main;
  if (props.isDragAccept)
    return theme.palette.success.main;
  if (props.isDragReject)
    return theme.palette.error.dark;
  if (props.isFocused || props.isDragActive)
    return theme.palette.primary.main;

  return 'rgb(150, 150, 150)' // theme.palette.divider is too light
}

const getBackgroundColor = (theme, props) => {
  if (props.isDragAccept)
    return theme.palette.success.light;
  if (props.isDragReject)
    return theme.palette.error.light;
  if (props.isFocused)
    return 'rgb(0, 0, 0, 0.15)'

  return theme.palette.background.default;
}

function augmentFile(file) {
  const url    = URL.createObjectURL(file)
  const fields = {
    preview: url,
    extension: getExtension(file.name),
    __id: removeExtension(file.name)
  }
  return Object.assign(file, fields)
}

export default InputFileField
