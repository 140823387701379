import { CircularProgressWithLabel } from 'components/common/TimedLoader';
import { useFormikContext } from 'formik';
import { AUTOSUBMIT_TIME } from 'helpers/environment';
import { TraitHelper } from 'helpers/traits';
import { useAutoSubmitReceiver } from 'hooks/autosubmit';
import useCountDown from 'hooks/counter';
import { useFormInfo } from 'hooks/form';
import { useSubmitting } from 'hooks/submitting';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SetState } from 'types/react';
import { isEmptyValue } from 'utils/utils';

import { Send as SubmitIcon } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, SxProps } from '@mui/material';

type SubmitButtonProps = {
  sx: SxProps
  onClick: (e?: React.SyntheticEvent) => void
  loading: boolean
  disableIcon?: boolean
}

export const SubmitButton = ({sx, onClick, loading, disableIcon}: SubmitButtonProps) => {
  const { t }                               = useTranslation()
  const [showAutoSubmit, setShowAutoSubmit] = useState(false)

  return (
    <Box sx={{flexGrow: 1, display: "flex", flexDirection: "row", alignItems: "center"}}>
      <LoadingButton
        id='submit-form-button'
        loading={loading}
        loadingPosition="end"
        endIcon={disableIcon ? undefined : <SubmitIcon />}
        color="primary"
        fullWidth
        size="large"
        type="submit"
        onClick={(e) => {setShowAutoSubmit(false); onClick(e)}}
        variant="contained"
        sx={sx}
      >
        {t('submit')}
      </LoadingButton>
      <AutoSubmitter showAutoSubmit={showAutoSubmit} setShowAutoSubmit={setShowAutoSubmit} onClick={onClick}/>
    </Box>
  )
}

type AutoSbumitterProps = {
  onClick: (e?: React.SyntheticEvent) => void
  showAutoSubmit: boolean
  setShowAutoSubmit: SetState<boolean>
}

export const AutoSubmitter = ({onClick, showAutoSubmit, setShowAutoSubmit}: AutoSbumitterProps) => {
  // NOTE: auto submit is not (yet) implemented for multiple
  const formInfo     = useFormInfo()
  const autoSubmit   = useMemo(() => TraitHelper.hasTrait(formInfo.form.fields, "autosubmit"),[formInfo])
  const directSubmit = useMemo(() => TraitHelper.hasTrait(formInfo.form.fields, "directsubmit"), [formInfo])
  const {content}    = useAutoSubmitReceiver()
  const formik       = useFormikContext()
  const {submitting} = useSubmitting()
  const enable       = autoSubmit || directSubmit

  useEffect(() => {
    if (!autoSubmit && !directSubmit)
      return 

    const fields      = formInfo.form.fields || []
    const isFilled    = fields.every(field => field.optional || !isEmptyValue(_.get(formik.values, field.path)))
    const isAllFilled = fields.every(field => !isEmptyValue(_.get(formik.values, field.path)))
 
    if ((directSubmit && isAllFilled) || (autoSubmit && isFilled))
      setShowAutoSubmit(true)
    else 
      setShowAutoSubmit(false)
  }, [content])  

  if (!submitting && enable && showAutoSubmit)
    return <SubmitCounter count={directSubmit && !autoSubmit ? 0 : AUTOSUBMIT_TIME} onClick={onClick} hideCounter={() => setShowAutoSubmit(false)}/>;
  else
    return <React.Fragment />
}

type SubmitCounterProps = {
  onClick: () => void
  count: number
  hideCounter: () => void
}

const SubmitCounter = (props: SubmitCounterProps) => {
  if (props.count == 0)
    return <DirectCounter {...props} />
  else 
    return <Counter {...props} />
}

const DirectCounter = ({onClick}: SubmitCounterProps) => {
  useEffect(() => {
    onClick()    
  },[])

  return <React.Fragment/>
}

const Counter = ({onClick, hideCounter, count}: SubmitCounterProps ) => {
  const { reset, counter } = useCountDown(count, () => {onClick(); hideCounter()} )

  const resetTimer = () => {
    reset()
  }

  useEffect(()=>{
    // @ts-ignore
    document.addEventListener('click', resetTimer)
    document.addEventListener('keyup', resetTimer)

    // @ts-ignore
    return () => {
      document.removeEventListener('keyup', resetTimer)
      document.removeEventListener('click', resetTimer)
    }
  }, [])

  return (
    <Box sx={{ display: "flex", height: "fit-content", paddingLeft: "8px" }}>
      <CircularProgressWithLabel value={Math.round((counter / count) * 100)} label={`${counter}`} circleSize="1.8rem" />
    </Box>
  )
}

