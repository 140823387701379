// adjust css of img by wrapping it with:
// <Box sx={{ '& > img': { mr: 2, height: ... } }} >...</Box>

const Logo = (props) => (
  <img
    alt="Logo"
    {...props}
  />
);

export default Logo;
