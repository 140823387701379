import { useState } from 'react';
import { getProcessName, getSubject } from 'components/form/utils/form-utils';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Tooltip } from '@mui/material';
import ConfirmDialog from 'components/process/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { GET_MY_PROCESS_INSTANCES } from 'queries/process';
import { GET_ALL_PROCESS_INSTANCES } from 'queries/process';
import { useResponsiveColumns } from 'hooks/columns';
import LargeElevatedTable from 'components/tables/LargeElevatedTable';
import CustomRefProvider from 'contexts/RefContext';
import { toDisplayValue } from "components/render/utils/render";
import { useTranslator } from 'hooks/translator';
import { useStopProcess } from 'hooks/process';
import {useApolloClient} from "@apollo/client";
import {createDataLoader} from "../../utils/table";

const ActiveProcessesTable = ({ mode }) => {
  const client = useApolloClient()
  const columns = useColumns()

  const requestInfo = mode === 'all' ? {
    variables: {},
    query:     GET_ALL_PROCESS_INSTANCES,
    dataPath:  'data.allActiveProcessInstances',
    countPath: 'data.allActiveProcessInstancesCount',
  } : {
    variables: {},
    query:     GET_MY_PROCESS_INSTANCES,
    dataPath:  'data.myActiveProcessInstances',
    countPath: 'data.myActiveProcessInstancesCount',
  }


  return (
    <CustomRefProvider>{({getCurrent}) => {
      const deleteLine = (id) => getCurrent().deleteLine(id)
      const stopProcess = useStopProcess(deleteLine)
      const {translator} = useTranslator()
      const dataLoader = createDataLoader(client, requestInfo, (row) => toTableRowData(translator, row, stopProcess))

      return (
        <LargeElevatedTable
          description="active processes"
          filterType="local"
          columns={columns}
          dataLoader={dataLoader}
        />
      )}
    }
    </CustomRefProvider>
  )
}

const useColumns = () => {
  const { t } = useTranslation()

  const columns = {
    lg: [
      {
        headerName: t('starter'),
        field: "starter",
        cellProps: {
          width: 200,
          align: "right"
        }
      }
    ],
    md: [
      {
        headerName: t('subject'),
        field: "subject",
        cellProps: {
          width: 100,
          align: "left"
        }
      },
      {
        headerName: t('started'),
        field: "started",
        cellProps: {
          width: 200,
          align: "right"
        }
      }
    ],
    default: [
      {
        headerName: t('process_name'),
        field: "processName",
        cellProps: {
          width: 350,
          align: "left"
        }
      }
    ],
    end: [
      {
        headerName: '',
        field: "kill",
        cellProps: {
          align: 'right',
          sx: { padding: 0, px: '8px', width: '40px' }
        }
      }
    ]
  }

  return useResponsiveColumns(columns)
}

/* convert a tasks to row data structure */
function toTableRowData(translator, processInstance, stopProcess) {
  return {
    id: processInstance.id,
    cells: {
      "processName": getProcessName(translator, processInstance),
      "starter":     processInstance.starter?.username,
      "started":     toDisplayValue(processInstance.startTime),
      "subject":     getSubject(processInstance),
      "kill":        (<KillButton processInstance={processInstance} stopProcess={stopProcess}/>)
    }
  }
}

const KillButton = ({processInstance, stopProcess}) => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation()

  return (
    <>
      <Tooltip disableInteractive title={t('processes.kill')}>
        <IconButton id={'process-' + processInstance.id + '-start-kill-button'} onClick={() => setOpen(true)} >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ConfirmDialog processInstance={processInstance} open={open} setOpen={setOpen} onClick={() => stopProcess(processInstance.id)}/>
    </>
  )
}

export default ActiveProcessesTable
