import { RenderTableRowCells } from 'components/render';
import { useFieldInfo } from 'hooks/field';
import { MultipleRow } from 'types/multiple';

const MultipleTableBasedOnCells = ({index, row}: {index: number, row: MultipleRow}) => {
  const { info } = useFieldInfo()
  const mode = info.field.mode

  const closedBasedOn = row?.__basedOn 
  if (mode === "closed" && closedBasedOn != undefined)
    return <RenderTableRowCells index={index} basedOn={closedBasedOn} />
  else 
    return null
}

export default MultipleTableBasedOnCells