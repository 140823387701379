import InputField from 'components/form/fields/InputField';
import { useFieldInfo } from 'hooks/field';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { TableCell } from '@mui/material';

const MultipleTableFieldCells = ({index}: {index: number}) => {
  const { info, info: {indices} } = useFieldInfo()
  const newIndices = { ...indices, [info.field.elementName || 0]: index }

  return (
    <React.Fragment>
      {
        info.field.fields?.map((field, index) => (
          <TableCell key={"field_" + index} sx={{ paddingLeft: "3px", paddingRight: "3px", width: 0 }}>
            <InputField
              field={field}
              indices={newIndices}
            />
          </TableCell>
        ))
      }
    </React.Fragment>
  )
}

MultipleTableFieldCells.propTypes = {
  row        : PropTypes.shape({
    id: PropTypes.number.isRequired,
    values: PropTypes.object.isRequired
  }),
}
export default MultipleTableFieldCells
