import CenteredLoader from 'components/common/CenteredLoader';
import React from 'react';

import { LoaderSize } from './Loader';

type PageLoaderProps = {
  size?: LoaderSize
  loading: boolean
  children: React.ReactNode
}

const PageLoader = ({size, loading, children}: PageLoaderProps): JSX.Element => {
  if (loading) {
    return <CenteredLoader size={size}/>
  } else {
    return <React.Fragment>{children}</React.Fragment>
  }
}

export default PageLoader