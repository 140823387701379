import React                    from 'react';
import { createContext }  			from 'react';
import { SnackbarProvider } 		from 'notistack';
import { Collapse, IconButton } from '@mui/material';
import { Close as CloseIcon }		from '@mui/icons-material';

export const NotificationContext = createContext();

const NotificationProvider = ({children, ...props}) => {
  const notistackRef = React.createRef();
  
  const onClickDismiss = key => () => { 
    notistackRef.current.closeSnackbar(key);
  }
	
  return (
    <NotificationContext.Provider value={{ref: notistackRef, dismiss: onClickDismiss}}>
      <SnackbarProvider 
        preventDuplicate
			  anchorOrigin={{
			  	vertical: 'bottom',
			  	horizontal: 'right',
			  }}
			  TransitionComponent={Collapse}
			  maxSnack={5} 
			  ref={notistackRef}
				action={(key) => (
          <IconButton 
            id={'close-' + key} 
            size="small"  
            onClick={onClickDismiss(key)}
            iconstyle={{width: '20px', height: '20px'}}
           > 
            <CloseIcon/>
          </IconButton>
				)}
        sx={{
          "& .SnackbarItem-action": {
            "& .MuiButtonBase-root": {
              color: "#ffffff"
            }
          }
        }}
			>
				{children}
			</SnackbarProvider>
    </NotificationContext.Provider>
  );
}

export default NotificationProvider;