import Loader from 'components/common/Loader';
import React from 'react';

import { SvgIconComponent } from '@mui/icons-material';
import { Box, IconButton, SxProps, Tooltip, TooltipProps } from '@mui/material';

type IconSize ='inherit' | 'large' | 'medium' | 'small' | undefined

type IconLoadingSize       = "micro" | "tiny" | "small" | "medium" | "large"
type IconButtonSize = 'small' | 'medium' | 'large'

type IconProps = Partial<{
  iconButtonSize: IconButtonSize,
  iconSize: IconSize
  sx: SxProps,
  loaderSize: IconLoadingSize,
}>

interface LoadingIconProps {
  icon: SvgIconComponent,
  iconProps?: IconProps
  tooltipProps?: LoadingTooltipProps,

  onClick: (e: React.SyntheticEvent) => void,
  loading?: boolean
}

interface LoadingTooltipProps {
  title: TooltipProps['title']
}

interface MaybeTooltip {
  children: React.ReactElement<any,any>,
  tooltipProps?: LoadingTooltipProps
}

const LoadingIcon = ({icon: Icon, iconProps, tooltipProps, loading, onClick}: LoadingIconProps): JSX.Element => {
  const iconSize       = iconProps?.iconSize       || "small"
  const iconButtonSize = iconProps?.iconButtonSize || "medium"
  const loaderSize     = iconProps?.loaderSize     || "micro"

  return (
    <MaybeTooltip tooltipProps={tooltipProps}>
      <IconButton tabIndex={-1} size={iconButtonSize} onClick={onClick} >
        <Icon   fontSize={iconSize} sx={{visibility: loading ? 'hidden': 'visible', ...iconProps?.sx}}/>
        { loading 
          ? <Box sx={{marginTop: "-2px", position: "absolute"}}> 
              <Loader size={loaderSize}/>
            </Box>
          : null 
        }
      </IconButton>
    </MaybeTooltip>
  )
} 

const MaybeTooltip = ({children, tooltipProps}: MaybeTooltip): JSX.Element => {
  return tooltipProps
    ? ( 
      <Tooltip disableInteractive {...tooltipProps}>
        <span>
          {children}
        </span>
      </Tooltip>
    )
    : children
}

export default LoadingIcon