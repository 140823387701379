import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Status = ({title, content}: {title?: string, content: JSX.Element | string | undefined}) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        pt: 6,
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center'

      }}
    >
      <Box>
        <Typography variant={'h2'}>
          {title}
        </Typography>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Typography fontSize={16} color={theme.palette.text.secondary}>
          {content}
        </Typography>
      </Box>
    </Box>
  )
}

export default Status