import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow, 
  Menu
} from '@mui/material';
import { useTranslation }            from 'react-i18next';
import { ArrowDropDown as InfoIcon } from '@mui/icons-material';
import { useState }                  from 'react';
import { toDisplayValue }            from "components/render/utils/render";


const MessageInfo = (props) => {
  const {t} = useTranslation()

  return (
    <Box display="flex" flexDirection={"row"}>
      <Typography sx={{padding: "8px", paddingRight: 0, paddingBottom: 0}} fontWeight={400} fontSize={12}>
        {t("messages.sender") + ": " + props.sender?.username || t("messages.unknown")}
      </Typography>
      <MessageInfoIcon {...props} />
    </Box>
  )
}

const MessageInfoIcon = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open                    = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip disableInteractive title={"Show info"}>
        <span>
          <IconButton sx={{padding: 0, paddingTop: "6px", marginLeft: "-2px"}} tabIndex={-1} size="small" onClick={handleClick}  >
            <InfoIcon fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={menuProps}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MessageDetails {...props} />
      </Menu>
    </>
  );
}

const  MessageDetails = (info) => {
	const { t }     = useTranslation()
	const cellStyle = {border: 'none', paddingTop: "3px", paddingBottom: "3px"}

  const rows = [
    {key: "messages.sender", field: "sender.username"},
    {key: "messages.recipient", field: "recipient.username"},
    {key: "messages.received", field: "received"},
    {key: "messages.read", field: "seen"},
    {key: "messages.subject", field: "subject"}
  ]

  const data = {...info,
    received: info.publishedAt ? toDisplayValue(info.publishedAt) : "",
    seen: info.readAt ? toDisplayValue(info.readAt) : "" 
  }

	return (
      <Table sx={{ minWidth: 300 }} size="small" >
        <TableBody>
          {rows.map((row, index) => (
            <TableRow  key={index} sx={{border:0}}>
              <TableCell align="right" component="th" sx={{...cellStyle, paddingRight: 0}}>
                <Typography fontWeight={400} fontSize={14}>
                  {t(row.key)}:
                </Typography>
              </TableCell> 
              <TableCell align="left" sx={cellStyle}>
                <Typography fontSize={14}>
                  {_.get(data, row.field)}
                </Typography>
              </TableCell> 
            </TableRow>

          )
        )}
        </TableBody>
      </Table>
  );
}

const menuProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 0.5,
    ml: -0.6,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 10,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
}

export default MessageInfo