import { Helmet }         from 'react-helmet'
import { useTranslation } from 'react-i18next'
import GroupTasksTable    from 'components/tables/GroupTasksTable'
import PageContainer      from 'components/common/PageContainer'
import { useConfig }      from 'hooks/config'

const GroupTasks = () => {
  const { t }       = useTranslation()
  const { project } = useConfig()

  return <>
    <Helmet>
      <title>{t('menu.tasks.group')} | {project}</title>
    </Helmet>
    <PageContainer>
      <GroupTasksTable/>
    </PageContainer>
  </>
}

export default GroupTasks;
