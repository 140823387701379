import { CopyButton } from 'components/form/fields/multiple';
import { MultipleButtonProps } from 'components/form/fields/multiple/MultipleTableRowActions';
import { copySelectedRows } from 'components/form/fields/utils/multiple-utils';
import { setStepIconTabIndices } from 'components/form/utils/task-utils';
import { useFieldInfo } from 'hooks/field';
import { useTranslation } from 'react-i18next';

const CopyRowButton = ({rows, index, selected, setSelected}: MultipleButtonProps) => {
  const { t }        = useTranslation()
  const { augProps } = useFieldInfo()

  const handleClick = () => {
    const at = index + 1
    const newRows = copySelectedRows(rows, [index], at)
    const newSelected = selected.map(id => id >= at ? id+1 : id) // adjust all ids higher than id
    augProps.setValue(newRows)
    setSelected([...newSelected, ...(selected.includes(at) ? [at] : [])])

    setStepIconTabIndices(1000)
  }

  return <CopyButton id={'row-' + index + '-copy-button'} title={t('multiple.copyrow')} onClick={handleClick} />
}

export default CopyRowButton
