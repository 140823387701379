import { Box } from '@mui/material';
import FileTile                from 'components/tile/FileTile';
import DownloadFilesButton from 'components/tile/DownloadFilesButton';

const FileTiles = ({attachments}) => {
  if (attachments?.length == 1) {
    return <Box sx={{margin: "20px"}}><FileTile attachment={attachments[0]} /> </Box>
  } else if (attachments?.length) {
    return (
      <>
        <Box sx={{padding: "5px", paddingBottom: 0, display: "flex", flexDirection: "row", width: "100%"}}>
          <Box sx={{flexGrow: 1}}/>
          <DownloadFilesButton attachments={attachments}/>
        </Box>
        <Box sx={{display: "flex", maxWidth: "100%", flexDirection: "row", alignItems: "flex-start", flexWrap: "wrap", marginRight: "20px"}}>
          { attachments.map((attachment, index) => (
              <Box sx={{marginLeft: "20px", marginBottom: "20px"}} key={index}> 
                <FileTile key={index} attachment={attachment} />
              </Box> 
            )) 
          }
        </Box>
      </>
    )
  } else return null
}

export default FileTiles