import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import TaskBackButton from 'components/common/TaskBackButton';
import { getTaskCrumbs } from 'components/form/utils/task-utils';
import { useFormInfo } from 'hooks/form';
import { useTranslator } from 'hooks/translator';

import { Box } from '@mui/material';

const TaskBreadcrumbs = () => {
  const formInfo       = useFormInfo()
  const { translator } = useTranslator()

  return (
    <Box sx={{padding: "15px", display: "flex", flexDirection: "row", alignItems: "center"}}>
      <TaskBackButton />
      <Breadcrumbs crumbs={getTaskCrumbs(translator, formInfo)} />
    </Box>
  )
}

export default TaskBreadcrumbs
