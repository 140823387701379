export function removeExtension(filename) {
  if (!filename)
    return filename

  const ext = getExtension(filename) 
  if (ext)
    return filename.slice(0, -1 * (ext.length + 1))
  else
    return filename
}

export function getExtension(filename) {
  if (filename) {
    return filename.substring(filename.lastIndexOf('.')+1, filename.length) 
  } else {
    return filename
  }
}