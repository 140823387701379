import * as React         from 'react';
import { useState }       from 'react';
import Box                from '@mui/material/Box';
import IconButton         from '@mui/material/IconButton';
import Tooltip            from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next'
import MainSettings       from 'components/layout/main/MainSettings';
import SettingsIcon       from '@mui/icons-material/Settings';
import { useTheme }       from '@mui/material/styles';

const MainIcon = ({handleClick}) => {
  const theme     = useTheme()
  const color     = theme.components.banner.color
  const iconStyle = { height: '32px', width: '32px' }
  const { t }     = useTranslation()

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
      <Tooltip disableInteractive title={t('settings')}>
        <IconButton id="settings-button" onClick={handleClick} size="small" sx={{color}} >
          <SettingsIcon style={iconStyle} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

const MainMenu = () => {
  const [ settingsOpen, setSettingsOpen] = useState(false)

  const handleClick = (event) => {
    setSettingsOpen(!settingsOpen)
  };

  return (
    <React.Fragment>
      <MainIcon handleClick={handleClick} />
      <MainSettings open={settingsOpen} setOpen={setSettingsOpen}/>
    </React.Fragment>
  );
}

export default MainMenu