import { isEmptyValue, isString } from "utils/utils"
import PropTypes                  from 'prop-types';
import {first, filter}            from "lodash";

export function selectOptionByLabel(options, label) {
  return first(filter(options, option => option.label === label))
}

export const isEqualOption = (option1, option2) => {
  return isEmptyValue(option1) && isEmptyValue(option2) ||
    (!isEmptyValue(option1) && !isEmptyValue(option2) && option1.value === option2.value)
}

export function selectOption(options, value) {
  return first(filter(options, option => option.value === value))
}

// we cannot store 'undefined' or 'null' with formik. We store value "", i.e., the empty string.
export const toFormikOptionValue = (option) => option === undefined || option === null ? "" : option.value

// material does not allow value 'undefined' for 'controlled' components, so that needs to be converted to 'null'
export const getMuiOption = (options, value) => {
  return value === null || value === undefined ? null : selectOption(options, value) || null
}

export const isSelectedOption = (option, selectedOptions) => selectedOptions.some(selected => selected.value == option.value)

export const filterSelectedOptions = (options, selectedOptions) =>
  Array.isArray(selectedOptions) ? options.filter(option => !isSelectedOption(option, selectedOptions)) : options

export const optionsConstraint = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired
}

export const filterDuplicateOptions = (options) => {
  if (!isEmptyValue(options) && Array.isArray(options) && options.length > 1) {
    const lastOption = options[options.length-1]
    if (options.filter(option => option.value === lastOption?.value).length > 1)
      return options.filter(option => option.value !== lastOption?.value)
  }

  return options
}

export const toRegex = (expression) => {
  const regexExpression = expression
    .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    .replace(/\\\s+/g, '.*')

  return new RegExp(regexExpression, "i")
}

export const filterOptionsByLabelWithExpression = (options, expression) => {
  if (isString(expression) && expression?.length > 0) {
    const regex = toRegex(expression)
    return options.filter(option => regex.test(option.label))
  } else return options
}

export const filterOptions = (options, selected, expression) =>
  filterOptionsByLabelWithExpression(filterSelectedOptions(options, selected), expression)

export const filterMuiOptions = (options, state) => {
  const expression = state && state.inputValue || ""
  return filterOptionsByLabelWithExpression(options, expression)
}

export function isOption(obj) {
  return obj ? obj.hasOwnProperty("value") && obj.hasOwnProperty("label") : false
}

export const toOption = (value) => !value ? null : isOption(value) ? value : ({value: value, label: value})

export function createRandomOptions(count, pageOffset ) {
  function random(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    for (let i = 0; i < length; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
  }
  const offset = pageOffset ? pageOffset : 0
  return Array.from(new Array(count)).map((value,index) => ({label: (offset + index).toString() + ' ' + random(Math.ceil(Math.random() * 30)), value: offset + index}))
}

