import Loader from 'components/common/Loader';
import React, { CSSProperties, ForwardedRef, Ref } from 'react';
// @ts-ignore
import { FixedSizeList as List } from 'react-window';
// @ts-ignore
import InfiniteLoader from 'react-window-infinite-loader';

import { Box } from '@mui/material';

const Listbox = React.forwardRef((props: any, ref: ForwardedRef<any>): JSX.Element => {
  const { children: items, hover, setHover, role, listboxProps, ...other } = props;
  const { highlightedIndex, isNextPageLoading, hasNextPage, loadNextPage } = listboxProps

  const actualCount         = Array.isArray(items) ? items.length : 0;
  const itemCount           = hasNextPage ? actualCount + 1 : actualCount;
  const loadMoreItems       = isNextPageLoading ? () => {} : loadNextPage;
  const isItemLoaded        = (index: number) => !hasNextPage || index < actualCount;
  const itemSize            = 36
  const height              = Math.min(8,itemCount) * itemSize

  const Row = ({index, style}: {style: CSSProperties, index: number}) => {
    if (!isItemLoaded(index))
      return <Loading style={style} />
    else
      return <Option style={style} option={items[index]} highlightedIndex={highlightedIndex} />
  }

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      threshold={5}
    >
      {({ onItemsRendered, ref: infiniteRef }: {onItemsRendered: any, ref: Ref<any>}) => (
        <Box ref={ref} {...other} sx={{padding: '0 !important', maxHeight: height + 20 + 'px !important'}} >
          <List
            ref={infiniteRef}
            height={height}
            width='100%'
            itemSize={itemSize}
            onItemsRendered={onItemsRendered}
            overscanCount={5}
            itemCount={itemCount}
            role={role}
          > 
            {Row}
          </List>
      </Box>
      )}
    </InfiniteLoader>
  )
})

const Loading = ({ style }: {style: CSSProperties}) => (
  <Box sx={{ ...style, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <Loader size='tiny' />
  </Box>
)

const Option = ({ option, style , highlightedIndex}: {option: any, style: CSSProperties, highlightedIndex: number}) => {
  const index = option.props['data-option-index']
  const className = index == highlightedIndex ? 'MuiAutocomplete-option Mui-focused' : 'MuiAutocomplete-option'

  return (
    <Box style={style} className={className} sx={{ padding: '0 !important' }}  >
      {React.cloneElement(option, { style: { height: style.height, width: style.width } })}
    </Box>
  )
}

export default Listbox
