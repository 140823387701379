import LoadingIcon from 'components/common/LoadingIcon';
import LayoutContextProvider, {
    ExpandAction, ExpandEnable, ExpandState, LayoutProps, SidebarKind, SidebarState
} from 'contexts/LayoutContext';
import { nextBannerState, useLayoutContext } from 'hooks/layout';
import useKeyboardShortcut, { keyboardCodeToChar } from 'hooks/shortcut';
import { useLocalTypeState } from 'hooks/state';
import { useTranslator } from 'hooks/translator';
import { useEffect, useRef, useState } from 'react';
import { DivProps } from 'types/react';

import ExpandIcon from '@mui/icons-material/ChevronLeft';
import { Box, useMediaQuery, useTheme } from '@mui/material';

const SidebarLayoutSidebar = (): JSX.Element => {
  const [sidebarState, setSidebarState] = useSidebarState()
  
  return ( 
    <LayoutContextProvider sidebarState={sidebarState} setSidebarState={setSidebarState}>
      <SidebarContent/>
    </LayoutContextProvider>
  )
}

const SidebarContent = (): JSX.Element => {
  const { props: { side, transitionTime } } = useLayoutContext() 
  const bannerSx = useBannerSx()
  
  return (
    <Box id='sidebar'
      sx={{
        display: "flex",
        flexShrink: 0,
        flexDirection: "column",
        boxShadow: side == 'left' ? "inset -1px 0px 7px 0px rgba(0, 0, 0, 0.4);" : "inset 1px 0px 7px 0px rgba(0, 0, 0, 0.4);",
        border: 0,
        transition: `all ${transitionTime} ease`,
      }}
    >
      <Box id='sidebar-banner'
        sx={{
          zIndex: 300,
          display: "flex",
          alignItems: "center",
          position: "relative",
          flexShrink: 0,
          overflow: "hidden",
          width: "100%",

          ...bannerSx,
        }}
      >
        <SidebarBanner />
      </Box>

      <Box id='sidebar-body'
        sx={{
          display: "flex",
          flexGrow: 1,
          flexShrink: 1,
          minHeight: 0,
          overflow: "hidden"
        }}
      >
          <SidebarBody />
      </Box>
    </Box>
  )
}

const SidebarBanner = ({}: DivProps): JSX.Element => {
  const { props: {sidebarBanner} } = useLayoutContext()

  return <>{sidebarBanner}</>
}

const SidebarBody = ({}: DivProps): JSX.Element => {
  const config = useLayoutContext()
  const { sidebarState, props: {side, sidebarBody, sidebarBodyFooter}, props: {transitionTime, expandedWidth}} = config
  const ref = useRef()
  const [height, setHeight] = useState("0px")

  useEffect(() => { 
    // @ts-ignore   
    setHeight(ref?.current?.clientHeight) 
  }, [])

  return (
    <Box 
      id='sidebar-body-content'
      sx={{
        flexGrow: 1,
        display: "flex", 
        flexDirection: "column",  
      }}
    >
      <Box 
        id='sidebar-overflow-container'
        sx={{
          display: "flex", 
          flexShrink: 1,
          flexGrow: 1, 
          transform: side == "left" ? "scaleX(-1)" : undefined,
          overflowY: sidebarState.expandState == "none" ? "hidden" : "auto",
          overflowX: "hidden"
        }}
      >
        <Box 
          id='sidebar-variable-width-container'
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            display: "flex",
            transform: side == "left" ? "scaleX(-1)" : undefined,
            width: getSidebarWidth(config),
            transition: `width ${transitionTime} ease`,
          }}
        >
          <Box 
            id='sidebar-fixed-width-container'
            sx={{
              display: "flex", 
              width: expandedWidth,
              minWidth: expandedWidth
            }}
          >
            {sidebarBody}
          </Box>
        </Box>
      </Box>
      <Box 
        id="permanent-sidebar-content"
        sx={{
          flexShrink: 0, 
          /* @ts-ignore */
          position: "relative", 
          width: "100%", 
          height: height
        }}
      >
        <Box ref={ref} sx={{left: 0, position: "absolute", width: "100%", overflow: "hidden"}}>
          {sidebarBodyFooter}
        </Box>
      </Box>
      <SidebarSlidingExpander/>
    </Box>
  )
}

export const BannerSlidingExpander = (): JSX.Element => {
  const {bannerState, props: { transitionTime }} = useLayoutContext()
 
  const getMargin = () => {
    switch(bannerState) {
      case "expanded": 
        return {
          marginTop: "-36px", 
        }

      case "collapsed": 
      case 'none':
        return {
          marginTop: "-10px",
          "&:hover": {
            transition: "margin 0.1s linear",
            marginTop: "0px"
        }
      }
    }
  }

  return (
    <Box sx={{ position: "relative", width: "36px", height: "100%", flexShrink: 0}}>
      <Box sx={{ position: "absolute", bottom: 0 }}>
        <Box
          sx={{
            position: "fixed",
            zIndex: 1000,
           // marginTop: bannerState == "none" ? "12px" : "-50px",
            transition: `margin ${transitionTime} ${transitionTime} linear`,
            ...getMargin()
          }}
        >
          <BannerExpander />
        </Box>
      </Box>
    </Box>
  )
}

export const BannerExpander = (): JSX.Element => {
  const { bannerProps: {shortcut, enabled}, bannerState, setBannerState, props: { transitionTime, side } } = useLayoutContext()
  const { t } = useTranslator()

  const handleClick = () => {
    setBannerState(state => nextBannerState(state, enabled))
  }
  
  const rotate = bannerState == "none" || bannerState == "collapsed"

  return <LoadingIcon 
    icon={ExpandIcon} 
    iconProps={{
      iconSize: 'small', 
      loaderSize: "small", 
      sx:{
        //color: bannerState == "none" ? undefined : theme.palette.grey[400],
        zIndex: 1000,
        transition: 'transform .2s linear', 
        transform: rotate ? 'rotate(270deg)' : 'rotate(90deg)',
        transitionDelay: transitionTime
      }
    }}
    tooltipProps={{
      title: (bannerState == "expanded" ? t('explorer.collapse') : t('explorer.expand')) + (shortcut ? ` ( CTRL-[ALT]-${keyboardCodeToChar(shortcut)} )`: "")
    }}
    onClick={handleClick} 
    loading={false} 
  />
}

const SidebarSlidingExpander = (): JSX.Element => {
  const {sidebarState, props: { transitionTime, side }} = useLayoutContext()

  const getMarginSx = () => {
    switch(sidebarState.expandState) {
      case "collapsed": 
      case "expanded": 
        return {
          marginLeft: side == "left" ? "-36px" : "0px", 
        }
      case 'none':
        return {
          marginLeft: side == "left" ? "-10px" : "-25px",
          "&:hover": {
            transition: "margin 0.1s linear",
            marginLeft: side == "left" ? "0px" : "-36px"
        }
      }
    }
  }
 
  return (
    <Box
      id="banner-expander-container"
      /* @ts-ignore */
      sx={{ flexShrink: 0, height: "36px", position: "relative"}}
    >
      <Box sx={{ position: "absolute", right: side == "left" ? 0 : undefined, left: side == "right" ? 0 : undefined }}>
        <Box
          sx={{
            zIndex: 1000,
            position: "fixed",
            transition: `margin ${transitionTime} ${transitionTime} linear`,
            ...getMarginSx()
          }}
        >
          <SidebarExpander />
        </Box>
      </Box>
    </Box>
  )
}

const SidebarExpander = (): JSX.Element => {
  const { sidebarProps: {shortcut, enabled}, sidebarState, setSidebarState, props: { transitionTime, side } } = useLayoutContext()
  const { t } = useTranslator()

  const expandAction = nextSidebarAction(sidebarState, enabled)
  const handleClick  = () => {
    setSidebarState(state =>  updateSidebarState(state, enabled, expandAction))
  }
 
  const rotate = side == "left" && expandAction == "expand" || side == "right" && expandAction == "collapse"

  return <LoadingIcon 
    icon={ExpandIcon} 
    iconProps={{
      iconSize: "small", 
      loaderSize:"small",
      sx:{
        zIndex: 1000,
        transition: 'transform .2s linear', 
        transform: rotate ? 'rotate(180deg)' : undefined,
        transitionDelay: transitionTime
      }
    }}
    tooltipProps={{
      title: (expandAction == "collapse" ? t('explorer.collapse') : t('explorer.expand')) + (shortcut ? ` ( CTRL-[ALT]-${keyboardCodeToChar(shortcut)} )`: "")
    }}

    onClick={handleClick} 
  />
}

const useSidebarState = () : [SidebarState, LayoutProps['setSidebarState']]=> {
  // @ts-ignore
  const sm = useMediaQuery(theme => theme?.breakpoints?.up('sm'));
  // @ts-ignore
  const lg = useMediaQuery(theme => theme?.breakpoints?.up('lg'));

  const { name, sidebarProps: { shortcut, enabled} } = useLayoutContext()
  const location = `${name}.sidebar`
  const {value: sidebarState, setValue: setSidebarState} = useLocalTypeState<SidebarState>(location, createSidebarExpand(sm, lg, enabled)) 

  if (shortcut){
    useKeyboardShortcut(
      () => { 
        setSidebarState(state => ({...state, expandState: "none"})) }, 
      {code: shortcut, ctrlKey: true, altKey: true}
    )

    useKeyboardShortcut(
      () => { setSidebarState(state => updateSidebarState(state, enabled, nextSidebarAction(state, enabled))) }, 
      {code: shortcut, ctrlKey: true}
    )
  }
  
  const initialRender = useRef(false)
  useEffect(() => {
    if (initialRender.current)
      setSidebarState(createSidebarExpand(sm, lg, enabled))
    
    initialRender.current = true
  }, [sm, lg])

  return [sidebarState, setSidebarState]
}

const useBannerSx = () => {
  const theme = useTheme()
  const { bannerState, bannerProps, props: { transitionTime, withBanner } } = useLayoutContext() 

  //@ts-ignore
  const bannerSx     = theme.components?.banner
  const bannerColor  = bannerSx?.backgroundColor || bannerSx?.background
  const bannerHeight = withBanner ? parseInt(bannerProps.height) + (bannerState != "expanded"  ? 20 : 0) + 'px' : "0px"
  
  if (withBanner)
    return {
      margin: 0,
      height: bannerHeight,
      transition: `all ${transitionTime} ease`,
      marginBottom: bannerState != "expanded" ? "5px" : "0px",
      boxShadow: bannerState == "expanded" ? bannerProps.shadow : undefined,
      background: bannerState == "expanded" ? bannerColor : undefined,
    }
  else return {}
}

function nextSidebarAction(state: SidebarState, enabled: ExpandEnable): ExpandAction {
  if ((Number(enabled.collapsed) + Number(enabled.expanded) + Number(enabled.none) <= 1))
    return "none"

  switch (state.expandState) {
    case 'expanded': return 'collapse'
    case enabled.expanded && 'collapsed': return "expand"
    case 'collapsed': return "collapse"
    case 'none': return 'expand'
    default: return "none"
  }
}

function updateSidebarState(state: SidebarState, enabled: ExpandEnable, action: ExpandAction): SidebarState {
  if ((Number(enabled.collapsed) + Number(enabled.expanded) + Number(enabled.none) <= 1))
    return state

  if (state.kind == "sliding"){
    switch (true) {
      case state.expandState == "expanded" && action == "collapse": 
        return { ...state, expandState: "none"}

      case state.expandState == "none" && action == "expand": 
        return { ...state, expandState: "expanded"}

      default: 
        console.error("Attempting to perform action %o on sidebarState %o", action, JSON.stringify(state))
        return state
    }
  } else {

    switch (true) {
      case state.expandState == "expanded" && action == "collapse": 
        return { ...state, expandState: enabled.collapsed ? "collapsed" : "none"}

      case state.expandState == "collapsed" && action == "collapse" : 
        return enabled.none ? { ...state, expandState: "none"} : state

      case state.expandState == "collapsed" && action == "expand" : 
        return enabled.expanded ? { ...state, expandState: "expanded"} : state

      case state.expandState == "none" && action == "expand": 
        return { ...state, expandState: enabled.collapsed ? "collapsed" : "expanded"}

      default: 
        console.error("Attempting to perform action %o on sidebarState %o", action, JSON.stringify(state))
        return state
    } 
  }
}

function createSidebarExpand ( sm: boolean, lg: boolean, enabled: ExpandEnable) : SidebarState {
  switch (true) {
    case enabled.expanded  && lg : return { kind: "permanent", expandState: "expanded" }
    case enabled.collapsed && sm: return { kind: "permanent", expandState: "collapsed" }
    default: return { kind: "permanent", expandState: "none"}
    //default: return { kind: "sliding", expandState: "none"}
  }
}

function getSidebarWidth(config: LayoutProps): string {
  switch (config.sidebarState.expandState) {
    case "collapsed":
      return config.props.collapsedWidth
    case "expanded":
      return config.props.expandedWidth
    default:
      return "0px"
  }
}

export default SidebarLayoutSidebar
