import { useFormikContext }    from "formik"
import { useFieldInfo }        from "hooks/field"
import _                       from 'lodash';
import { useEffect, useState } from "react";

export const useDependants = () => {
  const { info } = useFieldInfo()
  const formik   = useFormikContext()
  const [depValues, setDepValues] = useState({})

  const dependants = info.field.dependants
  if (dependants.length == 0)
    return undefined

  const entries = dependants.map(dependant => [dependant, _.get(formik.values, dependant)])
  const values = Object.fromEntries(entries)

  useEffect(() => {
    const updated = Object.entries(values).some(([variable, value]) => _.get(depValues, variable) != value)
    if (updated) {
      setDepValues(values)
    }
  }, [values])

  return depValues
}
