import _ from 'lodash';

export function getRegionAndLanguage(locale) {
  if (locale && _.isString(locale)) {
    const match = locale.match(/(\w{2})[-_](\w{2})/)
    if (match) {
      return {
        'language': match[1].toLowerCase(),
        'region'  : match[2].toUpperCase()
      }
    }
  }
  return null
}

export function toDisplayLanguage(locale) {
  const rl = getRegionAndLanguage(locale)
  if (!rl)
    return "Unknown language"

  const displayLocale = rl.language + '-' + rl.region
  const languageNames = new Intl.DisplayNames([displayLocale], { type: 'language' });
  const language      = languageNames.of(rl.language)

  return language + (rl.language !== rl.region.toLowerCase() ? ' (' + rl.region + ')' : '')
}