import { useTranslator } from 'hooks/translator';
import { useState } from 'react';

import { DragHandle } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

const MultipleDragButton = () => {
  const { t }                   = useTranslator()
  const [grabbing, setGrabbing] = useState<boolean>(false)

  const onGrab = () => {
    setGrabbing(true)
  }

  const onDrop = () => {
    setGrabbing(false)
  }

  return (
    <Tooltip title={t('drag')}>
      <span>
        <IconButton tabIndex={-1} size="small" onMouseDown={onGrab} onMouseUp={onDrop} sx={{cursor: grabbing ? "grabbing !important" : "grab !important"}} >
          <DragHandle fontSize='small'/>
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default MultipleDragButton