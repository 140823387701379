import { useEffect, useRef } from "react"

const useClickAway = (handler: (e: React.SyntheticEvent) => void) => {
  const ref = useRef(null)

  useEffect(() => {
    function handleClickOutside(event: React.SyntheticEvent) {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        handler(event)
      }
    }

    // @ts-ignore
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // @ts-ignore
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return ref
}

export default useClickAway