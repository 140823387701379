import { useEffect, useState }       from 'react';
import { useTranslation }            from 'react-i18next';
import { useFormikContext }          from 'formik';
import {
  Save as SaveIcon,
  SaveOutlined as SaveOutlinedIcon } from '@mui/icons-material'
import { Tooltip, IconButton, Box }  from '@mui/material';
import { useMutation }               from '@apollo/client';
import Loader                        from 'components/common/Loader';
import { useNotifier }               from 'hooks/notification';
import { useFormInfo, useTaskId }    from 'hooks/form';
import { SAVE_TASK }                 from 'queries/task';
import { SAVE_START_FORM }           from 'queries/process';
import Report                        from 'helpers/report';
import { extractFiles }              from 'utils/utils';
import { useTranslator }             from 'hooks/translator';
import { useNavigate }               from 'react-router';

const SaveButton = ({isStartForm}) => {
  const navigate                = useNavigate()
  const { t }                   = useTranslation()
  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading]   = useState(false)
  const { translator }          = useTranslator()
  const [doSave]                = isStartForm ? useMutation(SAVE_START_FORM) : useMutation(SAVE_TASK)
  const idOrKey                 = isStartForm ? useFormInfo().processDefinition.key : useTaskId()
  const formik                  = useFormikContext()
  const notifier                = useNotifier()

  const handleSave = (e) => {
    const values = formik?.values
    const [rValues, files]  = extractFiles(values)

    console.log("handleSave: id=%o, values=%o, isStartForm=%s", idOrKey, values, isStartForm)
    if (files.length > 0)
      notifier.warning("Files are not yet supported with the save operation") 

    console.log("handleSave: id=%o, values=%o, isStartForm=%s, files=%o, ", idOrKey, rValues, isStartForm, files)
    setDisabled(true)
    setLoading(true)

    const vars = isStartForm
      ? { key: idOrKey, values: rValues, files }
      : { id: idOrKey, values: rValues, files }

    doSave({ variables: vars})
      .then(
        result => {
          if (!isStartForm) {
            setLoading(false)
          } else {
            const createdTaskId = result.data?.saveStartForm?.id
            const url = `/gears/tasks/${idOrKey}/${createdTaskId}`
            console.log("Navigating to created task after start form save: %s", url)
            navigate(url)
          }
        },
        error  => {
          setLoading(false)
          setDisabled(false)
          const report = Report.from(error, translator, { category: Report.backend})
          report.addToNotifier(notifier)
          console.error("Save error: %s", report.verboseMessage)
        }
      )
  }

  useEffect(() => {
    if (!disabled)
      return

    const timeout = 1500
    const timer = setTimeout(() => {
      setDisabled(false);
    }, timeout);

    return () => {clearTimeout(timer)}
  }, [disabled]);

  const ButtonLoader = () => (
    <Box sx={{paddingTop: '4px', paddingLeft: '5px'}}>
      <Loader size='small'/> 
    </Box>
  )
  
  const unabled = disabled || loading
  const title = unabled ? "" : t("save")
  return unabled 
    ? <ButtonLoader/> 
    : <Tooltip disableInteractive title={title}>
        <span>
          <IconButton tabIndex={-1} id='save-button' size="normal" disabled={unabled} onClick={handleSave} >
            { unabled 
              ? <SaveOutlinedIcon fontSize="normal" /> 
              : <SaveIcon fontSize="normal" /> }
          </IconButton>
        </span>
      </Tooltip>
}

export default SaveButton