import LoadingIcon from 'components/common/LoadingIcon';
import ContentPreview from 'components/form/fields/content/ContentPreview';
import ContentViewer from 'components/form/fields/content/ContentViewer';
import { previewable } from 'components/form/fields/content/utils';
import { Content, ContentViewEvent } from 'helpers/content';
import { useContentOnClick } from 'hooks/content';
import { useEffect, useState } from 'react';
// @ts-ignore
import { FileIcon } from 'react-file-icon';
import { Attachment } from 'types/graphql';
import { removeExtension } from 'utils/files';

import { Download as DownloadIcon } from '@mui/icons-material';
import { alpha, Box, Card, Typography } from '@mui/material';

const FileTile = ({attachment}: {attachment: Attachment}) => {
  const {
    content, loading, loadContent, 
    loadOnClick, downloadOnClick } = useContentOnClick({id: attachment.__id, filename: attachment.filename, type: 'content', mimeType: attachment.contentType})
  const [open, setOpen]            = useState<ContentViewEvent>(null)

  const onClick = (e: any) => {
    //openInNewTab(Runtime.content(attachment.__id)) // this does not work because there is no way to authenticate
    loadOnClick(e).then(() => setOpen(e))
  }

  return (
    <Card
      onClick={onClick}
      sx={{
        cursor: !loading ? "pointer" : "wait",
        background: (theme) => theme.palette.grey[200],
        height: "120px",
        width: "120px",
        "&:hover": {
          background: (theme) => theme.palette.grey[400],
        }
      }}
    >
      <Box sx={{position: "relative"}}>
        <Box
          sx={{
            opacity: 0.6,
            position: "absolute",
            width: "120px",
            height: "120px",
            display:"flex",
            justifyContent:"center",
            alignItems: "center"
          }}
        >
          <ContentViewer content={content} open={open} setOpen={setOpen}/>
          <TilePreview content={content} loadContent={loadContent} />
        </Box>

        <Box sx={{display: "block", position: "absolute", width: "120px", height: "120px", opacity: 0,
          "&:hover": {opacity: "1 !important", background: (theme) => alpha(theme.palette.grey[400], 0.85)}}}
        >
          <TileHover downloadOnClick={downloadOnClick} loading={loading} attachment={attachment} />
        </Box>
      </Box>
    </Card>
  )
}

const TilePreview = ({content, loadContent}: {content: Content, loadContent: () => void}) => {
  const isPreviewable = previewable(content) 

  const defaultPreview = (
    <Box sx={{position: "absolute", width: "75px", height: "90px", overflow: "hidden"}}>
      <FileIcon extension={content.extension} />
    </Box>
  )

  useEffect(() => {
    if (isPreviewable)
      loadContent()
  } ,[])

  if (content.hasData && isPreviewable)
    return <ContentPreview content={content} fallback={defaultPreview} />
  else
    return defaultPreview
}

const TileHover = ({downloadOnClick, loading, attachment}: {downloadOnClick: (e: any) => void, loading: boolean, attachment: Attachment}) => {
  return (
    <Box sx={{padding: "10px", display: "flex", flexDirection: "column", height: "100%", opacity: 1}}>
      <Typography noWrap textOverflow={"ellipsis"} fontSize={10}>
        {removeExtension(attachment.filename)}
      </Typography>
      <Box sx={{flexGrow: 1}} />
      <Box sx={{display: "flex", width: "100%", justifyContent: "right"}}>
        <LoadingIcon icon={DownloadIcon} tooltipProps={{title:"Download"}} onClick={downloadOnClick} loading={loading} />
      </Box>
    </Box>
  )
}

export default FileTile
