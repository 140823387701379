import {
    Box, AppBar,
    Toolbar, useTheme } from '@mui/material';
import BannerLogo       from 'components/layout/common/BannerLogo';
import LogoContainer    from 'components/layout/common/LogoContainer';
import MainMenu         from 'components/layout/main/MainMenu';

const MainBanner = (props) => {
  const theme = useTheme()

  return (
    <AppBar
      elevation={theme.components.banner.elevation}
      sx={theme.components.banner}
    >
      <Toolbar sx={theme.components.toolbar} disableGutters={true}>
        <LogoContainer>
          <BannerLogo/>
        </LogoContainer>
        <Box sx={{ flexGrow: 1 }} />
        <MainMenu/>
      </Toolbar>
    </AppBar>
  );
}
export default MainBanner;
