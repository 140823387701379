import { useLayoutConfig, useLayoutContext } from 'hooks/layout';
import { createContext } from 'react';
import Types from 'types/types';
import { DeepPartial } from 'types/partial';
import { SetState } from 'types/react';

const LayoutContextProvider = ({children, reset, ...context}: LayoutProviderProps) => {
  const config = useLayoutConfig(context, reset)

  return (
    <LayoutContext.Provider value={config}>
	   {children}
    </LayoutContext.Provider>
  )
}

export interface LayoutProps {
  name: string,
  props: LayoutConfigProps,
  bannerProps: BannerProps,
  sidebarProps: SidebarProps,
  sidebarState: SidebarState,
  setSidebarState: SetState<SidebarState>,
  bannerState: ExpandState,
  setBannerState: SetState<ExpandState>,
}

export interface PartialLayoutProps extends DeepPartial<LayoutProps> {}

export interface LayoutProviderProps extends PartialLayoutProps {
  reset?: boolean,
  children: React.ReactNode,
}

export type SidebarSide  = "left" | "right"
export type SidebarKind  = "sliding" | "permanent"
export type ExpandState  = "expanded" | "collapsed" | "none"
export type ExpandAction = "collapse" | "expand" | "none"
export type ExpandEnable = {
  expanded: boolean,
  collapsed: boolean,
  none: boolean
} 

export type SidebarState = {
  kind: SidebarKind,
  expandState: ExpandState
}

export interface LayoutConfigProps {
  transitionTime: string,
  expandedWidth: string,
  collapsedWidth: string,
  withBanner: boolean,
  side: SidebarSide,
  sidebarBanner: React.ReactNode,
  sidebarBody: React.ReactNode,
  sidebarBodyFooter?: React.ReactNode,
  contentBanner: React.ReactNode,
}

export interface BannerProps {
  shortcut?: KeyboardEvent['code'] 
  height: string,
  shadow?: string,
  enabled: ExpandEnable
}

export interface SidebarProps {
  shortcut?: KeyboardEvent['code'],
  enabled: ExpandEnable
}

export const defaultSidebarLayoutProps: LayoutProps = {
  name: "sidebar-layout",
  props: {
    transitionTime: "0.3s",
    expandedWidth: "260px",
    collapsedWidth: "52px",
    side: "left",
    withBanner: true,
    sidebarBanner: Types.null<React.ReactNode>(),
    sidebarBody: Types.null<React.ReactNode>(),
    sidebarBodyFooter: Types.null<React.ReactNode>(),
    contentBanner: Types.null<React.ReactNode>(),
  },
  bannerProps: {
    height: "55px",
    shadow: "0 2px 3px 0px rgba(0, 0, 0, 0.4)", // "  rgba(0, 0, 0, 0.4)",
    enabled: {
      expanded: true,
      collapsed: true,
      none: true
    }
  },
  bannerState: "expanded",
  setBannerState: () => { console.error("You cannot change banner state. The setState function is not set.") },

  sidebarProps: {
    enabled: {
      expanded: true,
      collapsed: true,
      none: true
    }
  },
  sidebarState: {
    expandState: "expanded",
    kind: "permanent"
  },
  setSidebarState: () => { console.error("You cannot change sidebar state. The setState function is not set.") },
}

export const LayoutContext = createContext<LayoutProps>(defaultSidebarLayoutProps)

export default LayoutContextProvider;
