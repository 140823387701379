import FirstPage    from 'components/table/page/FirstPage'
import LastPage     from 'components/table/page/LastPage'
import NextPage     from 'components/table/page/NextPage'
import PreviousPage from 'components/table/page/PreviousPage'

const MenuBarPaging = ({pageable, toolbarFunctions, status}) => {
  if (pageable) {
    return (
      <>
        <FirstPage    onClick={toolbarFunctions.first}    loading={status.busy && status.with === 'first'}    />
        <PreviousPage onClick={toolbarFunctions.previous} loading={status.busy && status.with === 'previous'} />
        <NextPage     onClick={toolbarFunctions.next}     loading={status.busy && status.with === 'next'}     />
        <LastPage     onClick={toolbarFunctions.last}     loading={status.busy && status.with === 'last'}     />
      </>
    )
  } else return null
}

export default MenuBarPaging