import Loader from 'components/common/Loader';
import MuiIcon from 'components/common/MuiIcon';
import { useTranslator } from 'hooks/translator';
import React from 'react';
import { getIcon } from 'utils/icons';

import { Box, IconButton, SxProps, Tooltip } from '@mui/material';

import { PageHelper } from '../helpers/PageHelper';
import { WindowHelper } from '../helpers/WindowHelper';
import { usePagePanelContext } from '../hooks/usePagePanelContext';
import { usePaneContext } from '../hooks/usePaneContext';
import { useWindowContext } from '../hooks/useWindowContext';
import { PageButtonProps } from '../types';
import Panel from './Panel';

const PagePanel = () => {
  const {buttons} = usePagePanelContext()

  return (
    <Panel sx={{right: "8px", top: "8px",}}>
      {buttons
        .map((button, index) =>
          <React.Fragment key={index}>{button.component}</React.Fragment>
        )
      }
      <OrientationButton key={1003} />
      <NavigateButtons key={1002} />
      <FullWidthButton key={1001} />
      <ExpandButton key={1000} />
    </Panel>
  )
}

const OrientationButton = () => {
  const {orientation, setOrientation} = useWindowContext()
  const {configs}                     = useWindowContext()
  const {visible}                     = WindowHelper.getConfigStatus(configs)
  const { t }                         = useTranslator()

  const onClick = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()
    setOrientation(o => o == 'vertical' ? 'horizontal' : 'vertical')
  }
  
  if (visible > 1) {
    return (
      <PanePageButton
        title={t('windowmanager.orientation')}
        onClick={onClick}
        icon='ScreenRotation'
        sx={{
          transform: orientation == 'horizontal' ? 'scaleX(-1) rotate(75deg)' : 'scaleX(-1) rotate(-25deg)',
          '&:hover': {
            transform: orientation == 'horizontal' ? 'scaleX(-1) rotate(45deg)' : 'scaleX(-1) rotate(-45deg)',
          },
          height: '18px'
        }}
      />
    )
  }
  return null
}

const NavigateButtons = () => {
  const {configs, setConfigs} = useWindowContext()
  const {t}                   = useTranslator()

  const onClick = (e: React.SyntheticEvent, direction: 'left' | 'right') => {
    e.preventDefault()
    e.stopPropagation()

    setConfigs(configs => direction == 'left' ? PageHelper.shiftLeft(configs) : PageHelper.shiftRight(configs))
  }

  const enableRight = PageHelper.enableRightShift(configs)
  const enableLeft  = PageHelper.enableLeftShift(configs)
  const enable      = enableRight || enableLeft

  if (enable) {
    return (
      <React.Fragment>
        <PanePageButton
          disabled={!enableLeft}
          title={t('windowmanager.previous')}
          onClick={e => onClick(e, 'left')}
          icon='NavigateBefore'
        />
        <PanePageButton
          disabled={!enableRight}
          title={t('windowmanager.next')}
          onClick={e => onClick(e, 'right')}
          icon='NavigateNext'
        />
      </React.Fragment>
    )
  } 

  return null
}

const ExpandButton = () => {
  const {configs, setConfigs} = useWindowContext()
  const {enabled, visible}    = WindowHelper.getConfigStatus(configs)
  const {index}               = usePaneContext()
  const {t}                   = useTranslator()

  const onClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()

    setConfigs(configs => PageHelper.resize(configs, index))
  }

  const isPrimary = visible == enabled
  if (enabled > 1) {
    return (
      <PanePageButton 
        title={t(isPrimary ? 'windowmanager.expand' : 'windowmanager.collapse')} 
        onClick={onClick} 
        isSecondary={!isPrimary}
        icon='Fullscreen'
        secondaryIcon='FullscreenExit'
      />
    )
  } else return null
}

const FullWidthButton = () => {
  const {configs, width, setWidth, orientation} = useWindowContext()
  const {t} = useTranslator()

  const onClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()

    const {enabled} = WindowHelper.getConfigStatus(configs)
    setWidth(width => width == "100%" ? WindowHelper.getWindowWidth(enabled, orientation) : "100%") 
  }

  const isPrimary = width != "100%"
  return (
    <PanePageButton
      title={t(isPrimary ? 'windowmanager.unleash' : 'windowmanager.restrict')}
      onClick={onClick}
      isSecondary={!isPrimary}
      icon='WidthFull'
      secondaryIcon='WidthNormal'
    />
  )
}

export const PanePageButton = ({title, icon, secondaryIcon, isSecondary, onClick, disabled, sx, loading}: PageButtonProps) => {
  const delay=1000

  const iconSx: SxProps = {
    height: "20px",
    transition: "height 0.1s linear, transform 0.2s ease",
    "&:hover": {
      // @ts-ignore
      height: sx?.height ? (Number.parseInt(sx.height) + 3 + "px") : "23px",
      // @ts-ignore
      ...(sx?.['&:hover'])
    },
    visibility: loading ? 'hidden': 'visible',
    ...sx
  }

  return (
    <Tooltip disableInteractive title={!disabled && title} enterDelay={delay} >
      <span>
        <IconButton disabled={disabled} tabIndex={-1}  sx={{width: "24px", height: "24px"}} onClick={onClick} >
          {!isSecondary
            ? <MuiIcon iconLocation={"page icons"} name={icon} sx={iconSx}/>
            : <MuiIcon iconLocation={"page icons"} name={secondaryIcon || icon} sx={iconSx} />
          }
          { loading ? <Box sx={{marginTop: "-5px", position: "absolute"}}> <Loader size='micro'/></Box>: null }
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default PagePanel