import { Box, SxProps } from "@mui/material"
import { ReactElement, useEffect, useRef, useState } from "react"
import { AnyReactElement, DivProps, ReactChildren } from "types/react"
import Types from "types/types"

type BannerProps = {
  banner: React.ReactNode,
  bodySx?: SxProps
}

interface TransparentBannerProps extends BannerProps {
  children: ReactChildren
}

const StickyBannerLayout = ({children, banner, bodySx}: TransparentBannerProps): JSX.Element => {
  return (
    <Box id='sticky-banner-container' sx={{display: "flex", width: "100%", overflow: "auto", maxHeight: "100%", flexDirection: "column"}}>
      <StickyBanner>
        {banner}
      </StickyBanner>
      <Box id='sticky-banner-body' sx={{display: "flex", flexGrow: 1, width: "100%", ...bodySx}}>
        {children}
      </Box>
    </Box>
  )
}

const StickyBanner = ({children}: DivProps) => {
  const [isSticky, setIsSticky] = useState(false)
  const ref                     = useRef()

  // mount
  useEffect(()=>{
    const cachedRef: Element = Types.as<Element>(ref.current),
          observer = new IntersectionObserver(
            ([e]) => setIsSticky(e.intersectionRatio < 1),
            {
              threshold: [1],
              // rootMargin: '-1px 0px 0px 0px',  // alternativly, use this and set `top:0` in the CSS
            }
          )

    observer.observe(cachedRef)

    // unmount
    return function(){
      observer.unobserve(cachedRef)
    }
  }, [])

  return (
    <Box ref={ref} 
      id='sticky-banner'
      sx={{
        flexShrink: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        //position: '-webkit-sticky',
        //position: '-moz-sticky',
        //position: '-ms-sticky',
        //position: '-o-sticky',
        position: 'sticky',
        top: "-0.1px",
        width: "100%",
        backdropFilter: "saturate(180%) blur(8px)",
        WebkitBackdropFilter: 'saturate(180%) blur(8px)',
        boxShadow: isSticky ? "0 2px 8px rgb(0 0 0 / 0.1)" : "none",
        transition: 'box-shadow 0.15s linear',
        zIndex: 70,
      }}
    >
      {children}
    </Box>
  )
}

export default StickyBannerLayout