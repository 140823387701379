import { Helmet }            from 'react-helmet';
import DetailView            from 'components/view/DetailView';
import { useTranslation }    from 'react-i18next';
import { handleResult }      from 'utils/utils';
import { Box, }              from '@mui/material';
import { useQuery }          from '@apollo/client';
import { useParams }         from 'react-router';
import NotFound              from 'pages/NotFound';
import { useNotifier }       from 'hooks/notification';
import { useConfig }         from 'hooks/config';
import { GET_DETAIL_VIEW }   from 'queries/detail';
import ViewLayout            from 'components/view/ViewLayout';
import { getEnabledLinks }   from 'components/view/utils/links';
import DetailBreadcrumbs     from 'components/breadcrumbs/DetailBreadcrumbs';
import RenderContextProvider from 'contexts/RenderContext';
import { useTranslator }     from 'hooks/translator';
import StickyBannerLayout    from 'components/layout/common/StickyBannerLayout';

const DetailPage = () => {
  const {t}         = useTranslation()
  const { project } = useConfig()
  const { id: detailId, detail: detailKey } = useParams()
  console.log("detailId=%o, detailKey=%o", detailId, detailKey)

  return (
    <>
      <Helmet>
        <title>{t('detail')} | {project}</title>
      </Helmet>
      <RenderContextProvider context="detail" props={{ detailKey, detailId }}>
        <DetailResult detailKey={detailKey} detailId={detailId}/>
      </RenderContextProvider>
    </>
  )
}

const DetailResult = ({ detailKey, detailId }) => {
  const result   = useQuery(GET_DETAIL_VIEW, { variables: { detailKey, detailId } })
  const notifier = useNotifier()

  return handleResult(result, notifier,
    data  => (
      <DetailContents schema={data.detail.schema}
                      sections={data.detail.instance.enabledSectionKeys}
                      links={getEnabledLinks(data.detail.instance.links)}/>
    ),
    error => {
      console.error("GraphQL error: %o", error); return <NotFound/>
    }
  )
}

const DetailContents = ({ schema, sections, links }) => {
  const {translator} = useTranslator()
  const label = translator.toDetailTitle(schema.key, schema.label)

  return (
    <ViewLayout links={links}>
      <StickyBannerLayout
        banner={<DetailBreadcrumbs label={label} /> }
      >
        <Box sx={{display: "flex", width: "100%", padding: "20px"}}>
          <DetailView schema={schema} sections={sections}/>
        </Box>
      </StickyBannerLayout>
    </ViewLayout>
  )
}

export default DetailPage
