import { useRef, useEffect } from 'react';

export function useMountedRef() {
  const ref = useRef(false);

  useEffect(() => {
    ref.current = true;
    return () => ref.current = false;
  }, []);

  return {ref, isMounted: () => ref.current}
}
