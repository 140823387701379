import moment, { Moment } from 'moment-timezone'
require('@rangerrick/moment-javaformat')

export default moment

export interface MomentJF extends Moment {
  formatJavaDTF(format?: string): string;
}

export function parseDate(value: string): MomentJF {
  return moment(value, [ "YYYY-MM-DD" ], true) as MomentJF
}

export function parseTime(value: string): MomentJF {
  return moment(value, [ "HH:mm:ss", "HH:mm:ss.SSSSSSS" ], true) as MomentJF
}

export function parseDatetime(value: string): MomentJF {
  return moment(value, [ "YYYY-MM-DDTHH:mm:ss.SSSSSSSSZ", "YYYY-MM-DDTHH:mm:ssZ", "YYYY-MM-DDTHH:mm:ss.SSSSSSSS", "YYYY-MM-DDTHH:mm:ss" ], true) as MomentJF
}

export function parseIsoDatetime(value: string): MomentJF {
  return moment(value) as MomentJF
}
