import Translator, { T, TranslateFunction } from 'helpers/translator';
import { useConfig } from 'hooks/config';
import i18n, { t } from 'utils/i18n';

export const useTranslator = () => {
  const { translator }: { translator: Translator } = useConfig()
  const t : TranslateFunction = translator.translate.bind(translator)

  return { 
    translator, 
    t,
  }
}

export const getTranslator = (): {translator: T} => {
  return {translator: new Translator(t, i18n)}
}
