import { useMemo }         from "react"
import { useNavigate }     from "react-router"
import { useApolloClient } from "@apollo/client"

import {
  createOnClickHandler,
  getEnabledLinks,
  toLinkLabel,
  toLinkTip,
  prepareLinkFormValues,
} from "components/view/utils/links"

import { useDashboardMenuItems } from "hooks/config"
import { useLocale }             from "hooks/locale"
import { useNotifier }           from "hooks/notification"
import useStartProcess           from "hooks/process"
import { useRenderContext }      from "hooks/render"
import { useTranslator }         from "hooks/translator"
import { GET_DETAIL_FORMVALUES } from "queries/detail"
import { GET_LIST_FORMVALUES }   from "queries/list"

export const useLinks = (context, runtimeLinks) => {
  const enabledLinks   = getEnabledLinks(runtimeLinks)
  const menuItems      = useDashboardMenuItems()
  const { translator } = useTranslator()
  const { language }   = useLocale()

  const notifier     = useNotifier()
  const navigate     = useNavigate()
  const startProcess = useStartProcess()
  const meta         = { startProcess, notifier, navigate }

  return useMemo(() => enabledLinks.map(link => (
    {
      ...link,
      label:   toLinkLabel(translator, context, link),
      tip:     toLinkTip(translator, link, menuItems),
      onClick: createOnClickHandler(meta, link),
    }
  )), [language, enabledLinks])
}

export const useViewLinks = (runtimeLinks) => {
  const context      = useRenderContext()
  const links        = useLinks(context, runtimeLinks)
  const client       = useApolloClient()
  const notifier     = useNotifier()
  const isDetail     = context.context == "detail"
  const isList       = context.context == "list"

  // augment onClick event to add form values incase we have a detail link with PROCESS_FILL kind
  const createDetailOnClick = (link, index) => {
    const detailId  = isDetail && context.props.detailId
    const detailKey = context.props.detailKey
    const l         = links[index]
    const linkKey   = l.key
    const linkKind  = l.kind
    const variables = { detailKey, detailId, linkKey }
    const path      = "data.detail.instance.link.formValues"
    const query     = GET_DETAIL_FORMVALUES
    const request   = { query, variables }

    return (e) => {
      const valuesPromise = prepareLinkFormValues(client, notifier, request, path, linkKind)
      link.onClick(e, valuesPromise)
    }
  }

  const createListOnClick = (link, index) => {
    const listKey   = context.props.listKey
    const l         = links[index]
    const linkKey   = l.key
    const linkKind  = l.kind

    const variables = { listKey, linkKey }
    const path      = "data.list.link.formValues"
    const query     = GET_LIST_FORMVALUES
    const request   = { query, variables }

    return (e) => {
      const valuesPromise = prepareLinkFormValues(client, notifier, request, path, linkKind)
      link.onClick(e, valuesPromise)
    }
  }

  switch (true) {
    case isDetail: return links.map((link, index) =>({...link, onClick: createDetailOnClick(link, index)}))
    case isList:   return links.map((link, index) =>({...link, onClick: createListOnClick(link, index)}))
    default:       return links
  }
}
