import PropTypes        from 'prop-types';


export const partitionType = PropTypes.shape({
  type   : PropTypes.string.isRequired,
  fields : PropTypes.arrayOf(PropTypes.shape({
    type : PropTypes.string.isRequired
  })).isRequired
}).isRequired

export const planType = PropTypes.arrayOf(partitionType).isRequired

export const fieldType = PropTypes.shape({
  type : PropTypes.string.isRequired
}).isRequired

export const fieldsType = PropTypes.arrayOf(fieldType).isRequired

export const formType = PropTypes.shape({
  fields : fieldsType
}).isRequired

export const taskType = PropTypes.shape({
  form     : formType
}).isRequired

export const columnsType = PropTypes.arrayOf(
  PropTypes.shape({
    headerName: PropTypes.string.isRequired,
    field     : PropTypes.string.isRequired,
    cellProps : PropTypes.object
  })
).isRequired
