import SidebarLayout from 'components/layout/common/SidebarLayout';
import { SidebarList } from 'components/layout/sidebar/SidebarList';
import { useViewLinks } from 'components/view/utils/link';
import { Link } from 'types/graphql';
import { ReactChildren } from 'types/react';
import { SidebarItemDataLink } from 'types/sidebar';

const ViewLayout = ({children, links}: {children: ReactChildren, links: Link[]}) =>  {
  return Array.isArray(links) && links.length > 0
    ? <ViewLinkLayout children={children} links={links} />
    : children
}

const ViewLinkLayout = ({children, links}: {children: ReactChildren, links: Link[]}) => (
  <SidebarLayout
    name='view'
    withBanner={false}
    side='right'
    sidebarBody={<ViewSidebarBody links={links} />}
    sidebarProps={{
      shortcut: "BracketRight"
    }}
  >
    {children}
  </SidebarLayout>
)

const ViewSidebarBody = ({links}: {links: Link[]}) => {
  const resultLinks = useViewLinks(links)
  const items = resultLinks.map(toSidebarItem)
  return <SidebarList items={items} />
}

function toSidebarItem(link: any): SidebarItemDataLink {
  return {
    type:       'item',
    ref:        link.ref,
    key:        link.label,
    label:      link.label,
    onClick:    link.onClick,
    tip:        link.tip || link.label,
    icon:       link.icon,
    activeIcon: link.icon
  } 
}

export default ViewLayout
