import { Content } from "helpers/content";
import { Notifier } from "hooks/notification";

export function authorizedDownloadAll(attachments: any[], notifier: Notifier) {
  function download_next(i: number) {
    if (i >= attachments.length)
      return;

    const file = attachments[i];
    createAuthorizedBlobUrl(file.url, file.filename)
      .then (
        blob => {
          if (blob) {
            download(blob, file.filename)
            window.URL.revokeObjectURL(blob)
          }
        },
        error => {
          console.error("Could not download %o: %o", file.filename, error)
          notifier.error(`Could not download ${file.filename} ${error}`)
        }
      )

    setTimeout(function() {
      download_next(i + 1);
    }, 500);
  }
  download_next(0)
}

export function blobToUrl(blob: Blob): string {
  return blob && window.URL.createObjectURL(blob)
}

export function blobPromiseToURL(blobPromise: Promise<Blob>, notifier: Notifier): Promise<string> {
  return blobPromise
    .then(
      blob  => blobToUrl(blob),
      error => {
        console.error("Unable to create authenticated URL: %o", error)
        notifier.error("Unable to create authenticated URL: " + error.message)
        return Promise.reject(error)
      }
    )
}

// return a promise, containing an authenticated url
export function createAuthorizedBlobUrl(remoteUrl: string, notifier: Notifier): Promise<string> {
  return blobPromiseToURL(Content.fetchBlob(remoteUrl), notifier)
}

export function download(contentUrl: string, filename: string) {
  if (!contentUrl){
    console.warn("No download url was set.")
    return
  }

  const a = document.createElement("a");
  a.href = contentUrl;

  if (filename) {
    a.setAttribute("download", filename);
  } else {
    const basename = contentUrl.split(/[\\/]/).pop() || ""
    a.setAttribute("download", basename !== "" ? basename : contentUrl);
  }

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
