import { detailRowToCrumb, listSectionToCrumb } from 'components/breadcrumbs/utils/breadcrumb'
import { createDetailCrumbKey, createDetailUrl, getCurrentDetailCrumbs } from 'components/view/utils/view'
import Settings from 'helpers/settings'

export const toListRow = ({translator, notifier, row, detail, section, currentDetail, navigate}) => {
  const cells   = section.fields.reduce((obj, field, index) => ({...obj, [field.key]: row[index + 1]}), {})
  const id      = row[0] 
  const onClick = detail && (_e => { 
    const detailUrl     = createDetailUrl(detail, id)
    const variable      = createDetailCrumbKey(detail, id)  
    const currentCrumbs = getCurrentDetailCrumbs(currentDetail?.detail, currentDetail?.id)
    const newCrumbs     = !currentDetail ? [listSectionToCrumb(translator, notifier, section), detailRowToCrumb(cells, detail, id)] : [detailRowToCrumb(cells, detail, id)]

    Settings.session.write(variable, [...currentCrumbs, ...newCrumbs]) 
    
    navigate(detailUrl) 
  })

  return { cells, id, onClick }
}
