import { Helmet }         from 'react-helmet';
import {
  Box,
  Container,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useConfig }      from 'hooks/config';

const NotFound = () => {
  const {t}         = useTranslation()
  const { project } = useConfig()

  return (
    <>
      <Helmet>
        <title>404 | {project}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: "100%",
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="md">
          <Typography
            align="center"
            color="textPrimary"
            variant="h1"
          >
            {t('404')}
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <img
              alt="Not found"
              src="/static/404.svg"
              style={{
                marginTop: 50,
                display: 'inline-block',
                maxWidth: '100%',
                width: 560
              }}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default NotFound;
