import React from 'react';

import CloudIcon from '@mui/icons-material/CloudOff';
/*images*/
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Card, createTheme, SxProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Note that this mapping include all mappings in ReportLevel
export const enum NotificationType {ERROR = 1, WARNING = 2, INFO = 3, CLOUD = 4}

/** This component visualises notifications for the user. Different error types are supported, e.b., 'error', 'warning', 'info', etc. */

export type NotificationProps = {
  type :     NotificationType
  message?:  string
  contents?: React.ReactNode
  details?:  string
  sx?:       SxProps
} 

const Notification = (props: NotificationProps) => {
  const color = useNotificationColor(props.type)

  // ---------- preconditions ----------
  if (!props.contents && !props.message)
    throw new Error("A notification requires a message.")
  // -----------------------------------
  
  return (
    <Card sx={{padding: "10px", background: color.light, border: '1px solid', borderColor: color.dark, ...props.sx}} raised={true}>
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        <IconContainer>
          <Icon type={props.type}/>
        </IconContainer>
        <Box sx={{flexGrow: 1}}>
          <NotificationMessage {...props}/>
        </Box>
      </Box>
    </Card>
  )
}

const NotificationMessage = (props: NotificationProps): JSX.Element =>
  props.contents !== undefined ? <React.Fragment>{props.contents}</React.Fragment> : <FromMessage {...props} />

const FromMessage = (props: NotificationProps): JSX.Element => {
  return (
    <Box>
      <Typography sx={{ fontSize: 16 }}>
        {props.message}
      </Typography>
      <Typography sx={{ fontSize: 14 }}>
        {props.details}
      </Typography>
    </Box>
  )
}

const Icon = ({ type }: { type: NotificationType }) => {
  switch (type) {
    case NotificationType.ERROR: return <ErrorIcon />
    case NotificationType.INFO:    return <InfoIcon/>
    case NotificationType.WARNING: return <WarningIcon/>
    case NotificationType.CLOUD:   return <CloudIcon/>
    default: {        
      console.error("There is no notification icon for type: " + JSON.stringify(type))
      return <ErrorIcon/>
    }
  }
}

const IconContainer = ({children}: {children: React.ReactNode}) => (
  <Box sx={{height: '30px', width: '30px'}}>
    {children}
  </Box>
)
const defaultTheme = createTheme();

const useNotificationColor = (type: NotificationType) => {
  const currentTheme = useTheme()
  const theme        = currentTheme ? currentTheme : defaultTheme

  switch (type) {
    case NotificationType.ERROR:   return theme.palette.error
    case NotificationType.INFO:    return theme.palette.info
    case NotificationType.WARNING: return theme.palette.warning
    case NotificationType.CLOUD:   return theme.palette.warning
    default: {        
        console.error("There is no notification icon for type: " + type)
        return theme.palette.error
      }
  }
}

export default Notification
