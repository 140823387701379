import { AutoSubmitContext } from 'contexts/AutoSubmitContext';
import { useContext, useEffect, useState } from 'react';

export const useAutoSubmitReceiver = ()  => {
  const ref                 = useContext(AutoSubmitContext)
  const [signal, setSignal] = useState(0);

  useEffect(() => {
    ref.current = setSignal;
  }, [ref]);

  return {ref, content: signal}
}

export const useAutoSubmitSignal = () => {
  const ref = useContext(AutoSubmitContext)

  return {
    signal: () => {
      ref.current?.(num => num + 1)
    }
  }
}

