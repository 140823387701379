
import React from 'react';

import { Box, SxProps } from '@mui/material';

import { PagePanelProvider } from '../contexts/PagePanelContext';
import { PaneProvider } from '../contexts/PaneContext';
import { WindowHelper } from '../helpers/WindowHelper';
import { usePaneContext } from '../hooks/usePaneContext';
import { useWindowContext } from '../hooks/useWindowContext';
import { useWindowManagerContext } from '../hooks/useWindowManagerContext';
import {
    PageConfig, PaneComponentProps, PaneContentComponentProps, PaneLayoutComponentProps, PaneProps,
    PaneSplitType
} from '../types';
import PagePanel from './PagePanel';
import { panelHoverSx } from './Panel';

const Pane = (props: PaneComponentProps) : JSX.Element => {
  switch (props.type) {
    case 'split'  : return <PaneLayout  {...(props as PaneLayoutComponentProps)}   />
    case 'content': return <PaneContent {...(props as PaneContentComponentProps)} />
  }
}

export const PaneLayout = ({sx, panes, split = "horizontal", root = false}: PaneLayoutComponentProps): JSX.Element => {
  const {orientation} = useWindowContext()
  const currentSplit  = root ? orientation : split 
  const {style}       = usePaneLayoutStyle(panes, currentSplit, root)

  return (
    <Box 
      className={`pane-layout-${root ? "root-" : ""}${split}`}
      sx={{
        display: "flex", 
        width: "100%", 
        flexGrow: 1,
        overflow: "auto",
        flexDirection: currentSplit == "horizontal" ? "row" : "column",
        justifyContent: "center",
        ...sx,
      }}
    >
      <Panes panes={panes} split={currentSplit} style={style} root={root} />
    </Box>
  )
}

type PanesProps = {
  panes: PaneProps[]
  style: SxProps[]
  root: boolean
  split: PaneSplitType
}

const Panes = ({panes, style, split, root}: PanesProps): JSX.Element => {
  const {configs} = useWindowContext()
  const {config}  = usePaneContext()
  const cf        = root ? WindowHelper.withDormantConfig(configs) : configs

  return (
    <React.Fragment>
      {
        panes.map((pane, index) =>
          <PaneProvider subtitle={pane.subtitle} title={pane.title} config={root ? cf[index] : config} key={index} index={index} siblings={panes} root={root} split={split}>
            <Pane {...pane} key={index} sx={style[index]} />
          </PaneProvider>
        )
      }
    </React.Fragment >
  )
}

const PaneContent = ({sx, ...paneProps}: PaneContentComponentProps): JSX.Element => {
  const {index, root} = usePaneContext()
  const { pageComponent: Page } = useWindowManagerContext()

  if (root) {
   return (
      <Box className={`pane-${index}`} sx={{ ...sx, display: "flex", flexDirection: "column" ,...panelHoverSx}} >
        <PagePanelProvider > 
          <PagePanel/>
          <Page>
            {paneProps.content}
          </Page>
        </PagePanelProvider>
      </Box>
    )
   } else {
    return (
      <Box className={`pane-${index}`} sx={{ ...sx}} >
        {paneProps.content}
      </Box>
    )
 
   }
}

const usePaneLayoutStyle = (panes: PaneProps[], split: PaneSplitType, root: boolean):{ configs: PageConfig[], style: SxProps[]} => {
  const {configs} = useWindowContext()
  const cf        = root ? WindowHelper.withDormantConfig(configs) : configs

  if (!root)
    window.alert("panes splitting is only implemented one level deep")

  if (!root) {
    return {
      configs,
      style: panes.map(_ => ({
        flexGrow: 1,
        width: split == 'vertical' ? "100%" : undefined,
        height: split == 'horizontal' ? "100%" : undefined,
        minWidth: 0,
        maxHeight: split == 'vertical' ? `calc(100% / ${panes.length})` : "100%",
        overflow: 'auto',
      }))
    }
  } else {
    return {
      configs,
      style: panes.map((_, index) => ({
        transition: getTransition(cf, index, split),
        width: getPageWidth(cf, index, split),
        height: getPageHeight(cf, index, split),
        minWidth: 0,
        minHeight: 0,
        maxHeight: "100%",
        overflow: 'auto',
        opacity: cf[index].state == 'visible' && cf[index].enabled ? 1 : 0,
        display: "flex",
        position: "relative",
      }))
    }
  }
}

function getPageHeight(configs: PageConfig[], index: number, split: PaneSplitType): string {
  if (configs.length <= index)
    return "0%"

  if (split == 'vertical') {
    const { visible } = WindowHelper.getConfigStatus(configs)
    return configs[index].state == 'visible' && configs[index].enabled ? `${100 / visible}%` : "0%"
  } else return "100%"
}

function getPageWidth(configs: PageConfig[], index: number, split: PaneSplitType): string {
  if (configs.length <= index)
    return "0%"

  if (split == 'horizontal') {
    const { visible } = WindowHelper.getConfigStatus(configs)
    return configs[index].state == 'visible' && configs[index].enabled ? `${100 / visible}%` : "0%"
  } else {
    return "100%"
  }
}

function getTransition(configs: PageConfig[], i: number, split: PaneSplitType): string | undefined {
  const config = configs[i]
  switch (true) {
    case config.action == 'none': return `${split == 'horizontal' ? 'width' : 'height'} 0.5s ease`
    case config.action == 'make-visible': return `${split == 'horizontal' ? 'width' : 'height'} 0.5s ease, opacity 0.3s ease 0.5s`
    case config.action == 'make-invisible': return `${split == 'horizontal' ? 'width' : 'height'} 0.5s ease`
    default: return undefined
  }
}


export default Pane
