import React, { createContext } from 'react';

import { PageConfig, PaneProps, PaneSplitType } from '../types';

type PaneContextProps = {
  title?: (() => string) | string;
  subtitle?: (() => string) | string;
  index: number;
  siblings: PaneProps[];
  root: boolean;
  split: PaneSplitType;
  config: PageConfig;
};
interface PaneProviderProps extends PaneContextProps {
  children: React.ReactNode;
}

export const PaneContext = createContext<PaneContextProps>({} as PaneContextProps);

export const PaneProvider = ({ children, ...paneProps }: PaneProviderProps): JSX.Element => {
  return (
    <React.Fragment>
      <PaneContext.Provider value={paneProps}>
        {children}
      </PaneContext.Provider>
    </React.Fragment>
  );
};


