import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SetState } from 'types/react';

import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme
} from '@mui/material';

const TileInfoDialog = ({title, description, open, setOpen}: {title: string, description: string, open: boolean, setOpen: SetState<boolean>}) => {
	const {t} = useTranslation()
	const theme = useTheme()
  const ref = useRef<HTMLDivElement>()

  const stopClickEvent = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }

	const handleClose = (e: React.SyntheticEvent) => {
    stopClickEvent(e)
    setOpen(false)
  }

  const handleClick = (e: React.SyntheticEvent) => {
    stopClickEvent(e)
    const clickIsInsideDiv = ref.current!.contains(e.target as Node)
    if (clickIsInsideDiv) {
      stopClickEvent(e)
    } else {
      handleClose(e)
    }
  }

  return (
      <Dialog
      id="description"
        open={open}
        onClick={handleClick}
      >
        <Box ref={ref} id={'dialog-container'}>
          <DialogTitle sx={{fontSize: theme.typography.h3.fontSize}}>
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{mt: 1}}>
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id={'process-close-info-button'} onClick={handleClose} autoFocus>{t('close')}</Button>
          </DialogActions>
        </Box>
      </Dialog>
  );
}

export default TileInfoDialog

