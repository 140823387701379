import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import { listSectionToCrumb } from 'components/breadcrumbs/utils/breadcrumb';
import { useNotifier } from 'hooks/notification';
import { useTranslator } from 'hooks/translator';

import { Box } from '@mui/material';

const ListBreadcrumbs = ({section}: {section: any}) => {
  const {translator} = useTranslator()
  const notifier     = useNotifier()

  return (
    <Box sx={{padding: "15px", display: "flex", flexDirection: "row", alignItems: "center"}}>
      <Breadcrumbs crumbs={[listSectionToCrumb(translator, notifier, section)]} />
    </Box> 
  )
}

export default ListBreadcrumbs
