import Fallback from 'components/common/Fallback';
import StepCard from 'components/form/task/standard/StepCard';
import { focusOnField } from 'components/form/utils/field-utils';
import { getFocusPathFromValues } from 'components/form/utils/input-utils';
import {
    createStepCardPlan, setStepIconTabIndices, StepPlan
} from 'components/form/utils/task-utils';
import { FormInfo } from 'contexts/FormInfoContext';
import RenderContextProvider from 'contexts/RenderContext';
import ValuesProvider from 'contexts/ValuesContext';
import { Form as FormikForm, Formik } from 'formik';
import { useFormInfo } from 'hooks/form';
import { FormikType } from 'hooks/submit';
import { useValues } from 'hooks/values';
import React, { useEffect, useRef } from 'react';

type StepProps = {
  onSubmit: (values: any, formInfo: FormInfo & {formik: any}) => void
}

/** This is a step with error handling */
function Step(props: StepProps) {
  const formInfo = useFormInfo()
  const plan     = createStepCardPlan(formInfo)

  console.log("Form layout plan: %o", plan)

  return (
    <Fallback type='component' name={"form"}>
      <ValuesProvider>
        <RenderContextProvider context="step">
          <StepContents plan={plan} {...props} />
        </RenderContextProvider>
      </ValuesProvider>
    </Fallback>
  )
}

interface StepContentProps extends StepProps {
  plan: StepPlan 
}

/** Render the contents of a step */
const StepContents = ({plan, onSubmit }: StepContentProps) => {
  const { values } = useValues()
  const formikRef  = useRef<FormikType | undefined>()
  const formInfo   = useFormInfo()

  function handleSubmit(values: any): void {
    const formik = formikRef.current
    onSubmit(values, { ... formInfo, formik: formik! })
  }

  const autoFocus = async () => {
    const focusPath = getFocusPathFromValues(formInfo, values)
    if (focusPath) {
      console.log("Focus on path: %o", focusPath)
      focusOnField(focusPath)
    }
  }

  useEffect(() => {
    setStepIconTabIndices()
  },[])

  useEffect(() => {
    autoFocus()
  },[])

  
  return (
    <>
      <Formik
        // @ts-ignore
        innerRef={formikRef}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{...values}}
        onSubmit={handleSubmit}
      >
        <FormikForm noValidate style={{paddingTop: "20px", display: "block", flexGrow: 1, maxWidth: "100%", overflow: "auto"}}>
          <RenderContextProvider multipleType={"mutable-table"}>
            <StepPlanComponent
              plan={plan}
            />
          </RenderContextProvider>
        </FormikForm>
      </Formik>
    </>
  )
}

const StepPlanComponent = ({plan}: {plan: StepPlan} ) => {
  return (
    <React.Fragment>
      {
        plan.map( (partition, index) => 
          <StepCard key={index} partition={{...partition, id: index}}  /> 
        )
      }
    </React.Fragment>
  )
}

export default Step;
