import { gql } from '@apollo/client';

export const GET_DOCUMENTS = gql`
  query GetDocuments($start: Int!, $count: Int!, $filter: String) {
    documents(filter: $filter, range: {start: $start, count: $count}) {
      id
      type
      title
      filename
      createdAt
      creator { id, username, givenName, familyName }
    }
  }`

