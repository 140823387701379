import { RenderValue } from 'components/render';
import { Box }               from '@mui/material';

const EmptyTableMessage = ({msg}) => (
  <Box sx={{marginLeft: 3}}>
    <RenderValue>
      {msg}
    </RenderValue>
  </Box> 
)

export default EmptyTableMessage