import { useMediaQuery } from '@mui/material';
import { styled }        from '@mui/material/styles';

const LogoContainer = styled('div')(({theme}) => {
  const large        = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const largePadding = theme.components.toolbar.paddingRight
  const smallPadding = `${parseInt(largePadding) - 5}px`
  const paddingLeft  = large ? largePadding : smallPadding

  return {
      height     : theme.components.banner.height,
      minWidth   : theme.components.sidebar.width,
      paddingLeft: paddingLeft,
      overflow   : 'hidden',
      display    : 'flex',
      alignItems : 'center',
    }
  }
);

export default LogoContainer