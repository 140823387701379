import { Paper, SxProps, useTheme } from '@mui/material';

const Elevate = ({sx = {}, elevation = 10, children, ...paperProps}: {sx?: SxProps, elevation?: number, children: React.ReactNode, paperProps?: object}) : JSX.Element => {
  const theme: any    = useTheme()
  const userElevation = theme?.components?.elevate?.elevation 

  return (
    <Paper 
      id='elevation-paper'
      elevation={userElevation == undefined ? elevation : userElevation} 
      {...paperProps}
      sx={{...sx}}
    >
      {children}
    </Paper>
  )
}

export default Elevate