import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import { createDetailCrumbKey } from 'components/view/utils/view';
import Settings from 'helpers/settings';
import { useParams } from 'react-router';

import { Box } from '@mui/material';

import { Crumb } from './Crumb';

const DetailBreadcrumbs = ({label}: {label: string}) => {
  const {detail, id} = useParams()
  const variable     = createDetailCrumbKey(detail,id)
  const crumbs       = Settings.session.read(variable, [])

  if (crumbs.length > 0) {
    crumbs[crumbs.length -1].label = label
  } else {
    crumbs.push({label: label})
  }

  return (
    <Box sx={{padding: "15px", display: "flex", flexDirection: "row", alignItems: "center"}}>
      <Crumbs crumbs={crumbs}/>
    </Box>
  )
}
  
const Crumbs = ({crumbs}: {crumbs: Crumb[]}) => 
  crumbs.length > 0 ? <Breadcrumbs crumbs={crumbs} /> : null


export default DetailBreadcrumbs
