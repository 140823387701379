import { gql } from '@apollo/client'

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($username: String!, $password: String!) {
    login(username: $username, password: $password)
  }
`

export const CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    currentUser {
      username
      emailAddress
      familyName
      givenName
      isAdmin
      roles { name }
    }
  }`

