import React from 'react';

import { Box, SxProps } from '@mui/material';

type PanelProps = {
  children: React.ReactNode,
  sx?: SxProps
}

export const panelHoverSx = {
  "&:hover" : { 
    "> .panel-container": {
      " .panel-buttons": {
        opacity: '0.2'
      }
    }
  }
}

const Panel = ({children, sx}: PanelProps) => {
  return (
    <Box 
      className='panel-container' 
      sx={{display: 'flex', position: "relative", right: 0}}
    >
      <Box 
        className='panel-buttons' 
        sx={{
          zIndex: 1000,
          position: "absolute",
          backdropFilter: "saturate(180%) blur(8px)",
          WebkitBackdropFilter: 'saturate(180%) blur(8px)',
          right: 0,
          top: 0,
          opacity: 0,
          "&:hover" : { opacity: '1 !important'},
          transition: "opacity 0.3s ease",
          borderRadius: "5px",
          ...sx
        }}
      > 
        {children}
      </Box>
    </Box>
  )
}

export default Panel