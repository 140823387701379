import { Box, Grid } from '@mui/material';

const GridCenter = ({children, minHeight}) => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: minHeight }}
  >
    <Grid item xs={3}>
      {children}
    </Grid>   
  </Grid> 
);

export const Center = ({children, direction, ...props}) => {
  const flexDirection = direction && direction == 'vertical' && 'column' || 'row'
  return (
    <Box id='center' 
      sx={{
        display: 'flex', 
        flexDirection, 
        justifyContent: 'center', 
        width:'100%', 
        ...props
      }}
    >
      {children}
    </Box>
  )
}

export default GridCenter;
