import DashboardMenu from 'components/layout/dashboard/DashboardMenu';
import { BannerSlidingExpander } from 'components/layout/sidebar/Sidebar';

import { Box, GearsMuiTheme, Toolbar, useTheme } from '@mui/material';

const DashboardBanner = ({ onMobileNavOpen }: {onMobileNavOpen: () => void}) => {
  const theme = useTheme<GearsMuiTheme>()

  return (
      <Toolbar sx={theme.components.toolbar} disableGutters={true}>
        <BannerSlidingExpander/>
        <Box sx={{ flexGrow: 1 }} />
        <DashboardMenu />
      </Toolbar>
  );
};

export default DashboardBanner;
