import { useCurrentUser } from 'authentication/main';
import LambdaComponent from 'components/common/LambdaComponent';
import { useUserMenu } from 'contexts/utils/config';
import Translator from 'helpers/translator';
import { useLocalState } from 'hooks/state';
import { GET_CONFIG } from 'queries/config';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingUser } from 'types/graphql';
import i18n from 'utils/i18n';
import { createCustomThemes, selectTheme, selectThemeKey } from 'utils/theme';

import { useQuery } from '@apollo/client';

import {
    GearsTheme, GearsThemeConfig, GearsThemeProps, GearsUIProps, TaskRendering
} from '../types/theming';
import { LocaleConfig } from './LocaleContext';

export type GearsConfig = {
  loading:    boolean
  menu:       any
  project:    string | undefined
  user:       LoadingUser
  translator: Translator
  props:      GearsUIProps
}

export const ConfigContext = createContext<GearsConfig>({} as GearsConfig)

const ConfigProvider = ({children, locale}: {children: React.ReactNode, locale: LocaleConfig}) => {
  const { t }                  = useTranslation()
  const translator             = new Translator(t, i18n)
  const user                   = useCurrentUser()
  const {loading, data, error} = useQuery(GET_CONFIG)
  const projectKey             = data?.config?.projectName || "GEARS"
  const project                = translator.toProjectName(projectKey)
  const props                  = useUIProps(data, projectKey, locale)
  const menu                   = useUserMenu(data?.config?.menu,user)

  if (error)
    console.log("Error while fetching config data: %o", error)


  const config: GearsConfig = {
    props,
    loading, 
    menu, 
    project, 
    user, 
    translator,
  }

  return (
    <ConfigContext.Provider value={config}>
			<LambdaComponent props={config}>
				{children}
			</LambdaComponent>
    </ConfigContext.Provider>
  );
}

const useUIProps = (data: any, projectKey: string, localeConfig: LocaleConfig): GearsUIProps => {
  const projectPrefix  = projectKey == "GEARS" ? "" : `${projectKey}.`
  const themePath      = projectPrefix + "THEME"
  const defaultTheme   = data?.config?.theme?.default || 'default'
  
  const themeConfig: GearsThemeConfig     = data?.config?.theme
  const [themes, setThemes]               = useState<GearsTheme[]>(() => createCustomThemes(localeConfig, themeConfig))
  const themeSettings                     = getThemeSettings(themeConfig)
  const [themeKey, setThemeKey]           = useLocalState(themePath, defaultTheme)
  const [taskRendering, setTaskRendering] = useLocalState<TaskRendering>("RENDERING", "standard")
  const selectedKey                       = selectThemeKey(themes, themeKey, themeConfig)

  const theme                             = selectTheme(themes, selectedKey)
  useEffect(() => {
    setThemes(createCustomThemes(localeConfig, themeConfig))
  }, [data, localeConfig])

  return {...themeSettings, themes, themeKey: selectedKey, theme, setThemeKey, taskRendering, setTaskRendering}
}

function getThemeSettings(theme: GearsThemeConfig): GearsThemeProps {
  const addIcons: GearsThemeProps['generateIconColor']     = typeof theme?.settings?.addIcons          == 'boolean' ? theme.settings.addIcons : Boolean(theme?.icons)
  const generateIconColor:GearsThemeProps['addIcons']      = typeof theme?.settings?.generateIconColor == 'boolean' ? theme.settings.generateIconColor : true
  const addDefaultTheme:GearsThemeProps['addDefaultTheme'] = typeof theme?.settings?.addDefaultTheme   == 'boolean' ? theme.settings.addDefaultTheme : true
    
  return {
    generateIconColor,
    addIcons,
    addDefaultTheme
  }
}

export default ConfigProvider
