import { useQuery }                    from '@apollo/client';
 import { useConfig }                  from 'hooks/config';
import { useNotifier }                 from 'hooks/notification';
import NotFound                        from 'pages/NotFound';
import { GET_MESSAGE   }               from 'queries/messages';
import { Helmet }                      from 'react-helmet';
import { useTranslation }              from 'react-i18next';
import {  useParams }                  from 'react-router';
import { handleResult }                from 'utils/utils';
import { Message as MessageComponent } from 'components/message';

const Message = () => {
  const {t}         = useTranslation()
  const { project } = useConfig()

  return (
    <>
      <Helmet>
        <title>{t('menu.message')} | {project}</title>
      </Helmet>
      <MessageResult/>
    </>
  );
}

const MessageResult = () => { const notifier      = useNotifier()
  const { id }        = useParams()
  const messageResult = useQuery(GET_MESSAGE, { variables: { id } })

  return handleResult(messageResult, notifier,
    data  => <MessageComponent message={data.message} />,
    error => {
      console.error("Graphql error: %o", error.message)
      return <NotFound/>
    }
  )
}


export default Message;
