import PageContainer from 'components/common/PageContainer';
import PageLoader from 'components/common/PageLoader';
import StatusPage from 'components/common/Status';
import { getProcessName } from 'components/form/utils/form-utils';
import { TraitHelper } from 'helpers/traits';
import { useConfig } from 'hooks/config';
import useCountDown from 'hooks/counter';
import { useNotifier } from 'hooks/notification';
import { goToProcessOrigin, openTaskForm, openTasksForm } from 'hooks/process';
import { useTaskEndHandler } from 'hooks/submit';
import { useTranslator } from 'hooks/translator';
import NotFound from 'pages/NotFound';
import { GET_PROCESS_INSTANCE_AND_TASK } from 'queries/process';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ProcessInstance, Task } from 'types/graphql';

import { useQuery } from '@apollo/client';
import { Link } from '@mui/material';

type StatusType = 'task' | 'logout'

function Status({type}: {type: StatusType}) {
  const { t }       = useTranslation()
  const { project } = useConfig()

  return  (
    <>
      <Helmet>
        <title>{t('menu.status')} | {project}</title>
      </Helmet>
      <PageContainer center={true}>
        <StatusSwitcher type={type} />
      </PageContainer>
    </>
  )
}

const StatusSwitcher = ({type}: {type: StatusType}) => {
  switch(type) {
    case 'task': 
      return <TaskStatus />
    case 'logout': 
      return <LogoutStatus />
    default:
      console.error("No status type provided") 
      return <NotFound />
  }
}

const LogoutStatus = () => {
  const { t } = useTranslation()
  const here  = <Link href="#/login" underline="hover"> {t('status.user.login.here')} </Link>
  const login = <>{t("status.user.login.begin")}{here}{t("status.user.login.end")}</>

  return <StatusPage title={t('status.user.logout') as string} content={login} />
}

const TaskStatus = () => {
  const { processInstanceId, taskId } = useParams()
  const result = useQuery(GET_PROCESS_INSTANCE_AND_TASK, { variables: { processInstanceId, taskId }, pollInterval: 3000 })

  return (
    <PageLoader loading={result.loading}>
      <TaskStatusContents error={result.error} instance={result.data?.processInstance} task={result.data?.task} />
    </PageLoader>
  )
}

const TaskStatusContents = ({error, instance, task}: {error: any, instance?: ProcessInstance, task?: Task}): JSX.Element | null => {
  const { translator, t } = useTranslator()
  const notifier          = useNotifier()
  const navigate          = useNavigate()
  const options           = {navigate, notifier, translator}
  const taskStarter       = useTaskEndHandler()

  if (error)
    return <NotFound/>

  if (!instance)
      return <StatusComplete type='process' />

  if (task && !task.owner && task.error){
    openTaskForm(options, null, task)
    return null
  }

  // if there's a single assigned task, open it
  const assignedTasks = instance.assignedTasks.filter(task => task.owner == null)
  if (assignedTasks.length > 0) {
    openTasksForm(options, assignedTasks)
    return null
  } 
  
  const processName = instance ? getProcessName(translator, instance) : undefined
  if (!task) {
    if (TraitHelper.containsTrait(instance?.processDefinition?.traits, "autostart")) {
      taskStarter()
      return null
    } else 
      return <StatusComplete processName={processName} type='task'/>
  }
  else
    return <StatusPage title={processName} content={t('status.task.waiting.description')}/>
}

const StatusComplete = ({processName, type}: {processName?: string, type: 'task' | 'process' }) => {
  const { t }     = useTranslator()
  const navigate  = useNavigate()
  const {counter} = useCountDown(3, () => { goToProcessOrigin(navigate) })

  return <StatusPage title={processName} content={t(`status.${type}.complete.counter`, {variables: {counter}})} />
}

export default Status
