import { useCurrentUser } from 'authentication/main';
import PageLoader from 'components/common/PageLoader';
import ConfigProvider, { GearsConfig } from 'contexts/ConfigContext';
import LocaleProvider, { LocaleConfig } from 'contexts/LocaleContext';
import NotificationProvider from 'contexts/NotificationContext';
import Report from 'helpers/report';
import { useConfig } from 'hooks/config';
import { useTranslations } from 'hooks/translation';
import { useTranslator } from 'hooks/translator';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useRoutes } from 'react-router-dom';
import i18n from 'utils/i18n';

import { ThemeProvider } from '@emotion/react';
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import routes from './routes';
import GlobalStyles from './theme/GlobalStyles';

const App = () => {
  const translations = useTranslations()

  const errorHandler = (error: any) => {
    console.error("An error occured: %o", error)
  }

  const fallback = ({error, resetErrorBoundary}: {error: any, resetErrorBoundary: any}) => {
    const detailReport = Report.error(Report.frontend, Report.code.frontend.Broken, {component: "main"})
    const report = Report.from(error, undefined, {verbose: detailReport.message, options: {component: "main"}})
    return report.toNotification()
  }

  return (
    <ErrorBoundary FallbackComponent={fallback} onError={errorHandler}>
      <NotificationProvider>
        <StyledEngineProvider injectFirst>
          {/* @ts-ignore */}
          <LocaleProvider>
            {(locale: LocaleConfig) =>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale.dateFnsLocale as unknown as any}>
                <ConfigProvider locale={locale}>
                  { (config: GearsConfig) =>
                    <MuiThemeProvider theme={config.props.theme.theme}>
                      <ThemeProvider theme={config.props.theme.theme}>
                        <GlobalStyles />
                        <AppContent loading={config.loading || translations.loading}/>
                      </ThemeProvider>
                    </MuiThemeProvider>
                  }
                </ConfigProvider>
              </LocalizationProvider>
            }
          </LocaleProvider>
        </StyledEngineProvider>
      </NotificationProvider>
    </ErrorBoundary>
  );
};

const AppContent = ({loading}: {loading: boolean}) => {
  const user                       = useCurrentUser()
  const content                    = useRoutes(routes(user));
  const { ready }                  = useTranslation('translation', { useSuspense: false })
  const languageIsLoading          = !ready || !i18n.isInitialized
  const { loading: configLoading } = useConfig()

  // console.log("loading=%o, user.loading=%o, languageIsLoading=%o", loading, user.loading, languageIsLoading)
  return (
    <PageLoader size='large' loading={loading || user.loading || languageIsLoading || configLoading}>
      {content}
    </PageLoader>
  )
}

export default App;
