import { Divider } from '@mui/material';
import FileTiles   from 'components/tile/FileTiles';
import { FileRef } from 'types/fileref';

const Attachments = ({fileRefs}: {fileRefs: FileRef[]}) => (
  <>
    { fileRefs?.length ? <Divider /> : null }
    <FileTiles attachments={fileRefs} />
  </>
)

export default Attachments