import Menu from '@mui/material/Menu';
import React from 'react';
import { ReactChildren, SetState } from 'types/react';
import SidebarContextProvider from 'contexts/SidebarContext';
import { useSidebarContext } from 'hooks/sidebar';

export type SidebarItemType = "group" | "cascading" | "item"

export type SidebarItemProps = {
  type: SidebarItemType,
  key: string,
  ref?: string,
  children?: Array<SidebarItemProps>,
  label?: string,
  href?: string,
  icon?: string,
  activeIcon?: string
}

type SidebarMenuProps = {
  anchorEl: null | HTMLElement
  setAnchorEl: SetState<null | HTMLElement>,
  children: ReactChildren
}

export default function SidebarMenu({children, anchorEl, setAnchorEl}: SidebarMenuProps){
  const { level, sidebarType, handleMenuClose } = useSidebarContext()

  const handleClose = () => {
    setAnchorEl(null);
    handleMenuClose()
  };

  return (
    <Menu
      id="sidebar-menu"
      anchorEl={anchorEl}
      MenuListProps={{onMouseLeave:handleClose}}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      sx={{ zIndex: 1000, marginLeft: sidebarType == "menu" || level != 0 ? "5px" : "40px" }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: sidebarType == "menu" || level > 0 ? 'right' : 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <SidebarContextProvider sidebarType={"menu"} addLevel handleMenuClose={handleClose}>
        {children}
      </SidebarContextProvider>
    </Menu>
  );
}

