import { useLocation } from 'react-router';

export const useSearchVariable = (key: string): string | null => { 
  const { search } = useLocation();

  return getVariableFromSearch(search, key) 
}

export function getSearchVariable(key: string): string | null {
  const hash  = window.location.hash
  const index = (hash || '').indexOf('?')

  if (index < 0)
    return null

  return getVariableFromSearch(hash.slice(index), key)
}

function getVariableFromSearch(search: string, key: string) {
  const parameters = new URLSearchParams(search);
  return parameters.get(key)  
}
