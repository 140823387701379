import { Box }                  from '@mui/material'
import RenderContextProvider    from 'contexts/RenderContext'
import Elevate                  from 'components/common/Elevate'
import ListSectionTitle         from 'components/view/ListSectionTitle'
import ListTable, { ListProps } from 'components/view/ListTable'

const ListSection = (listProps: ListProps) => {
  return (
    <RenderContextProvider parent="list" props={{ listKey: listProps.section.key, detailKey: listProps.section.detail }}>
      <Elevate sx={{ 
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: "hidden",
        display: "flex", 
        height: "fit-content", 
      }}>
        <Box sx={{ overflow: "hidden", maxHeight: '100%', maxWidth: '100%', display: 'flex', flexFlow: 'column' }}>
          <ListSectionTitle schema={listProps.schema} section={listProps.section} />
          <Box id='list-container' sx={{display: "flex", flexGrow: 1, overflow: "auto"}}>
            <ListTable {...listProps}/>
          </Box>
        </Box>
      </Elevate>
    </RenderContextProvider>
  )
}

export default ListSection
