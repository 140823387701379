import * as icons from '@mui/icons-material';

export function getIcon(name: string): icons.SvgIconComponent {
  const defaultFailure = 'SentimentVeryDissatisfiedOutlined'
  // @ts-ignore
  return name && hasIcon(name) ? icons[name] : icons[defaultFailure]
}

export function hasIcon(name: string): boolean {
  // @ts-ignore
  return Boolean(icons[name])
}