import { Stack }        from '@mui/material'
import LanguageSelector from "components/settings/LanguageSelector"
import ThemeSelector    from "components/settings/ThemeSelector"
import SettingsDialog   from "components/common/SettingsDialog"
import RenderSelector   from 'components/settings/RenderSelector'
import PropTypes        from 'prop-types'

const UserSettings = (props) => {
  return (
    <SettingsDialog {...props} >
      <Stack spacing={2} sx={{mt: "10px"}}>
        <LanguageSelector/>
        <ThemeSelector/>
        <RenderSelector/>
      </Stack>
    </SettingsDialog>
  )
}

UserSettings.propTypes = {
	open:    PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired
}

export default UserSettings
