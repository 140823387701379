import RefreshIcon        from '@mui/icons-material/Refresh';
import PageButton         from 'components/table/page/PageButton';
import { useTranslation } from 'react-i18next';

const MenuBarReload = ({onClick, loading, disableReload}) => {
  const {t} = useTranslation()
  if (!disableReload) {
    return (
      <PageButton
        disabled={!onClick}
        onClick={onClick}
        loading={loading}
        tip={t('table.reload')}
        Icon={RefreshIcon}
      />
    )
  } else return null
}

export default MenuBarReload