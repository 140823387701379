import { Box, }    from '@mui/material';
import { Tooltip } from '@mui/material';
import IconButton  from '@mui/material/IconButton';
import Loader      from 'components/common/Loader'

const PageButton = ({disabled, onClick, loading, tip, Icon}) => {
  return (
    <Tooltip disableInteractive title={tip} enterDelay={1500} >
      <span>
        <IconButton disabled={disabled} onClick={loading ? () => {} : onClick}>
          <Icon sx={{visibility: loading ? 'hidden': 'visible'}}/>
          { loading ? <Box sx={{marginTop: "-5px", position: "absolute"}}> <Loader size='micro'/></Box>: null }
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default PageButton