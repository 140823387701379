import { useContext }       from "react";
import { FormContext }      from "contexts/FormInfoContext";
import { GET_CHOICES }      from "queries/task";

export const useFormInfo = () => useContext(FormContext)

export function useTaskId() {
  const formInfo = useFormInfo()
  return formInfo.taskId
}

export function useChoicesQuery(formInfo, path, start, count) {
  const query = GET_CHOICES
  const variables = { 
    taskId: formInfo.taskId, 
    processDefinitionId: formInfo.processDefinitionId,
    path, start, count
  }
  return useQuery(query, { variables })
}
