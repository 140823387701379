import { RenderValue } from 'components/render';
import { useFormInfo } from 'hooks/form';
import _ from 'lodash';

import { Box } from '@mui/material';

const TaskBasedon = ({fullwidth = true}: {fullwidth?: boolean}): JSX.Element => {
  const formInfo = useFormInfo()
  const basedOn  = formInfo.form.values?.__basedOn

  return (
    <Box className='based-on-container' sx={{padding: "10px", width: fullwidth ? "100%" : undefined, height: "fit-content", display: "flex", overflow: 'auto', maxWidth: "100%", maxHeight: "100%"}}>
      {/* @ts-ignore */}
      <RenderValue> 
        {basedOn} 
      </RenderValue>
    </Box>
  )
}

export default TaskBasedon

