import { gql } from '@apollo/client'

export const GET_MESSAGE = gql`
  query GetMessage($id: ID!) {
    message(id: $id) {
      id
      sender {
        id
        username
      }
      recipient {
        id
        username
      }
      subject
      body
      attachments {
        id
        filename
        contentType
      } 
      readAt
      publishedAt
    }
  }
`

export const GET_MESSAGES = gql`
  query GetMyMessages($start: Int!, $count: Int!) {
    myMessages(range: {start: $start, count: $count}) {
      id                  
      sender {
        id
        username
      }
      subject             
      attachments         { id } 
      readAt              
      publishedAt           
      processKey
    }
    myMessagesCount
  }
`

export const DELETE_MESSAGE = gql`
  mutation deleteMessage($id: ID!) {
    deleteMessage(id: $id)
  }
`

export const READ_MESSAGE = gql`
  mutation markMessageRead($id: ID!, $read: Boolean!) {
    markMessageRead(id: $id, read: $read)
  }
`
