import { SelectedRows } from 'components/form/fields/multiple/MultipleFlex';
import { toFieldRenderType } from 'components/form/utils/field-utils';
import { basedOnEntries, basedOnKeys, getInputLabel } from 'components/form/utils/form-utils';
import { FormInfo } from 'contexts/FormInfoContext';
import { F } from 'helpers/formatter';
import GearsTranslator from 'helpers/translator';
import { useConfig } from 'hooks/config';
import { useFieldInfo } from 'hooks/field';
import { useFormInfo } from 'hooks/form';
import { useTranslator } from 'hooks/translator';
import { useMemo } from 'react';
import { FieldRenderType } from 'types/field';
import { Field } from 'types/graphql';
import { MultipleColumn, MultipleRow, MultipleRows } from 'types/multiple';
import { TaskRendering } from 'types/theming';
import Types from 'types/types';

export function getMultipleNrFields(props: any): number {
  return props.fields.length
}

export function getMultipleNrBasedOns(props: any): number {
  const { mode, value } = props
  const closedBasedOn   = mode === "closed" && value && value[0].__basedOn
  const basedOnNames    = closedBasedOn && basedOnKeys(closedBasedOn) || []

  return basedOnNames.length
}

export function getMultipleNrColumns(props: any): number {
  return getMultipleNrFields(props) + getMultipleNrBasedOns(props)
}

export interface MultipleColumnProps extends Field {
  taskRendering: TaskRendering
  formInfo: FormInfo
  rpath: string
  processKey: string
  translator: GearsTranslator
  rows: MultipleRows
}

export const useMultipleColumns = (rows: MultipleRows): MultipleColumn[] => {
  const { translator }           = useTranslator()
  const formInfo                 = useFormInfo()
  const {props: {taskRendering}} = useConfig()
  const { info }                 = useFieldInfo()

  return useMemo(() => getMultipleColumns({
    processKey: formInfo.processDefinition.key, 
    formInfo,
    taskRendering,
    rows, 
    ...info.field, 
    translator, 
    rpath: info.rpath
  }), [])
}

export function getMultipleColumns(props: MultipleColumnProps): MultipleColumn[] {
  const {formInfo, rpath, translator, processKey, taskRendering} = props

  const basedOnColumns = (basedOn: any)=> {
    if (Types.isObject(basedOn)) {
      return basedOnEntries(basedOn)
        .map(([key, _]) => (
          {
            name: translator.toLabel(processKey, key, F.toSentenceCase(key.replaceAll("_", " "))),
            attr: `__basedOn.${key}`,
          }
        ))
    } else if (Types.isSimpleType(basedOn)) {
      return [{
        name: translator.toLabel(processKey,`${rpath}_column`, F.toSentenceCase(translator.t('basedon'))),
        attr: '__basedOn',
      }]
    } else
      return []
  }

  const { mode, fields, rows } = props
  const closedBasedOn = mode === "closed" && rows[0]?.__basedOn != undefined ? rows[0]?.__basedOn : undefined

  const fieldNames: MultipleColumn[] = (fields || []).map((field: Field): MultipleColumn => ({ 
    field: field,
    attr: field.name, 
    renderType: toFieldRenderType(formInfo, field, taskRendering),  
    name: getInputLabel(translator, field, formInfo.processDefinition.key) || formInfo.processDefinition.name, 
    required: !Boolean(field.optional),
    type: 'input'
  }))

  const basedOnNames: MultipleColumn[] = (closedBasedOn != undefined ? basedOnColumns(closedBasedOn) : [])
    .map((obj) => ({
      ...obj,
      name: obj.name as string,
      renderType: "TEXT" as FieldRenderType,
      required: false, 
      type: 'basedon'
    }) )

  return [...basedOnNames, ...fieldNames]
}

function sort(selected: SelectedRows): SelectedRows {
  return selected
    .slice()
    .sort(function(a, b) { return a - b; })
}

export function deleteSelectedRows(rows: MultipleRows, selected: SelectedRows) {
  if (!selected.length)
    return rows

  const copy = [...rows]
  const sortedSelected = sort(selected).reverse()
  sortedSelected.forEach(id => copy.splice(id,1))

  return copy
}

export function insertRowsAt(rows: MultipleRows, insertRows: MultipleRows, at: number): MultipleRows {
  return rows.slice(0,at)
    .concat(insertRows)
    .concat(rows.slice(at))
}

export function copySelectedRows(rows: MultipleRows, selected: SelectedRows, at: number): MultipleRows {
  if (!selected.length)
    return rows

  const insertRows = sort(selected)
    .map(id => rows[id])
    .map(row => ({...row, __original_index: undefined}))

  return insertRowsAt(rows, insertRows, at)
}

export const addRowAt = (rows: MultipleRows, row: MultipleRow, at: number): MultipleRows => {
  return insertRowsAt(rows, [row], at)
}
