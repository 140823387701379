import {
  Table,
  TableRow,
  Paper,
  TableBody,
  TableCell,
  TableContainer
} from "@mui/material"
import { basedOnEntries } from "components/form/utils/form-utils"
import RenderTableCell from "components/render/RenderTableCell"
import { RenderValue } from "components/render"
import RenderContextProvider from "contexts/RenderContext"
import { useGearsTranslation } from "hooks/translation"
import { useRenderContext } from "hooks/render"
import { useFormInfo } from "hooks/form"

const RenderObjectTableRows = ({object}) => {
  const { translator } = useGearsTranslation()
  const formInfo       = useFormInfo()
  const processKey     = formInfo?.processDefinition?.key

  const toLabel = (key) =>
    translator.toLabel(processKey, key, key)

  return basedOnEntries(object).map(([key, value], index) => {
    const label = toLabel(key)
    return (
      <TableRow based-on={`based-on-row-${index}`} hover key={'line' + index} >
        <TableCell style={{width: "1%", whiteSpace: 'nowrap'}} align="left"  based-on={`based-on-cell-${index}-0`} >
          <RenderValue>
           {`${label}:`}
          </RenderValue>
        </TableCell>
        <RenderTableCell columnIndex={2} rowIndex={index} align="left" basedOn={value}/>
      </TableRow>
    )
  })
}

const RenderObjectTable = ({object}) => {
  const {parent} = useRenderContext()
  const props    = parent == "table" ? {} : {component: Paper}

  return (
    <TableContainer sx={{"& td": { verticalAlign: "top" }}} {...props} >
      <Table size="small" >
        <TableBody>
          <RenderContextProvider parent="table" >
            <RenderObjectTableRows object={object} />
          </RenderContextProvider>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default RenderObjectTable
