import React, { createContext, useRef } from 'react';

export type SetAutoSubmit = React.Dispatch<React.SetStateAction<number>> 
export type AutoSubmitRef = React.MutableRefObject<SetAutoSubmit | null>

export const AutoSubmitContext = createContext<AutoSubmitRef>(null as unknown as AutoSubmitRef);

const AutoSubmitProvider = ({children}: {children: React.ReactNode}) => {
  const ref = useRef<SetAutoSubmit | null>(null)

  return (
    <AutoSubmitContext.Provider value={ref}>
      {children}
    </AutoSubmitContext.Provider>
  )
}
 
export default AutoSubmitProvider;

