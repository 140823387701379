import { format, parse, isValid } from 'date-fns'
import { nullOrEmpty } from "components/form/fields/utils/values"
// default JavaScript date format is ECMA-262

const now = new Date()
const formatter = (pattern) => ({
  pattern: pattern,
  format(value) {
    return value == null ? '' : format(value, pattern)
  },
  parse(value, lenient = false) {
    let result = nullOrEmpty(value) ? null : parse(value, pattern, now)
    return lenient && !isValid(result) ? null : result
  }
})

// internal representation
export const reference = {
  date:     formatter('yyyy-MM-dd'),
  time:     formatter('HH:mm:ss'),
}

