import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useApolloClient} from '@apollo/client'
import {Download as DownloadIcon} from '@mui/icons-material'
import {Box} from '@mui/material'
import LoadingIcon from 'components/common/LoadingIcon'
import ContentPreview from 'components/form/fields/content/ContentPreview'
import ContentViewer from 'components/form/fields/content/ContentViewer'
import {RenderValue} from 'components/render'
import LargeElevatedTable from 'components/tables/LargeElevatedTable'
import {Content, ContentViewEvent} from 'helpers/content'
import GearsTranslator from 'helpers/translator'
import {useResponsiveColumns} from 'hooks/columns'
import {useContentLoader, useContentOnClick} from 'hooks/content'
import {useNotifier} from 'hooks/notification'
import {useTranslator} from 'hooks/translator'
import {GET_DOCUMENTS} from 'queries/documents'
import {GearsDocument} from 'types/graphql'
import {SetState} from 'types/react'
import {getExtension} from 'utils/files'
import {createDataLoader} from 'utils/table'

type DocumentEvent = {
  event: KeyboardEvent | null
  document: GearsDocument | undefined
}

const DocumentTable = () => {
  const client = useApolloClient()
  const columns = useColumns()
  const {translator} = useTranslator()
  const [de, setDe] = useState<DocumentEvent>({event: null, document: undefined})
  const notifier = useNotifier()
  const {t} = useTranslator()

  useEffect(() => {
    if (de.document)
      notifier.info(t("table.loaddocument", {variables: {filename: de.document.filename}}) as string)
  },[de])

  const requestInfo = {
    variables: {},
    query:     GET_DOCUMENTS,
    dataPath:  'data.documents',
    countPath: 'data.documentsCount',
  }

  const toTableRow = (document: GearsDocument) => toTableRowData(translator, document, setDe)
  const dataLoader = createDataLoader(client, requestInfo, toTableRow)

  return (
    <React.Fragment>
      <LargeElevatedTable
        description="documents"
        dataLoader={dataLoader}
        columns={columns}
      />
      <DocumentViewer de={de} setDe={setDe} />
    </React.Fragment>
  )
}

type DocumentViewerProps = {
  de:    DocumentEvent
  setDe: SetState<DocumentEvent>
}

const DocumentViewer = ({de, setDe}: DocumentViewerProps)  => {
  const [open, setOpen] = useState<boolean>(false)
  const {content, loadContent} = useContentLoader({
    id: de.document?.id || 'none',
    filename: de.document?.filename || 'file.pdf',
    type: 'document'
  })

  useEffect(() => {
    if (content.id != 'none')
      loadContent().then(_ => setOpen(true))
  }, [content])

  const closeDocument: SetState<ContentViewEvent> = (open: ContentViewEvent | ((state: ContentViewEvent) => ContentViewEvent)) => {
    setOpen(!!open)
    if (!open) 
      setDe({document: undefined, event: null})
  }

  return <ContentViewer content={content} open={open && de.event || null} setOpen={closeDocument}/>
}

const useColumns = () => {
  const { t } = useTranslation() // NOTE: hook call inside a function. be carefull when calling this.

  const config = {
    'default': [
      {
        field: 'name',
        headerName: t('explorer.title'),
        width: 200
      }
    ],
    'sm': [
      {
        field: 'createdAt',
        headerName: t('explorer.created_at'),
        width: 100
      }
    ],
    'md': [
      {
        field: 'creator.username',
        headerName: t('explorer.creator'),
        width: 100
      }
    ],
    'lg': [
      {
        field: 'type',
        headerName: t('explorer.type'),
        width: 100
      }
    ],
    'xl': [
    ]
  }

  return useResponsiveColumns(config)
}

/* convert a tasks to row data structure */
function toTableRowData(translator: GearsTranslator, document: GearsDocument, setDe: SetState<DocumentEvent>) {
  return {
    id: document.id,
    onClick: (e: KeyboardEvent) => {
      setDe({event: e, document: document})
    },
    cells: {
      ...document,
      name: <DocumentTitle document={document} />,
    }
  }
}

const DocumentTitle = ({document}: {document: GearsDocument}) => {
  const extension                  = getExtension(document.filename)
  const {loading, downloadOnClick} = useContentOnClick({
    id: document.id, filename: document.filename, type: 'document'
  })

  return (
    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
      <Box style={{ marginLeft: "0px", marginRight: "10px", display: "flex", flexShrink: 0, alignItems: "center", minWidth: "20px", height: "30px", maxHeight: "30px", overflow: "hidden" }}> 
        <Box sx={{width: "20px"}}>
          <ContentPreview content={{extension: extension, type: document.type} as Content} fallback={undefined} />
        </Box>
        <LoadingIcon icon={DownloadIcon} tooltipProps={{title: "Download attachment"}} loading={loading} onClick={downloadOnClick} />
      </Box>
      <RenderValue>
        {document.title}
      </RenderValue>
    </Box>
  )
 }

export default DocumentTable;
