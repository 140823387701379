import { validateField } from 'components/form/utils/validate-utils';
import { useFormikContext } from 'formik';
import { useAutoSubmitSignal } from 'hooks/autosubmit';
import { useFieldInfo } from 'hooks/field';
import _ from 'lodash';
import { isOption } from 'utils/option-utils';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FormControl, FormHelperText, ToggleButton, ToggleButtonGroup } from '@mui/material';

const InputBooleanButton = () => {
  const { fieldProps, info, augProps } = useFieldInfo()
  const value                          = isOption(fieldProps.value) ? fieldProps.value.value : fieldProps.value
  const formik                         = useFormikContext()
  const isError                        = _.get(formik.errors, info.rpath)
  const {signal}                       = useAutoSubmitSignal()

  function handleValidate(e: React.SyntheticEvent, value: any) {
    const error = validateField("boolean", fieldProps.required, e, value)
    augProps.setError(error)
  }

  function handleChange(e: React.SyntheticEvent, newValue: any) {
    if (fieldProps.required && newValue === null) return
    augProps.setRuntimeError(undefined)
    const formValue = newValue === undefined ? null : newValue
    augProps.setValue(formValue)
    handleValidate(e, formValue)
  }

  function handleBlur(e: React.SyntheticEvent) {
    fieldProps.onBlur(e)
    handleValidate(e, fieldProps.value)
    signal()
  }

  return (
    <>
      <FormControl id={fieldProps.id} component="fieldset" required={fieldProps.required} error={fieldProps.error} sx={{flexDirection: "row", width: 0}}> 
        <ToggleButtonGroup
          exclusive
          value={value}
          size="small"
          onChange={handleChange}
          onBlur={handleBlur}
          sx={{alignSelf: "center"}}
        >
          <ToggleButton value="true" sx={{borderColor: isError ? theme => theme.palette.error.main : undefined}} color="success">
            <CheckOutlinedIcon/>
          </ToggleButton>
          <ToggleButton value="false" sx={{borderColor: isError ? theme => theme.palette.error.main : undefined}} color="error">
            <CloseOutlinedIcon/>
          </ToggleButton>
        </ToggleButtonGroup>
        <FormHelperText>{fieldProps.helperText}</FormHelperText>
      </FormControl>
    </>
  );
}

export default InputBooleanButton
