import { gql } from '@apollo/client'

export const GET_LIST_DATA = gql`
  query GetList($key: ID!, $start: Int!, $count: Int!, $filter: String) {
    list(key: $key) {
      id,
      data(filter: $filter, range: { start: $start, count: $count })
      count(filter: $filter)
    }
  }
`

export const GET_LIST_WITH_SCHEMA = gql`
  query GetList($key: ID!, $start: Int!, $count: Int!, $filter: String) {
    list(key: $key) {
      id,
      data(filter: $filter, range: { start: $start, count: $count })
      schema
    }
  }
`

export const GET_LIST_FORMVALUES = gql`
  query GetListFormValues($listKey: ID!, $linkKey: ID!) {
    list(key: $listKey) {
      link(key: $linkKey) {
        id, formValues
      },
      schema
    }
  }
`

export const GET_LIST_SCHEMA = gql`
  query GetListSchema($key: ID!) {
    list(key: $key) {
      id,
      links {
        id, key, ref, label, icon, kind, conditional, processKey, enabled, conditional 
      },
      schema
    }
  }
`
