import _ from 'lodash'
import { TableHead, TableRow, TableCell, Checkbox } from '@mui/material'

import { MultipleTableHeaderFieldCells } from 'components/form/fields/multiple'
import { MultipleRowProps } from 'components/form/fields/multiple/MultipleTable'

const MultipleTableHeader = (props: MultipleRowProps) => {
  const { select, reorder, add, remove } = props.features
  return (
    <TableHead>
      <TableRow>
        {reorder ? (
          <TableCell key="dragcolumn" sx={{width: "1%", padding: 0 }}>
          </TableCell>
        ) : null}
        {select ? (
        <TableCell key="selection" sx={{ height: "60px", width: "fit-content", padding: 0 }}>
          <MultipleCheckBox rows={props.rows} selected={props.selected} setSelected={props.setSelected} />
        </TableCell>
        ) : null}
        <MultipleTableHeaderFieldCells {...props} />
        {add || remove ? (
          <TableCell width={0} sx={{padding: 0}}></TableCell>
        ) : null}
      </TableRow>
    </TableHead>
  )
}

const MultipleCheckBox = ({rows, selected, setSelected}: any) => {
  const handleChange = (event: any, value: any) => {
    if (value)
      setSelected(_.range(rows.length))
    else
      setSelected([])
  }

  const indeterminate = Boolean(selected.length && selected.length < rows.length)
  const checked       = Boolean(rows.length > 0 && selected.length === rows.length)

  return (
    <Checkbox
      tabIndex={-1}
      size="small"
      color="primary"
      indeterminate={indeterminate}
      checked={checked}
      onChange={handleChange}
      name="multiple"
    />
  )
}

export default MultipleTableHeader