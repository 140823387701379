import LastPageIcon       from '@mui/icons-material/LastPage';
import { useTranslation } from 'react-i18next';
import PageButton         from 'components/table/page/PageButton';


const LastPage = ({onClick, loading}) => {
  const { t }    = useTranslation()

  return <PageButton
    disabled={!onClick}
    onClick={onClick}
    loading={loading}
    tip={t('table.last')}
    Icon={LastPageIcon}
  />
}

export default LastPage