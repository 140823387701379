import { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import InputError, { InputErrorProps } from 'components/form/fields/InputError'
import { fieldMinWidthStyle } from 'components/form/utils/field-utils'
import { getEventValue, validateField, workaroundFieldLabelOverlap } from 'components/form/utils/validate-utils'
import { useAutoSubmitSignal } from 'hooks/autosubmit'
import { useConfig } from 'hooks/config'
import { useFieldInfo } from 'hooks/field'
import { useFormInfo } from 'hooks/form'
import { useLocale } from 'hooks/locale'
import { isEmptyValue } from 'utils/utils'

type DecimalSeparator = "dot" | "comma";

const InputDecimalField = () => (
  <InputError>
    {/* @ts-ignore */}
    <InputDecimalFieldContent />
  </InputError>
)

const InputDecimalFieldContent = (props: InputErrorProps) => {
  const {setFocus}                   = props
  const {augProps, fieldProps, info} = useFieldInfo()
  const formInfo                     = useFormInfo()
  const {signal}                     = useAutoSubmitSignal()
  const {props: {taskRendering}}     = useConfig()
  const {locale}                     = useLocale()
  const decimalSeparator             = determineDecimalSeparator(locale)
  const [value, setValue]            = useState<string>(toMuiValue(fieldProps.value, decimalSeparator))

  useEffect(() => {
    if (fieldProps.value != toRuntimeValue(value)) { // value != newValue?.replace(",",".") && value != newValue?.replace(".",","))
      const getValue = () => {
        const newValue = toMuiValue(fieldProps.value, decimalSeparator)
        switch (true) {
          case value?.includes('.'): return newValue?.replace(',', '.')
          case value?.includes(','): return newValue?.replace('.', ',')
          case inputDecimalRegex.test(newValue): return newValue // case when newValue is an integer literal
          default: return value
        }
      }
      setValue(getValue())
    }
  }, [fieldProps.value, decimalSeparator, locale])

  function handleValidate(e: React.SyntheticEvent, runtimeValue: string | null) {
    const error = validateField("decimal", fieldProps.required, e, runtimeValue)
    augProps.setError(error)
  }

  function handleChange (e: React.SyntheticEvent) {
    const value        = getEventValue(e)
    const runtimeValue = toRuntimeValue(value)

    if (value == ""){
      setValue("")
      augProps.setValue(null)
    }
    else if (inputDecimalRegex.test(value)){
      setValue(value)
      if (runtimeValue)
        augProps.setValue(runtimeValue)

      handleValidate(e, runtimeValue)
    }
	}

  function handleBlur (e: React.SyntheticEvent) {
    setFocus(false)
    fieldProps.onBlur(e)
    const runtimeValue = toRuntimeValue(value) 
    handleValidate(e, runtimeValue)
    signal()
  }

  function handleFocus(e: React.SyntheticEvent) {
    setFocus(true)
    fieldProps.onFocus(e)
  }

  const workaround = workaroundFieldLabelOverlap(info.rpath)
  return (
    <TextField
      {...fieldProps}
      value={value}
      {...workaround}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      
      style={taskRendering == 'standard' ? fieldMinWidthStyle(formInfo, info.field, taskRendering) : undefined}

	    fullWidth
    />
  )
}

const inputDecimalRegex = /^[+-]?(\d+([\,\.])?)?(\d+)?$/

function toRuntimeValue(muiValue: string): string | null {
  return isEmptyValue(muiValue) ? null : muiValue.replace(',', '.') 
}

function toMuiValue(runtimeValue: any, decimalSeparator: DecimalSeparator): string {
  const value = isEmptyValue(runtimeValue) || Number.isNaN(parseFloat(runtimeValue)) ? "" : typeof runtimeValue == "string" ? runtimeValue : JSON.stringify(runtimeValue)
  switch (decimalSeparator) {
    case "dot":   return value.replace(",", ".")
    case "comma": return value.replace(".", ",")
  }
}

function determineDecimalSeparator(locale: string): DecimalSeparator {
  switch (locale.replace("_", "-")) {
    case "en-EN": 
    case "en-US": 
      return "dot"
    case "nl-NL": 
    default: 
      return "comma"
  }
}

export default InputDecimalField
