import { Moment, isMoment } from "moment";

export function mformat(aMoment: Moment): String {
  if (isMoment(aMoment))
    return aMoment.format('LLLL ([week] WW)')
  else
    return 'invalid moment'
}

export function setToStartOfWeek(aMoment: Moment): Moment {
  return aMoment.startOf("isoWeek").startOf("day");
};

export function setToStartOfNextWeek(aMoment: Moment): Moment {
  return aMoment.endOf("isoWeek").add(1, "day").startOf("day");
};
