import merge from 'deepmerge';
import { useRenderContext } from 'hooks/render';
import { createContext } from 'react';

export type MultipleRenderType = "mutable-flex" | "mutable-table" | "immutable" 

export type RenderProps = {
  parent?: string
  multipleType?: MultipleRenderType
  context?: string
  props?: any
}

interface RenderContextProps extends RenderProps {
  children: React.ReactNode 
}

export const RenderContext = createContext<RenderProps>({} as RenderProps);

const RenderContextProvider = ({children, ...context}: RenderContextProps) => {
  const ctx = useRenderContext()  
  const value: RenderProps = merge.all([ctx, context]) as RenderProps

  return (
    <RenderContext.Provider value={value}>
	   {children}
    </RenderContext.Provider>
  )
}

export default RenderContextProvider;
