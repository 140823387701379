import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TimePicker, renderMultiSectionDigitalClockTimeView } from '@mui/x-date-pickers';
import { validateField } from "components/form/utils/validate-utils";
import { reference } from "components/form/fields/utils/dt-formats";
import { isEmptyValue } from "utils/utils";
import { useFieldInfo } from "hooks/field";
import { useFormInfo } from "hooks/form";
import InputError from "components/form/fields/InputError";
import Types from "types/types";
import { useNotifier } from "hooks/notification";
import { useAutoSubmitSignal } from "hooks/autosubmit";
import { fieldMinWidthStyle } from "components/form/utils/field-utils";
import { useConfig } from "hooks/config";

function toRuntimeValue(value) {
  try {
    return isEmptyValue(value) ? null : reference.time.format(value)
  } catch (error) {
    return null
  }
}

function toMuiValue(value) {
  try {
    return isEmptyValue(value) ? null : reference.time.parse(value)
  } catch (error) {
    return null
  }
}

const InputTimeFieldContent = (props) =>  {
  const { augProps, fieldProps, info } = useFieldInfo()
  const formInfo                       = useFormInfo()
  const { t }                          = useTranslation()
  const { setFocus, setViewOpen }      = props
  const [error, setError]              = useState(null)
  const [value, setValue]              = useState(toMuiValue(fieldProps.value))
  const notifier                       = useNotifier()
  const {signal}                       = useAutoSubmitSignal()
  const {props: {taskRendering}}       = useConfig()



  delete fieldProps.onChange

  function toHelperText(error) {
    if (info.inMultiple)
      return undefined

    if (fieldProps.error)
      return fieldProps.helperText
    else if (error)
      return t("yup.invalid.time")
    else
      return " "
  }

  function handleValidate(e, value) {
    const error = validateField("time", fieldProps.required, e, value)
    augProps.setError(error)
  }

  function handleChange (value, context) {
    const { validationError } = context

    augProps.setRuntimeError(undefined)
    setError(validationError)
    setValue(value)
    if (!validationError) {
      const runtimeValue = toRuntimeValue(value)
      augProps.setValue(runtimeValue)
      handleValidate(null, runtimeValue)
    } 
  }

  function handleBlur (e) {
    const val = error ? toRuntimeValue(value) : fieldProps.value 
    if (error || !Types.isValidDate(toMuiValue(val))) {
      augProps.setValue(null)
      setValue(null)
      notifier.error("invalid time in field " + fieldProps.label)
    }
    
    setFocus(false)
    fieldProps.onBlur(e)
    handleValidate(e, val)
    signal()
  }

  function handleFocus(e) {
    setFocus(true)
    fieldProps.onFocus(e)
  }

  const slotProps = {
    slotProps: {
      textField: (params) => ({
        ...fieldProps,
        onBlur: handleBlur,
        onFocus: handleFocus,
        ...params,
        id: fieldProps.id,
        error:  params.error || fieldProps.error,
        helperText :  toHelperText(params.error),
        style: taskRendering == 'standard' ? fieldMinWidthStyle(formInfo, info.field) : undefined,

        fullWidth: true
      }),
      actionBar: {actions: ['today','clear', 'cancel', 'accept']},
    }
  }

  // fix for clearing the field
  useEffect(() => {
    if (fieldProps.value != toRuntimeValue(value)) {
      const getValue = () => {
        const newValue = toMuiValue(fieldProps.value)
        return isValidDate(newValue) ? newValue : value
      }
      setValue(getValue())
    }
    if (value == null) 
      augProps.setValue(null)
  }, [fieldProps.value])

  // fix for clearing the field
  useEffect(() => {
    if (value == null)
      augProps.setValue(null)
  }, [value])

  return (
    <TimePicker
      className="grs-InputTimeField"
      format="HH:mm:ss"
      ampm={false}
      value={value}
      views={["hours", "minutes", "seconds"]} 
      viewRenderers={{
        hours: renderMultiSectionDigitalClockTimeView,
        minutes: renderMultiSectionDigitalClockTimeView,
        seconds:renderMultiSectionDigitalClockTimeView 
      }}

      onOpen={() => setViewOpen(true)}
      onClose={() => setViewOpen(false)}

      onChange={handleChange}
      {...augProps.inputProps}

      {...slotProps}
    />
  )
}

const InputTimeField = (props) => (
  <InputError>
    <InputTimeFieldContent />
  </InputError>
)

export default InputTimeField
