import Report, { ReportLevel } from 'helpers/report';
import { useNotifier } from 'hooks/notification';
import { useTranslator } from 'hooks/translator';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import StackTrace, { StackFrame } from 'stacktrace-js';
import { SetState } from 'types/react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Link, Stack, Table, TableBody, TableCell } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import Loader from './Loader';
import MuiIcon from './MuiIcon';

type FallbackType = "component" | "page"

const Fallback = ({children, name, type = 'component'}: {children: React.ReactNode, name: string, type?: FallbackType}) => {

  const errorHandler = (error: any) => console.error("A fontend issue occured: %o", error)

  const fallback = ({error}: {error: any}) => {
    return <Issue name={name} error={error} type={type}/>
  }

  return (
    <ErrorBoundary  FallbackComponent={fallback} onError={errorHandler}>
      {children}
    </ErrorBoundary>
  )
}

const Issue = ({name, error, type}: {name: string, error?: any, type: FallbackType}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const {translator}    = useTranslator()
  const notifier        = useNotifier()
  const { t }           = useTranslator()
  const detailReport    = Report.error(Report.frontend, Report.code.frontend.Broken, {component: name || "unknown"})
  const report          = Report.from(error, translator,
    {
      verbose: "component '" + (name || "unknown") + "': " + detailReport.message,
      options: {component: name || "unknown" }
    }
  )

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => { 
    report.addToNotifier(notifier) 
  },[])

  return (
    <Box className='issue' 
      sx={{
        padding: "10px",
        margin: "auto",
        width: "fit-content",
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: "center", 
        alignItems: "center",
      }}
    >
      <Box className='issue-message' sx={{display: 'flex', justifyContent: "center", alignItems: "center"}}>
        {/* @ts-ignore */}
        <MuiIcon name="Error" sx={{color: theme => theme.palette?.error?.main}}/>
        <Typography sx={{marginLeft: "10px"}} variant={type == 'page' ? 'h4' : undefined}>{report.message}</Typography>
      </Box>
      { error instanceof Error
        ? <Box className='issue-dialog-button-container' sx={{marginTop: "20px"}}>
          <Button color="error" size='small' variant='contained' sx={{cursor: "pointer"}} onClick={handleClickOpen}>{t('issues.showissue')}</Button>
            <IssueDialog open={open} setOpen={setOpen} title={error.message} error={error} />
          </Box>
        : null
      }
    </Box>
  )
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const IssueDialog = ({open, setOpen, title, error}: {open:boolean, setOpen: SetState<boolean>, title: string, error:Error} ) => {
  const {t} = useTranslator()

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
    
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={false}
        maxWidth={false}
        sx={{minWidth: "300px"}}
      >
        <DialogTitle fontSize={"18px"} sx={{ m: 0, p: 2, paddingRight: "50px"}} id="customized-dialog-title">
          {`${t('issues.issue')} : ${error.message}`}
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <StacktraceTable error={error}/>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

const StacktraceTable = ({error}: {error: Error}) => {
  const { t } = useTranslator()
  const [stackframes, setStackFrames] = useState<StackFrame[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    StackTrace
      .fromError(error)
      .then(stackFrames => setStackFrames(stackFrames))
      .catch(function(err: Error) {
        console.log(err.message);
      });
  },[])

  useEffect(() => {
    setTimeout(() => {
      if (stackframes.length != 0)
        setLoading(false)}, 1000)
  }, [stackframes])

  if (loading)
    return <Box sx={{margin: "auto", display: "flex", justifyContent: "center"}}><Loader size='medium'/></Box>

  return (
    <Table sx={{"& .MuiTableCell-root": {paddingY: "2px !important", paddingX: "5px", borderBottom: "none"}}}>
      <TableBody>
        {stackframes.map((sf, index) => (
          <TableRow key={index} >
            <TableCell>
              <Typography >
                {`${t('issues.at')} ${sf.getFunctionName()}`}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {sf.getFileName()?.split(/[\\/]/).pop()}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {`${sf.getLineNumber()}:${sf.getColumnNumber()}`}
              </Typography>
            </TableCell>
          </TableRow>
        )
        )}
      </TableBody>
    </Table>
  )

  return <Typography>{t('issues.nostacktrace')}</Typography>
}

export default Fallback
