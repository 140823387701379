import { useCurrentUser } from 'authentication/main';
import { useConfig } from 'hooks/config';
import { useTranslator } from 'hooks/translator';
import { useEffect, useState } from 'react';
import { User } from 'types/graphql';
import { TaskRendering } from 'types/theming';
import { selectOption } from 'utils/option-utils';

import { Autocomplete, TextField } from '@mui/material';

const RenderSelector = () => {
  const {user}: {user: User} = useCurrentUser()
  if (!(user?.isAdmin || user?.username == 'demo'))
    return null

  const {t} = useTranslator()
  const renderOptions: {value: TaskRendering, label: string}[] = [
    {value: "standard", label: t('rendering.standard') || "standard"}, 
    {value: "window", label: t('rendering.window') || "Window"}
  ]
  const {props: {taskRendering, setTaskRendering}} = useConfig()
  const [value, setValue]                          = useState(taskRendering)

  useEffect(() => {
    setTaskRendering(value)
  }, [value])

  return (
    <Autocomplete
      options={renderOptions}
      style={{ width: 300 }}
      value={selectOption(renderOptions, value)}
      autoHighlight
      disableClearable
      onChange={(event, newOption) => {
        event.preventDefault()
        event.stopPropagation()
        setValue(newOption.value)
      }}
      renderInput={(params) => (
        <TextField autoComplete='new-password' {...params} label={t('rendering.label')} fullWidth />
      )}
    />
  )
}

export default RenderSelector