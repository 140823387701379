import { F } from 'helpers/formatter';
import { env } from 'utils/utils';

export const TRANSLATION: string = env('TRANSLATION') || "normal"
console.debug("TRANSLATION=%s", TRANSLATION)

const TRANSLATION_VALUES = ["normal", "missing", "found", "all"] 
if (!TRANSLATION_VALUES.includes(TRANSLATION))
  console.error("Environment variable TRANSLATION has value %o, but it can only be one of: %s", TRANSLATION, F.toList(TRANSLATION_VALUES.map(v => String(v)), " or "))

export const AUTOSUBMIT_TIME = env('AUTOSUBMIT_TIME') || 5
export const AUTOCANCEL_TIME = env('AUTOCANCEL_TIME') || 10
