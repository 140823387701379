import { TableCell, Checkbox } from '@mui/material'
import { useFieldInfo }        from 'hooks/field'

const MultipleTableHeaderSelectCell = ({rows, selected, setSelected}) => {
  const { info } = useFieldInfo()
  const mode = info.field.mode

  return mode === "open" ? (
    <>
      <TableCell key="dragcolumn" sx={{width: "1%", padding: 0 }}>
      </TableCell>
      <TableCell key="selection" sx={{ height: "60px", width: "fit-content", padding: 0 }}>
        <MultipleCheckBox rows={rows} selected={selected} setSelected={setSelected} />
      </TableCell>
    </>
  ) : null
}

export const MultipleCheckBox = ({rows, selected, setSelected}) => {
  const handleChange = (event, value) => {
    if (value)
      setSelected(_.range(rows.length))
    else
      setSelected([])
  }

  const indeterminate = Boolean(selected.length && selected.length < rows.length)
  const checked       = Boolean(rows.length > 0 && selected.length === rows.length)

  return (
    <Checkbox
      tabIndex={-1}
      size="small"
      color="primary"
      indeterminate={indeterminate}
      checked={checked}
      onChange={handleChange}
      name="multiple"
    />
  )
}

export default MultipleTableHeaderSelectCell
