import MuiIcon from 'components/common/MuiIcon';
import { User } from 'types/graphql';

import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { Avatar as MuiAvatar, Box, useTheme } from '@mui/material';

const Avatar = ({user}: {user: User}) => {
  const iconStyle = { height: '32px', width: '32px' }

  return !user
    ? <NoAccountsIcon style={iconStyle} />
    : (
      <Box className="avatar-container" sx={{ position: "relative" }}>
        { user.isAdmin 
          ? <Box className="avatar-admin-container" sx={{ position: "absolute", top: "-6px", right: "-2px", zIndex: 500, height: "10px"}}><MuiIcon size="14px" name="AdminPanelSettings" /></Box>
          : null
        }
        <MuiAvatar style={{ ...iconStyle, display: "flex", fontSize: "18px", alignItems: "center", justifyContent: "center", textAlign: "center" }} {...stringAvatar(user.name)} />
      </Box>
    )
}

export function stringToColor(str: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

export default Avatar
