import React, { createContext, useState } from 'react';
import { SetState } from 'types/react';

export type PagePanelButtonType = {
  id: string;
  component: React.ReactNode;
};
type PagePanelProps = {
  buttons: PagePanelButtonType[];
  setButtons: SetState<PagePanelButtonType[]>;
};

export const PagePanelContext = createContext<PagePanelProps>({} as PagePanelProps);

export const PagePanelProvider = ({ children }: { children: React.ReactNode; }): JSX.Element => {
  const [buttons, setButtons] = useState<PagePanelButtonType[]>([]);

  return (
    <React.Fragment>
      <PagePanelContext.Provider value={{ buttons, setButtons }}>
        {children}
      </PagePanelContext.Provider>
    </React.Fragment>
  );
};


