import InputField from 'components/form/fields/InputField';
import { Field } from 'types/graphql';

import { Box, Stack } from '@mui/material';

const TaskFields = ({fields}:{fields: Field[]}) => {
  function toField(field: Field, index: number) {
    return (
      <Box key={'field' + index}>
        <InputField
          field={field}
          indices={{}}
        />
      </Box>
    )
  }

  return (
    <Stack spacing={0}>
      {fields.map(toField)}
		</Stack>
	)
}

export default TaskFields
