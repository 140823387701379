import {
  Box,
  Card,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next';

const MessageSubject = ({subject}) => {
  const { t } = useTranslation()

  return (
    <Card sx={{flexShrink: 0, display: "flex", flexDirection: "row"}}>
      <Box sx={{flexShrink: 0, background: (theme) => theme.palette.primary.main, padding: "5px 10px 5px 10px"}}>
        <Typography fontWeight={500}>{t('messages.subject') + ":"}</Typography>
      </Box>
      <Box sx={{flexGrow: 1, padding: "5px 10px 5px 10px"}}>
        <Typography>{subject}</Typography>
      </Box>
    </Card>
  )
}

export default MessageSubject