import { DeleteButton } from 'components/form/fields/multiple';
import { MultipleButtonProps } from 'components/form/fields/multiple/MultipleTableRowActions';
import { deleteSelectedRows } from 'components/form/fields/utils/multiple-utils';
import { useFieldInfo } from 'hooks/field';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MultipleRows, MultipleSelected } from 'types/multiple';
import { SetState } from 'types/react';

const DeleteRowButton = ({rows, index, setSelected, selected}: MultipleButtonProps) => {
  const { t } = useTranslation()
  const { augProps } = useFieldInfo()

  const handleClick = () => {
    const newRows     = deleteSelectedRows(rows, [index])
    const newSelected = selected
      .filter(sid => sid !== index)        // remove id
      .map(sid => sid > index ? sid-1 : sid) // adjust all ids higher than id

    augProps.setValue(newRows)
    setSelected(newSelected)
  }

  return <DeleteButton
    id={'row-' + index + '-delete-button'}
    title={t("multiple.deleterow")}
    disabled={rows.length === 1}
    disabledTitle={rows.length === 1 ? t("multiple.disabled.deletelastrow") : null }
    onClick={handleClick}
  />
}

DeleteRowButton.propTypes = {
  rows       : PropTypes.array.isRequired,
  index      : PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
  selected   : PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
}

export default DeleteRowButton
