import React                            from 'react'
import { Typography }                   from '@mui/material'
import { DisplayProps, toDisplayValue } from 'components/render/utils/render'
import { useLocale }                    from 'hooks/locale'
import { useRenderContext }             from 'hooks/render'
import { isHtmlString, renderHtml }     from 'utils/html'
import { ENABLE_HTML }                  from './RenderValue'

interface RenderSimpleValueProps {
  value: any
  displayProps?: DisplayProps
}

export const RenderSimpleValue = ({ value, displayProps }: RenderSimpleValueProps): JSX.Element => {
  const { props: {sx} }    = useRenderContext()
  const {language, locale} = useLocale()

  if (ENABLE_HTML && isHtmlString(value))
    return <React.Fragment>{renderHtml(value)}</React.Fragment>

  const defaultSx = { fontSize: 14, color: "text.secondary" }
  const localSx = {...defaultSx, ...sx}

  /* pre-wrap interprets \n, \r, spaces and tabs. pre-line only interprets \n and \r */
  return <Typography sx={{...localSx, whiteSpace: "pre"}}>{
    toDisplayValue(value, {language, locale, ...displayProps})
  }</Typography>
}
