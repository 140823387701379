import {
  Tooltip,
  IconButton }                            from '@mui/material';
import _                                  from 'lodash';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';

const CopyButton = ({id, onClick, title}) => (
  <Tooltip disableInteractive title={title}>
    <span>
      <IconButton tabIndex={-1} id={id} size="small" onClick={onClick} >
        <ContentCopyIcon fontSize="small"/>
      </IconButton>
    </span>
  </Tooltip>
)

export default CopyButton
