import React, { createContext, SetStateAction, useRef, useState } from 'react';
import { SetState } from 'types/react';

import { WindowHelper } from '../helpers/WindowHelper';
import { PaneProps, WindowState } from '../types';

export type WindowPageFunctions = {
  pages: PaneProps[]
  setPages: SetState<PaneProps[]>
};

export interface WindowPageState extends WindowPageFunctions {
  data: any
};

export interface WindowPageProps {
  loading: boolean;
  ref: React.MutableRefObject<WindowPageState>;
  update(windowState: WindowState, setWindowState: SetState<WindowState>): void;
}

export interface WindowPageProviderProps {
  children: React.ReactNode;
}

export const WindowPageContext = createContext<WindowPageProps>({} as WindowPageProps);

export const WindowPageProvider = ({ children }: WindowPageProviderProps): JSX.Element => {
  const ref = useRef<WindowPageState>({data: {}} as WindowPageState);
  const [loading, setLoading] = useState<boolean>(true);

  const update = (windowState: WindowState, setWindowState: SetState<WindowState>) => {
    ref.current = {
      ...ref.current,
      ...createWindowPageProps(windowState, setWindowState)
    }

    if (loading)
      setLoading(false);
  };

  return (
    <React.Fragment>
      <WindowPageContext.Provider value={{ loading, ref, update }}>
        {children}
      </WindowPageContext.Provider>
    </React.Fragment>
  );
};

function createWindowPageProps(windowState: WindowState, setWindowState: SetState<WindowState>): WindowPageFunctions {
  return {
    pages:    windowState.layout.panes,
    setPages: createSetPages(setWindowState),
  };
}

function createSetPages(setWindowState: SetState<WindowState>): SetState<PaneProps[]> {
  return (pages: SetStateAction<PaneProps[]>): void => { 
    setWindowState(windowState => WindowHelper.updateWindowState(windowState, pages))
  }
}
