import { Primitive } from 'types/types';

const PAGESIZE = "PAGE_SIZE"
const LOCALE = "LOCALE" 

class Settings { 
  static read(variable: string, defaultValue: Primitive | object = null) {

    const stored = localStorage.getItem(variable)
    if (stored === null)
      return defaultValue

    try {
      const parsed = JSON.parse(stored)
      if (parsed !== undefined)
        return parsed
      else 
        return defaultValue
    } catch(error) {
      return defaultValue
    }
  }

  static write(variable: string, value: Primitive | object) {
		localStorage.setItem(variable, JSON.stringify(value))
  }

  static session = {
    write(variable: string, value: Primitive | object) {
      if (value !== undefined)
        sessionStorage.setItem(variable, JSON.stringify(value))
    },
   
    remove(variable: string) {
      sessionStorage.removeItem(variable)
    },
    
    readOnce(variable: string, otherwise: Primitive | object = null) {
      const value = this.read(variable, otherwise)
      this.remove(variable)
      return value
    },

    read(variable: string, otherwise: Primitive | object = null) {
      const stored = sessionStorage.getItem(variable)
      if (stored === null)
        return otherwise

      try {
        const parsed = JSON.parse(stored)
        if (parsed != undefined)
          return parsed
        else 
          return otherwise
      } catch(error) {
        return otherwise
      }
    }
  }

  get pageSize(): number { return Settings.read(PAGESIZE, 10) }
 
  setPageSize(value: number) { Settings.write(PAGESIZE, value) }
  
  setLocale(value: string)   { Settings.write(LOCALE  , value) }

  get initial() {
    return {
      pageSize: this.pageSize
    }
  }

}

export default Settings