import { useContext } from "react";
import { useSnackbar, SnackbarKey } from "notistack";
import DataNotification from "components/notification/DataNotification";
import { NotificationContext } from "contexts/NotificationContext";

export type Notify = (msg: string) => SnackbarKey 

export type Notifier = {
  enqueue: any,
  close: any,
  error: Notify,
  success: Notify,
  warning: Notify,
  info: Notify,
  message: Notify,
  data: (msg: string, href: string, filename: string) => SnackbarKey
}

export const useNotifier = (): Notifier => { 
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const context = useContext(NotificationContext)
 
	return {
		...context,
		enqueue: enqueueSnackbar,
		close: closeSnackbar,
		error:   (msg) => enqueueSnackbar(msg, {preventDuplicate: true, variant: 'error'}),
		success: (msg) => enqueueSnackbar(msg, {preventDuplicate: true, variant: 'success'}),
		warning: (msg) => enqueueSnackbar(msg, {preventDuplicate: true, variant: 'warning'}),
		info:    (msg) => enqueueSnackbar(msg, {preventDuplicate: true, variant: 'info'}),
		message: (msg) => enqueueSnackbar(msg, {preventDuplicate: true, variant: 'default'}),
		data:    (msg, href, filename) => enqueueSnackbar(msg, { 
      // usage example: notifier.data("Stopped process successfully", "/img/xlrit.svg", "XLRIT.svg")
      preventDuplicate: true, 
			persist: true,
			content: (key, message) => (
				//<DataNotification ref={context.ref} href={href} filename={filename} id={key} message={message} />
				<DataNotification ref={context.ref}  />
		  )
		}),
	}
}