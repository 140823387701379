
import { useTranslation } from 'react-i18next';
import {
	Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow } from '@mui/material'
import { toDisplayValue }           from "components/render/utils/render";
  

const DeploymentTable = ({deployment}) => {
	const { t } = useTranslation()
	const cellStyle = {border: 'none'}

	return (
      <Table sx={{ minWidth: 300 }} size="small" >
        <TableBody>
					<TableRow  key="1" sx={{border:0}}>
              <TableCell align="right" component="th" sx={{...cellStyle, fontWeight:"bold"}}>{t('deployments.name')}:</TableCell> 
              <TableCell align="left" sx={cellStyle}>{deployment.name}</TableCell> 
					</TableRow>
					<TableRow>
						<TableCell align="right" component="th" sx={{...cellStyle, fontWeight:"bold"}}>{t('timestamp')}:</TableCell> 
						<TableCell align="left" sx={cellStyle}>{toDisplayValue(deployment.deploymentTime)}</TableCell> 
					</TableRow>
					<TableRow>
						<TableCell align="right" component="th" sx={{...cellStyle, fontWeight:"bold"}}>{t('source')}:</TableCell> 
						<TableCell align="left" sx={cellStyle}>{deployment.source}</TableCell> 
					</TableRow>
          <TableRow>
						<TableCell align="right" component="th" sx={{...cellStyle, fontWeight:"bold"}}>{t('number_of_defs')}:</TableCell> 
						<TableCell align="left" sx={cellStyle}>{deployment.processDefinitionsCount}</TableCell> 
					</TableRow>

        </TableBody>
      </Table>
  );
}

const ConfirmDialog = ({deployment, onClick, open, setOpen}) => {
	const {t} = useTranslation()
	const theme = useTheme()

	const handleClose = () => { setOpen(false) }
  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{fontSize: theme.typography.h3.fontSize}}>
          {t('deployments.confirmation')}
        </DialogTitle>
        <DialogContent>
					<Box sx={{mt: 3}} >
						<DeploymentTable deployment={deployment}/>
					</Box>
          <DialogContentText id="alert-dialog-description" sx={{mt: 6}}>
						{t('deployments.warning')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id={'deployment-' + deployment.id + '-undeploy-button'} onClick={(e) => { handleClose(e); onClick(e); }}>{t('deployments.undeploy')}</Button>
          <Button id={'deployment-' + deployment.id + '-cancel-button'} onClick={handleClose} autoFocus>{t('cancel')}</Button>
        </DialogActions>
      </Dialog>
  );
}

export default ConfirmDialog;