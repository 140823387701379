import { useConfig }      from 'hooks/config';
import { Helmet }         from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Card,  }    from '@mui/material'
import MessageTable       from 'components/tables/MessageTable';
import PageContainer      from 'components/common/PageContainer';
import Elevate from 'components/common/Elevate';

const Messages = () => {
  const {t}         = useTranslation()
  const { project } = useConfig()
  
  return (
    <>
      <Helmet>
        <title>{t('menu.messages')} | {project}</title>
      </Helmet>
      <PageContainer>
        <Box className='message-page'
          sx={{
            maxHeight: '100%',
            maxWidth: '100%',
            flexDirection: 'column',
            display: 'flex',
            width: "100%",

            //justifyContent: 'center',
            alignItems: "center",
          }}
        >
          <Elevate className='messages-card'
            sx={{
              maxWidth: '100%',
              maxHeight: '100%',
              width: "100%",
              display: "flex"
            }}
          >
            <MessageTable />
          </Elevate>
        </Box>
      </PageContainer>
    </>
  );
}


export default Messages;
