import i18n                   from 'i18next'
import { initReactI18next }   from 'react-i18next'
import Backend                from 'i18next-http-backend'
import LanguageDetector       from 'i18next-browser-languagedetector'

const init = i18n
  .use(Backend)           // see https://github.com/i18next/i18next-http-backend
  .use(LanguageDetector)  // see https://github.com/i18next/i18next-browser-languageDetector
  .use(initReactI18next)  // pass the i18n instance to react-i18next.
  .init({                 // see https://www.i18next.com/overview/configuration-options
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      prefix: "${",
      suffix: "}",
    },
    backend: {
      loadPath: 'locales/${lng}/${ns}.json',
    },
    react: {
      useSuspense: true
    }
  })


const t = i18n.t.bind(i18n);

export { i18n as default, t, init};
