import ChevronRightIcon   from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import PageButton         from 'components/table/page/PageButton';

const NextPage = ({onClick, loading}) => {
  const { t }    = useTranslation()

  return <PageButton
    disabled={!onClick}
    onClick={onClick}
    loading={loading}
    tip={t('table.next')}
    Icon={ChevronRightIcon}
  />
}

export default NextPage