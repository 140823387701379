import InputSelectField            from "components/form/fields/InputSelectField";
import InputSingleSelectFieldLocal from "components/form/fields/InputSingleSelectFieldLocal";
import { useFieldInfo }            from "hooks/field";

const InputSingleSelectField = (props) => {
  const { info }  = useFieldInfo()

  const valueType = info.field.type == 'BOOLEAN' ? 'value' : 'option'
    
  if (info.numOptions < 100 && !info.isDepending)
    return <InputSingleSelectFieldLocal {...props} selectProps={{valueType}}/>
  else
    return <InputSelectField {...props} selectProps={{mode: 'single', valueType}} />
}

export default InputSingleSelectField
