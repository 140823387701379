import { formatChoices } from 'components/form/utils/input-utils';
import { useFieldInfo } from 'hooks/field';
import { useFormInfo } from 'hooks/form';
import { useTranslator } from 'hooks/translator';
import { useMemo } from 'react';
import { Option } from 'types/option';

import { useLocale } from './locale';

export const useSubmitOptions = (): Option[] => {
  const { info, fieldProps } = useFieldInfo()
  const { language }         = useLocale()
  const { translator, t }    = useTranslator()
  const formInfo             = useFormInfo()

  return useMemo<Option[]>(
    (): Option[] => {
      const processKey      = formInfo.processDefinition.key
      const options         = formatChoices(translator, info.options || [], info.optionsKind, processKey)
      const addSubmitOption = !fieldProps.required || !(info.numOptions > 0)
      return addSubmitOption ? [...options, { value: 'submit', label: t('submit')! }] : options
    },
    [language]
  )
}

