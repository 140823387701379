import * as yup from 'yup';
import _ from 'lodash';
import { isEmptyValue, isEvent, isValidDate, withDefault } from 'utils/utils';
import { isDate } from 'date-fns';
import {t} from 'utils/i18n'

function toMessage(path, values) {
  return () => () => t(path, values)
}

export function getEventValue(e, ...otherwise) {
  if (isEvent(e)) {
    if (_.hasIn(e, "target.value"))
      return _.get(e, "target.value", null)
    else
      console.log("Could not get target value from event: %o", e)
  }

  return otherwise.length ? otherwise[0] : null
}

export function validateInteger(value, required, badInput) {
  const m = (path, values) => toMessage(path, values)

  if (isEmptyValue(value)) {
    return required || badInput ? m("yup.required.integer")() : undefined
  } else {
    try {
      yup
        .number(m("yup.invalid.number", { value: value }))
        .test({
          name: "whole-number",
          test: function(value) {
            if (_.isInteger(value))
              return true
            else {
              return this.createError({
                message: m("yup.invalid.integer", { value: value })
              })
            }
          }
        })
        .validateSync(value)

      return undefined
    } catch(error) {
      return error.message
    }
  }
}

export function validateDecimal(value, required, badInput) {
  const m = (path, values) => toMessage(path, values)

  if (isEmptyValue(value)) {
    return required || badInput ? m("yup.required.decimal")() : undefined
  } else {
    // validate with yup
    try {
      yup
        .number(m("yup.invalid.decimal", { value: value }))
        .validateSync(value)

      return undefined
    } catch(error) {
      return error.message
    }
  }
}

export function validateText(value, required, badInput) {
  const m = (path, values) => toMessage(path, values)

  if (isEmptyValue(value)) {
    return required || badInput ? m("yup.required.text")() : undefined
  } else {
    // validate with yup
    try {
      yup
        .string(m("yup.invalid.text", { value: value }))
        .validateSync(value)

      return undefined
    } catch(error) {
      return error.message
    }
  }
}

export function validateSelect(value, required, badInput) {
  const m = (path, values) => toMessage(path, values)

  if (isEmptyValue(value)) {
    return required || badInput ? m("yup.required.select")() : undefined
  }

  return undefined
}

export function validateFile(value, required, multiple) {
  const m = (path, values) => toMessage(path, values)

  if (isEmptyValue(value)) {
    return required
      ? (multiple ? m("yup.required.multiplefile")() : m("yup.required.file")())
      : undefined
  }

  return undefined
}


export function validateMultiSelect(value, required, badInput) {
  const m = (path, values) => toMessage(path, values)

  if (isEmptyValue(value)) {
    return required
      ? m("yup.required.multiselect")()
      : badInput
        ? m("yup.required.select")()
        : undefined
  }

  return undefined
}

export function validateTime(value, required, badInput) {
  const m = (path, values) => toMessage(path, values)

  if (isEmptyValue(value)) {
    return required || badInput
      ? m("yup.required.time")()
      : undefined
  } else if (isDate(value) && !isValidDate(value)) {
    return m("yup.invalid.time")()
  }

  return undefined
}

export function validatePeriod(value, required, badInput) {
  const m = (path, values) => toMessage(path, values)

  if (isEmptyValue(value)) {
    return required || badInput
      ? m("yup.required.period")()
      : undefined
  }
  return undefined
}


export function validateValue(value, required, badInput) {
  const m = (path, values) => toMessage(path, values)

  if (isEmptyValue(value)) {
    return required || badInput
      ? m("yup.required.value")()
      : undefined
  }
  return undefined
}


export function validateDate(value, required, badInput) {
  const m = (path, values) => toMessage(path, values)

  if (isEmptyValue(value)) {
    return required || badInput
      ? m("yup.required.date")()
      : undefined
  } else if (isDate(value) && !isValidDate(value)) {
    return m("yup.invalid.date")()
  }

  return undefined
}

export function validateDateTime(value, required, badInput) {
  const m = (path, values) => toMessage(path, values)

  if (isEmptyValue(value)) {
    return required || badInput
      ? m("yup.required.datetime")()
      : undefined
  } else if (isDate(value) && !isValidDate(value)) {
    return m("yup.invalid.datetime")()
  }

  return undefined
}

export function validateBoolean(value, required, badInput) {
  const m = (path, values) => toMessage(path, values)

  if (isEmptyValue(value)) {
    return required || badInput
      ? m("yup.required.value")()
      : undefined
  } else if (!(value === "true" || value === "false")) {
    return m("yup.invalid.boolean")()
  }

  return undefined
}

export function validateField(type, required, e, inputValue) {
  if (e !== null && !isEvent(e))
    console.error("provided argument is not an event: %o", e)

  const value    = arguments.length < 4 ? getEventValue(e) : inputValue
  const badInput = _.get(e, "target.validity.badInput", false)

  switch (type) {
    case "integer"       : return validateInteger(value, required, badInput)
    case "decimal"       : return validateDecimal(value, required, badInput)
    case "text"          : return validateText(value, required, badInput)
    case "radio"         :
    case "select"        : return validateSelect(value, required, badInput)
    case "multiselect"   : return validateMultiSelect(value, required, badInput)
    case "time"          : return validateTime(value, required, badInput)
    case "date"          : return validateDate(value, required, badInput)
    case "datetime"      : return validateDateTime(value, required, badInput)
    case "file"          : return validateFile(value, required, false)
    case "multiple file" : return validateFile(value, required, true)
    case "boolean"       : return validateBoolean(value, required, badInput)
    case "period"        : return validatePeriod(value, required, badInput)
    default :
      console.error("no validation available for type: %o", type)
      return undefined
  }
}

// workaround: the label will otherwise overlap wrong input. As if there is no input.
export function workaroundFieldLabelOverlap(rpath) {
  const element  = document.getElementById(rpath);
  const badInput = element ? _.get(element, "validity.badInput", false) : false
  return { InputLabelProps: { shrink: badInput || undefined } }
}
