import getUserLocale              from 'get-user-locale'
import * as muiLocales            from '@mui/material/locale';
import * as dateFnsLocales        from 'date-fns/locale'
import { 
  enabledLocaleOptions,
  defaultLocale, 
  enabledLocales}                 from 'contexts/utils/locale';
  import { getRegionAndLanguage } from 'utils/language'

export function normalizeLocale(locale) {
  const rl = getRegionAndLanguage(locale)
  return rl ? rl.language + '_' + rl.region : locale
}

// enabled locales. requires to be of format: [a-z]{2}_[A-Z]{2}
export function toEnabledLocale(locale) {
  const normalized = normalizeLocale(locale)
  if (enabledLocaleOptions.some(option => option.value == normalized))
    return normalized

  return null
}

function toLocale(locale) {
  if (locale) {
    const loc = toEnabledLocale(locale)
    if (loc) 
      return loc
    else
      console.error("Provided locale '%o' cannot be converted to one of the valid locales: %o", locale, enabledLocales)
  }

  return null
}

// get locale from local storage
function getStoredLocale() {
	const storedLocale = JSON.parse(localStorage.getItem('LOCALE'))
	if (storedLocale && storedLocale !== "") {
    const locale = toEnabledLocale(storedLocale)
    if (locale) 
      return locale
    else 
      console.error("Stored locale '%s' is not supported. Valid options are: %o", locale, enabledLocales)
  }

  return null
}

// attempt to get the browser locale
function getBrowserLocale() {
	const userLocale = getUserLocale()
	if (userLocale && userLocale !== "") {
    const locale = toEnabledLocale(userLocale)
    if (locale)
      return locale

    if (/^[\w]{2}$/.test(userLocale)) {
      const matchingLocale = enabledLocales.find(locale => { const rl = getRegionAndLanguage(locale); return rl.language == userLocale } )
      if (matchingLocale) 
       return matchingLocale
    }   

    console.error("Browser locale '%s' is not supported. Valid options are: %o", userLocale, enabledLocales)
  }

  return null
}

export function getInitialLocale(locale) {
	// first look at the provided locale
  const providedLocale = toLocale(locale)
  if (providedLocale) {
    console.log("Using frontend provided locale: %s", storedLocale)
    return providedLocale
  }

  // otherwise select stored locale 
  const storedLocale = getStoredLocale()
  if (storedLocale) {
    console.log("Using stored locale: %o", storedLocale)
    return storedLocale
  }

  // otherwise select browser locale 
  const browserLocale = getBrowserLocale()
  if (browserLocale) {
    console.log("Using browser locale: %s", browserLocale)
    return browserLocale
  }
    
	// otherwise select default locale
  console.log("Using default locale: %s", defaultLocale)
	return defaultLocale
}

function muiKeyToLocale(key) {
  return `${key.substring(0, 2).toLowerCase()}_${key.substring(2, 4).toUpperCase()}`
}

export function getValidMuiLocales() {
  return Object.keys(muiLocales).map(muiKeyToLocale)
}

function toLocaleKey(locale) {
  if (/^[a-zA-Z][a-zA-Z][-_][a-zA-Z][a-zA-Z]$/.test(locale))
    return locale.substring(0, 2).toLowerCase() + locale.substring(3, 5).toUpperCase()
   else if (/^[a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z]$/.test(locale))
    return locale.substring(0, 2).toLowerCase() + locale.substring(2, 4).toUpperCase()
   else
    return undefined
}

export function toMuiLocale(locale) {
  const key = toLocaleKey(locale)

  if (key in muiLocales)
    return { key: key, value: muiLocales[key] }
  else if (locale in muiLocales)
    return { key: locale, value: muiLocales[locale] }

  console.error("could not get mui locale, based on: %o", locale)
  return undefined
}

export function toDateFnsLocale(locale) {
  const key = toLocaleKey(locale) || ""

  const country = key.substring(0,2).toLowerCase()
  if (key in dateFnsLocales)
    return { key: key, value: dateFnsLocales[key] }
  else if (country in dateFnsLocales)
    return { key: country, value: dateFnsLocales[country] }
  else if (locale in dateFnsLocales)
    return { key: locale, value: dateFnsLocales[locale] }

  console.error("could not get date fns locale, based on: %o", locale)
  return undefined
}

export function toLocaleProps(locale) {
  if (!/^[a-z][a-z][-_][A-Z][A-Z]$/.test(locale))
    console.warn("Locale does not have the expected format: %o", locale)

  const mui = toMuiLocale(locale)
  const fns = toDateFnsLocale(locale)
  return {
    locale       : locale,
    language     : locale.substring(0,2).toLowerCase(),
    muiKey       : mui ? mui.key : undefined,
    muiLocale    : mui ? mui.value : undefined,
    dateFnsKey   : fns ? fns.key : undefined,
    dateFnsLocale: fns ? fns.value : undefined,
  }
}
