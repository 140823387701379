import Breadcrumb from 'components/breadcrumbs/Breadcrumb';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

import { Crumb } from './Crumb';

const Breadcrumbs = ({crumbs, chipped = false}: {crumbs: Crumb[], chipped?: boolean}) => {
  return (
      <MuiBreadcrumbs
        separator={<BreadCrumbSeparator/>}
        maxItems={4}
        itemsAfterCollapse={2}
        itemsBeforeCollapse={2}
        sx={{
          ".MuiBreadcrumbs-ol": {gap: "5px"}, 
          ".MuiBreadcrumbs-separator": { margin: 0 }, 
        }}
      >
        <Typography></Typography> {/* this causes the leading separator*/}
        { crumbs.map( (crumb, index) => 
          <Breadcrumb chipped={chipped} crumb={crumb} key={'' + index} id={index} clickable={index + 1 != crumbs.length} /> )
        }
      </MuiBreadcrumbs>
  );
}

const BreadCrumbSeparator = () => <NavigateNextIcon  fontSize="small" />

export default Breadcrumbs;