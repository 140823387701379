import {
  Box,
  Card
} from '@mui/material';
import MessageInfo from 'components/message/MessageInfo';
import MessageAttachments from 'components/message/Attachments';
import Types from 'types/types';
import { RenderValue } from 'components/render';

const convertToFileRefs = (attachments) => {
  if (Array.isArray(attachments) && attachments.length > 0 && attachments[0].__typename == "ContentRef") {
    return attachments.map(attachment => ({...attachment, __id: attachment.id || attachment.__id, __kind: "content-ref"}))
  } else return attachments
}
const MessageBody = (message) => {
  const attachments = Types.asFileRefs(convertToFileRefs(message.attachments))
  return (
    <Card sx={{marginTop: "20px", display: "flex", flexDirection: "column", overflow: "auto"}}>
      <Box sx={{flexShrink: 0}}>
        <MessageInfo {...message} />
      </Box>

      <Box sx={{flexGrow:1, padding: "20px", overflow: "auto"}}>
        <MessageContents body={message.body} />
      </Box>
      <Box sx={{flexShrink: 0, maxWidth: "100%"}}>
        < MessageAttachments fileRefs={attachments}/>
      </Box>
    </Card>
  )
  }

const MessageContents = ({body}) => {
  return (
    <RenderValue>
      {body}
    </RenderValue>
  )
}

export default MessageBody
