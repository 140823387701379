import React from 'react';

import { Box, Divider, Typography } from '@mui/material';

import { usePaneContext } from '../hooks/usePaneContext';

export const TitleComponent = () => {
  const { title, subtitle } = usePaneContext();

  if (title != undefined) {
    return (
      <Box className='pane-title' sx={{ flexShrink: 0, display: "flex", flexDirection: "column" }}>
        <Box sx={{ padding: "10px" }}>
          <Typography variant="h3" sx={{}}>{typeof title === 'function' ? title() : title}</Typography>
          {subtitle != undefined
            ? <Typography sx={{ paddingTop: "10px", color: theme => theme.palette.grey[700]}}>{typeof subtitle === 'function' ? subtitle() : subtitle}</Typography>
            : null}
        </Box>
        <Divider />
      </Box>
    );
  } else return null;
};
