
import { useEffect, useState }      from 'react'
import { useTranslator }            from 'hooks/translator'
import RenderContextProvider        from 'contexts/RenderContext'
import { F }                        from 'helpers/formatter'
import Elevate                      from 'components/common/Elevate'
import LoadingTable                 from 'components/table/LoadingTable'

const GroupSection = ({ schema, section, loader }) => {
  const {columns, rows: initialRows} = useGroupData(schema, section) // initialRows doesn't contain the values yet
  const [loading, setLoading]        = useState(false)
  const [rows, setRows]              = useState(initialRows)

  const setData = (data) => {
    const rows = initialRows.map(row => {
      const newValue = data[row.cells.key]
      const newCells = { ...row.cells, value: newValue }
      return { ...row, cells: newCells }
    })
    setRows(rows)
  }

  useEffect(() => {
   loader(setLoading, setData)
  },[])

  return (
    <RenderContextProvider context="group">
      <Elevate sx={{height: 'auto', maxHeight: '100%', maxWidth: '100%', width: 'min-content'}}>
        <LoadingTable columns={columns} rows={rows} loading={loading} />
      </Elevate>
    </RenderContextProvider>  
  )
}

const useGroupData = (schema, section) => {
  const { translator } = useTranslator()

  const sectionTitle = translator.translate(
    `${schema.translationBaseKey}.sections.${section.key}`,
    { user: true, fallback: section.label, format: F.toTitleCase }
  )

  const cellProps = { sx: {whiteSpace: 'nowrap'} }
  const columns = [
    { headerName: sectionTitle, field: 'label', cellProps }, 
    { headerName: '',           field: 'value', cellProps },
  ]

  console.debug("useGroupData: schema=%o, section=%o, columns=%o", schema, section, columns)

  const toDetailLabel = (rowKey, rowLabel) => {
    return translator.translate(
      `${schema.translationBaseKey}.labels.${rowKey}`,
      { user: true, fallback: rowLabel, format: F.toTitleCase }
    )
  }

  const toRowData = (row) => {
    const label = toDetailLabel(row.key, row.label)
    //const displayProps = getDisplayPropsFromLabel(label)
    return {
      cells: { 
        key:   row.key, 
        value: undefined, // actual value will be set later
        label
      }
    }
  }

  const rows = section.fields.map(toRowData)
  return { columns, rows }
}

export default GroupSection
