import {
  Table,
  Paper,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody  }               from "@mui/material"
import { RenderValue }       from "components/render"
import { basedOnKeys }       from "components/form/utils/form-utils"
import RenderTableRowCells   from "components/render/RenderTableRowCells"
import RenderContextProvider from "contexts/RenderContext"
import { useRenderContext }  from "hooks/render"
import { useTranslator }     from "hooks/translator"
import { useFormInfo }       from "hooks/form"


const RenderArrayTable = ({array}) => {
  const {parent} = useRenderContext()
  const props    = parent == "table" ? {} : {component: Paper}
  
  return (
    <TableContainer sx={{maxHeight: 'calc(100vh - 120px)', "& td": { verticalAlign: "top" }}} {...props}>
      <Table size="small" sx={{}}>
        <RenderContextProvider parent="table" >
          <RenderTableHeader array={array} />
          <RenderTableBody   array={array} />
        </RenderContextProvider>
      </Table>
    </TableContainer>
  )
}

const RenderTableHeader = ({array}) => {
  const { translator } = useTranslator()
  const formInfo       = useFormInfo()
  const processKey     = formInfo?.processDefinition?.key

  const labels = basedOnKeys(array[0])
  const toDisplayLabel = (label) => 
    translator.toLabel(processKey, label, label)

  return (
    <TableHead>
      <TableRow sx={{'& th > p': { fontWeight: "bold"}, '& th': { background: theme => theme.palette.background.default}}}>
        { labels.map((label, index) => 
          <TableCell sx={{width: "1%", whiteSpace: 'nowrap'}} key={'BasedOn' + index}>
            <RenderValue>
              {toDisplayLabel(label)}
            </RenderValue>
          </TableCell>)
        }
      </TableRow>
    </TableHead>
  )
}

const RenderTableBody = ({array}) => {
  return (
    <TableBody>
      { array.map( (row, index) =>
          <TableRow based-on={`based-on-row-${index}`}  key={'line' + index}>
            <RenderTableRowCells index={index} basedOn={row} />
          </TableRow>
        )
      }
    </TableBody>
  )
}


export default RenderArrayTable
