import {
  TableCell,
  TableHead,
  TableRow,
}                from '@mui/material';
import { columnsType } from 'utils/type-utils';

const TableHeader = ({columns}) => (
  <TableHead>
    <TableRow>
      {columns.map((column, index) => (
        <TableCell key={index} {...column.cellProps} >
          {column.headerName}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
)

TableHeader.propTypes = {
  columns: columnsType
};

export default TableHeader;

