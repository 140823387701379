import EmptyTableMessage from 'components/tables/EmptyTable';
import useStartProcess, { TranslatedProcessDefinition } from 'hooks/process';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid } from '@mui/material';
import Grow from '@mui/material/Grow';

import ProcessCard from './ProcessCard';

const ProcessDefinitionItems = ({processDefinitions}: {processDefinitions: TranslatedProcessDefinition[]}) => {
  const { t }        = useTranslation()
  const startProcess = useStartProcess()
  
  const size = processDefinitions?.length 
  if (size) {
    return (
      <React.Fragment>
        {processDefinitions.map((pd, index) => (
          <Grid
            item
            key={pd.id}
            {...ogStyle}
          >
            <Grow in={true} style={{ height: "100%", transformOrigin: '0 0 0' }} timeout={(1500 / size) * index}>
              <Box>
                <ProcessCard
                  process={pd}
                  onStartProcess={startProcess}
                />
              </Box>
            </Grow>
          </Grid>
        ))
        }
      </React.Fragment>
    )
  } else
    return <Box sx={{marginLeft: 3, marginTop: 3}}><EmptyTableMessage msg={t("tables.start")} /></Box>
}

const ogStyle = {
  xl: 2,
  lg: 3,
  md: 4,
  sm: 6,
  xs: 12,
}

export default ProcessDefinitionItems