import LayoutContextProvider, { BannerProps, LayoutConfigProps, SidebarProps } from 'contexts/LayoutContext';
import { useBannerState, useLayoutContext } from 'hooks/layout';
import { DivProps } from 'types/react';
import SidebarLayoutSidebar from 'components/layout/sidebar/Sidebar';
import SidebarLayoutContent from 'components/layout/sidebar/Content';
import { Box, } from '@mui/material';

interface SidebarLayoutProps extends Partial<LayoutConfigProps> {
  name: string,
  bannerProps?: Partial<BannerProps>,
  sidebarProps?: Partial<SidebarProps>,
  children: React.ReactNode
}

const SidebarLayout = ({children, name, bannerProps, sidebarProps, ...layoutProps}: SidebarLayoutProps): JSX.Element  => {
  return (
    <LayoutContextProvider 
      reset={true}
      name={name}
      props={layoutProps} 
      bannerProps={bannerProps} 
      sidebarProps={sidebarProps} 
    > 
      <LayoutBody>
        {children}
      </LayoutBody>
    </LayoutContextProvider>
  )
}

const LayoutBody = ({children}: DivProps) => {
  const {sidebarProps: {enabled}, props: {side, withBanner}, bannerProps: {shortcut}, name} = useLayoutContext()
  const [bannerState, setBannerState] = useBannerState(withBanner, shortcut)

  return (
    <Box 
      id={name}
      className='fullsize' 
      sx={{
        background: theme => theme.palette.background.default,
        display: "flex",
        flexDirection: "row",
        overflow: 'hidden',
      }}
    > 
      <LayoutContextProvider 
        bannerState={bannerState}
        setBannerState={setBannerState}
      >
        {side == "left" ? <SidebarLayoutSidebar /> : null}
        <SidebarLayoutContent>
          {children}
        </SidebarLayoutContent>
        {side == "right" ? <SidebarLayoutSidebar /> : null}
      </LayoutContextProvider>
    </Box>
  )
}

export default SidebarLayout
