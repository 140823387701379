import Logo              from "../../common/Logo"
import { Box, useTheme } from '@mui/material';

const BannerLogo = (props) => {
  const theme        = useTheme()
  const href         = theme.components.logo?.href
  const src          = theme.components.logo.src
  const bannerHeight = theme.components.banner.height
  const height       = (parseInt(bannerHeight, 10 ) -10 ) + "px";

  return (
      <Box height={height}>
        <a href={href ? href : window.location.origin}>
          <Logo {...props} height={height} src={src}/>
        </a>
      </Box>
  )
}

export default BannerLogo;
