import { gql } from '@apollo/client'

export const GET_DEPLOYMENTS = gql`
  query GetDeployments {
    deployments {
      id
      name
      deploymentTime
      source
      processDefinitionsCount
      processInstancesCount
    }
  }`

export const REMOVE_DEPLOYMENT = gql`
  mutation RemoveDeployment($id: ID!) {
    removeDeployment(id:$id, cascade:true)
  }
`

