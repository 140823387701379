import ChevronLeftIcon    from '@mui/icons-material/ChevronLeft';
import { useTranslation } from 'react-i18next';
import PageButton         from 'components/table/page/PageButton';

const PreviousPage = ({onClick, loading}) => {
  const { t }    = useTranslation()

  return <PageButton
    disabled={!onClick}
    onClick={onClick}
    loading={loading}
    tip={t('table.previous')}
    Icon={ChevronLeftIcon}
  />
}

export default PreviousPage