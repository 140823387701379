import React from 'react';
import { useTranslation } from 'react-i18next';
import { SetState } from 'types/react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';

const SettingsDialog = ({open, setOpen, children}: {open: boolean, setOpen: SetState<boolean>, children: React.ReactNode}) => {
  const handleClose = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(false)
  }

  const handleOnClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const { t }  = useTranslation()
	const theme  = useTheme()

  return (
	  <Dialog open={open} onClick={handleOnClick} /* PaperProps={{ref:ref}} */ >
			<DialogTitle sx={{fontSize: theme.typography.h3.fontSize}}>{t('settings')}</DialogTitle>
			<DialogContent sx={{pt: 3}}>
        {children}
        <DialogActions sx={{padding: 0, mt: 3}}>
					<Button id='close-button' onClick={handleClose}>{t('close')}</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	)
}

export default SettingsDialog;
