import ProcessGridItems from 'components/process/ProcessGridItems';
import ProcessGroup from 'components/process/ProcessGroup';
import {
    filterDefinitions, getGroupDepth, groupDefinitions, translateDefinitions
} from 'components/process/utils/process';
import { useLocale } from 'hooks/locale';
import { useProcessRenderContext } from 'hooks/render';
import { useTranslator } from 'hooks/translator';

import { Grid, Stack } from '@mui/material';

const ProcessGrid = () => {
  const { processInfo, groupBy, filter } = useProcessRenderContext()
  const { translator } = useTranslator()
  const { language }   = useLocale()
  const depth          = getGroupDepth(groupBy)
  const definitions    = translateDefinitions(translator, language, processInfo.processes)
  const defs           = filterDefinitions(definitions, filter)

  switch (groupBy) {
    case 'category':
    case 'subcategory':
      const grouped = groupDefinitions(translator, defs, depth)
      return (
        <Stack spacing={2}>
          { grouped.map(([category, defs], index) => (
            <ProcessGroup key={index} processDefinitions={defs} category={category} />))
          }
        </Stack>
      )

    default:
      return (
        <Grid container spacing={3} >
          <ProcessGridItems processDefinitions={defs} />
        </Grid>
      )
  }
}

export default ProcessGrid
