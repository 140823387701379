import { env } from 'utils/utils';

export const SERVER = env('SERVER');

export class Runtime {
  public static server(append: string) {
    return SERVER + append;
  }
  
  public static logo(filename: string) {
    return Runtime.server('logos/' + filename);
  }

  public static icon(filename: string) {
    return Runtime.server('icons/' + filename);
  }

  public static document(documentId: string) {
    return Runtime.server('document/' + documentId);
  }

  public static content(contentId: string) {
    return Runtime.server('content/' + contentId);
  }
}
