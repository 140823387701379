import { useEffect, useState }       from 'react';
import { useTranslation }            from "react-i18next";
import { DatePicker }                from '@mui/x-date-pickers';
import { fieldMinWidthStyle }        from 'components/form/utils/field-utils';
import { reference }                 from 'components/form/fields/utils/dt-formats';
import { isEmptyValue, isValidDate } from "utils/utils";
import { validateField }             from "components/form/utils/validate-utils";
import { useFieldInfo }              from 'hooks/field';
import { useFormInfo }               from 'hooks/form';
import { renderDateDay }             from 'components/form/fields/date/DayDate';
import InputError                    from "components/form/fields/InputError";
import Types from 'types/types';
import { useNotifier } from 'hooks/notification';
import { useAutoSubmitSignal } from 'hooks/autosubmit';
import { useConfig } from 'hooks/config';

function toRuntimeValue(value) {
  try {
    return isEmptyValue(value) ? null : reference.date.format(value)
  } catch (error) {
    return null
  }
}

function toMuiValue(value) {
  try {
    return isEmptyValue(value) ? null : reference.date.parse(value)
  } catch (error) {
    return null
  }
}

const InputDateFieldContent = (props) => {
  const { augProps, fieldProps, info } = useFieldInfo()
  const { t }                          = useTranslation()
  const formInfo                       = useFormInfo()
  const [error, setError]              = useState(null)
  const [value, setValue]              = useState(toMuiValue(fieldProps.value))
  const { setFocus, setViewOpen }      = props
  const notifier                       = useNotifier()
  const {signal}                       = useAutoSubmitSignal()
  const {props: {taskRendering}}       = useConfig()

  delete fieldProps.onChange

  function toHelperText(error) {
    if (info.inMultiple)
      return undefined

    if (fieldProps.error)
      return fieldProps.helperText
    else if (error)
      return t("yup.invalid.date")
    else
      return " "
  }

  function handleValidate(e, value) {
    const error = validateField("date", fieldProps.required, e, value)
    augProps.setError(error)
  }

  function handleChange(value, context) {
    const { validationError } = context

    augProps.setRuntimeError(undefined)
    setError(validationError)
    setValue(value)

    if (isValidDate(value)) {
      const runtimeValue = toRuntimeValue(value)
      augProps.setValue(runtimeValue)
      handleValidate(null, runtimeValue)
    } 
  }

  function handleBlur (e) {
    const val = error ? toRuntimeValue(value) : fieldProps.value 
    if (error || !Types.isValidDate(toMuiValue(val))) {
      augProps.setValue(null)
      setValue(null)
      notifier.error("invalid date in field " + fieldProps.label)
    }
    
    setFocus(false)
    fieldProps.onBlur(e)
    handleValidate(e, val)
    signal()
  }

  function handleFocus(e) {
    setFocus(true)
    fieldProps.onFocus(e)
  }

  const slotProps = {
    slotProps: {
      textField: (params) => ({
        ...fieldProps,
        onBlur: handleBlur,
        onFocus: handleFocus,
        ...params,
        id: fieldProps.id,
        error:  params.error || fieldProps.error,
        helperText :  toHelperText(params.error),
        style: taskRendering == 'standard' ? fieldMinWidthStyle(formInfo, info.field) : undefined,

        fullWidth: true
      }),
      actionBar: {actions: ['today','clear', 'cancel', 'accept']},
    }
  }

  useEffect(() => {
    if (fieldProps.value != toRuntimeValue(value)) {
      const getValue = () => {
        const newValue = toMuiValue(fieldProps.value)
        return isValidDate(newValue) ? newValue : value
      }
      setValue(getValue())
    }
  }, [fieldProps.value])

  // fix for clearing the field
  useEffect(() => {
    if (value == null)
      augProps.setValue(null)
  }, [value])

  return <DatePicker
    displayWeekNumber={true}
    className="grs-InputDateField"
    desktopModeMediaQuery="@media (not (pointer: coarse))"
    renderDay={renderDateDay}
    format="dd-MM-yyyy"    
    value={value}
    onChange={handleChange}
    {...augProps.inputProps}

    onOpen={() => setViewOpen(true)}
    onClose={() => setViewOpen(false)}
      
    {...slotProps}
  />
}

const InputDateField = (props) => (
  <InputError>
    <InputDateFieldContent />
  </InputError>
)

export default InputDateField
