import _, { isObject } from 'lodash'
import Types from 'types/types';

interface PruneProps {
  clone: boolean,
  undefined: boolean,
  null: boolean,
  nan: boolean,
  array: boolean, 
  object: boolean,
  string: boolean,
} 

export function prune(obj: object, props: Partial<PruneProps>) {

  function _prune(current: any) {
    _.forOwn(current, function (value, key) {

      if (isObject(value) && !value.hasOwnProperty('$$typeof'))
        _prune(value)

      switch (true) {
        case props.undefined && _.isUndefined(value):
        case props.null      && _.isNull(value):
        case props.nan       && _.isNaN(value):
        case props.array     && Array.isArray(value) && value.length == 0:
        case props.object    && Types.isObject(value) && _.isEmpty(value):
        case props.string    && value === "":
          delete current[key];
          break;

        default: 
      }
    });

    return current
  }
  
  return _prune(props.clone ? _.cloneDeep(obj) : obj);  
}
 