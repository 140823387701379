import { useState }                 from 'react'
import { useTranslation }           from 'react-i18next'
import {useApolloClient, useMutation} from '@apollo/client'
import { Delete as DeleteIcon }     from '@mui/icons-material'
import { IconButton }               from '@mui/material'
import { Tooltip }                  from '@mui/material'
import { reformQuery, removerById } from 'utils/utils'
import { bannerQueries }            from 'utils/banner-utils'
import { useNotifier }              from 'hooks/notification'
import { REMOVE_DEPLOYMENT }        from 'queries/deployments'
import ConfirmDialog                from 'components/tables/deployments/ConfirmDialog'
import { GET_DEPLOYMENTS }          from 'queries/deployments'
import { useResponsiveColumns }     from 'hooks/columns'
import LargeElevatedTable           from 'components/tables/LargeElevatedTable'
import CustomRefProvider            from 'contexts/RefContext'
import { toDisplayValue }           from 'components/render/utils/render'
import { createDataLoader }         from 'utils/table'

const DeploymentsTable = () => {
  const client = useApolloClient()
  const columns = useColumns()

  const requestInfo = {
    variables: {},
    query:     GET_DEPLOYMENTS,
    dataPath:  'data.deployments',
    countPath: 'data.count',
  }

  return (
    <CustomRefProvider>{({getCurrent}) => {
      const deleteLine = (id) => getCurrent().deleteLine(id)
      const [undeploy] = useUndeploy(deleteLine)
      const toTableRow = (row) => toTableRowData(row, {'undeploy': undeploy})
      const dataLoader = createDataLoader(client, requestInfo, toTableRow)

      return (
        <LargeElevatedTable
          description="deployments"
          columns={columns}
          dataLoader={dataLoader}
          disableFilter
          disablePaging
        />
      )}
    }
    </CustomRefProvider>
  )
}

const useColumns = () => {
  const { t } = useTranslation()

  const columns = {
    default: [
      {
        headerName: t('deployment_name'),
        field: "deploymentName",
        cellProps: {
          width: 350,
          align: "left"
        }
      }
    ],
    md: [
      {
        headerName: t('timestamp'),
        field: "timestamp",
        cellProps: {
          width: 200,
          align: "right"
        }
      }
    ],
    lg: [
      {
        headerName: t('number_of_defs'),
        field: "number_of_defs",
        cellProps: {
          width: 80,
          align: "right"
        }
      },
      {
        headerName: t('number_of_instances'),
        field: "number_of_instances",
        cellProps: {
          width: 80,
          align: "right"
        }
      },
    ],
    xl: [
      {
        headerName: t('source'),
        field: "source",
        cellProps: {
          width: 100,
          align: "right"
        }
      }
    ],
    end: [
      {
        headerName: '',
        field: "undeploy",
        cellProps: {
          align: 'right',
          sx: { padding: 0, px: '8px', width: '40px' }
        }
      }
    ]
  }

  return useResponsiveColumns(columns)
}

const UndeployButton = ({deployment, actions}) => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation()
  return (
    <>
      <Tooltip disableInteractive title={t('deployments.undeploy')}>
        <IconButton id={'deployment-' + deployment.id + '-start-undeploy-button'} onClick={() => setOpen(true)} >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ConfirmDialog deployment={deployment} open={open} setOpen={setOpen} onClick={() => actions.undeploy(deployment.id)}/>
    </>
  )
}

/* convert a tasks to row data structure */
function toTableRowData(deployment, actions) {
  return {
    id: deployment.id,
    cells: {
      "deploymentName":      deployment.name,
      "timestamp":           toDisplayValue(deployment.deploymentTime), 
      "source":              deployment.source,
      "number_of_defs":      deployment.processDefinitionsCount,
      "number_of_instances": deployment.processInstancesCount,
      "undeploy":            (<UndeployButton deployment={deployment} actions={actions}/>)
    }
  }
}

export const useUndeploy = (deleteLine) => {
  const notifier = useNotifier()
  
  const [doRemoveDeployment] = useMutation(REMOVE_DEPLOYMENT, {
    update: (cache, { data: { removeDeployment: id } }) => {
      reformQuery(cache, GET_DEPLOYMENTS, { deployments: removerById(id) })
    },
    refetchQueries: bannerQueries
  })

  const removeDeployment = (id) => {
    return doRemoveDeployment({ variables: { id } })
      .then(result => {
        deleteLine(id)
        notifier.success("Stopped deployment successfully")
      }, error => {
        notifier.error("Could not stop deployment: " + error)
        console.error("stopping deployment has failed: %o", error)
      })
      .catch(reason => {
        notifier.error("Could not stop deployment")
        console.error("the frontend has an issue with stopping the deployment: " + reason)
      })
  }
  
  return [removeDeployment]
}


export default DeploymentsTable
