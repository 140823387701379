import {useNavigate} from 'react-router'
import {useTranslation} from 'react-i18next'
import {useApolloClient} from '@apollo/client'
import {
  getProcessName,
  getTaskCreatedAt,
  getSubject
} from 'components/form/utils/form-utils'
import {useResponsiveColumns} from 'hooks/columns'
import LargeElevatedTable from 'components/tables/LargeElevatedTable'
import {GET_ASSIGNED_TASKS} from 'queries/task'
import {useTranslator} from 'hooks/translator'
import {getTaskTitle} from 'contexts/FormInfoContext'
import {openTaskForm} from 'hooks/process'
import {useNotifier} from 'hooks/notification'
import {createDataLoader} from 'utils/table'

const AssignedTasksTable = () => {
  const client = useApolloClient()
  const navigate = useNavigate()
  const columns = useColumns()
  const {translator} = useTranslator()
  const notifier = useNotifier()
  const options = {translator, navigate, notifier, register: true}

  const requestInfo = {
    variables: {},
    query:     GET_ASSIGNED_TASKS,
    dataPath:  'data.assignedTasks',
    countPath: 'data.assignedTasksCount',
  }

  const toTableRow = (row) => toTableRowData(options, row)
  const dataLoader = createDataLoader(client, requestInfo, toTableRow)

  return <LargeElevatedTable
    description="assigned tasks"
    filterType="local"
    columns={columns}
    dataLoader={dataLoader}
  />
}

function toTableRowData(options, task) {
  const { translator } = options
  const t = translator.translate.bind(translator)

  return {
    id: task.id,
    onClick: (e) => openTaskForm(options, e, task), 
    cells: {
      'processName' :    task.processDefinition ? getProcessName(translator, task) : t('unknown'),
      //'taskDescription': getTaskDescription(translator, task),
      'taskTitle':       getTaskTitle(translator, {isStartForm: false, task}), 
      'taskSubject':     getSubject(task),
      'assignee':        task.assignee && task.assignee.username,
      'createdAt':       getTaskCreatedAt(task)
    }
  }
}

const useColumns = () => {
  const { t } = useTranslation()

  const processName = {
    headerName: t('process_name'),
    field: "processName",
    cellProps: {
      width: 200,
      align: "left"
    }
  }

  const subject = {
    headerName: t('subject'),
    field: "taskSubject",
    cellProps: {
      width: 200,
      align: "left"
    }
  }  

  const title = {
    headerName: t('title'),
    field: "taskTitle",
    cellProps: {
      width: 200,
      align: "left"
    }
  }

  const description = {
    headerName: t('description'),
    field: "taskDescription",
    cellProps: {
      width: 350,
      align: "left"
    }
  }

  const createdAt = {
    headerName: t('createdAt'),
    field: "createdAt",
    cellProps: {
      width: 100,
      align: "left",
      sx: {whiteSpace: "nowrap"}
    }
  }

  const columns = {
    xxs: [subject],
    md: [
      processName,
      subject 
    ],
    lg: [
      processName,
      subject,
      title,
      //description
    ],
    xl: [
      processName,
      subject,
      title,
      //description,
      createdAt,
    ]
  }

  return useResponsiveColumns(columns, true)
}

export default AssignedTasksTable;
