import { IconButton, Tooltip } from '@mui/material'
import { Print as PrintIcon }  from '@mui/icons-material'
import { useReactToPrint }     from 'react-to-print'

const PrintMessageButton = ({messageRef}) => {
  const handlePrint = useReactToPrint({content: () => messageRef.current, pageStyle:"@page { size: auto; margin: 15mm; } }"})

  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handlePrint(e)
  }

  return (
    <Tooltip disableInteractive title={"Print message"}>
      <span>
        <IconButton tabIndex={-1} size="small" onClick={onClick}  >
          <PrintIcon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default PrintMessageButton