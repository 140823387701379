import { Box, } from '@mui/material';
import { useLayoutContext } from 'hooks/layout';
import { DivProps } from 'types/react';

const SidebarLayoutContent = ({children}: DivProps): JSX.Element => {
  const { 
    props: {contentBanner, transitionTime}, 
    bannerProps,
    bannerState
  } = useLayoutContext()

  return (
    <Box id='layout-content' 
      sx={{
        flexGrow: 1,
        overflow: "hidden", // this is required, otherwise it is 'visible'
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box id='content-banner' 
        sx={{
          display: "flex", 
          flexShrink: 0, 
          overflow: "hidden",
          width: "100%",  
          position: "relative",
          zIndex: 300,
          boxShadow: bannerState == "expanded" ?  bannerProps.shadow : undefined,
          height: bannerState == "expanded" ? bannerProps.height : "0px",
          transition: `box-shadow ${transitionTime} ease, height ${transitionTime} ease`,
        }}
      >
        <Box id='content-banner-container'
          sx={{
            height: bannerProps.height,
            width: "100%",
            position: "absolute",
            bottom:0,
            right: 0
          }}
        >
          {contentBanner}
        </Box>
      </Box>
      
      <Box id='content-body' 
        sx={{
          display: "flex",
          flexGrow: 1,
          overflow: "auto"
        }}>
          {children}
      </Box> 

    </Box>
  )
}


export default SidebarLayoutContent