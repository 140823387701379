import * as React from 'react';

import Box from '@mui/material/Box';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const TimedLoader = () => {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={progress} label={" " + process} circleSize="1.4rem" />;
}

export const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number, label: string, circleSize: string },
) => {
  const { circleSize: ignore, ...style} = props
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={props.circleSize} variant="determinate" {...style} />
      <Box
        sx={{
          top: "2px",
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{props.label}</Typography>
      </Box>
    </Box>
  );
}

export default TimedLoader
