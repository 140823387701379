import MuiIcon from 'components/common/MuiIcon';
import { stringToColor } from 'components/layout/dashboard/Avatar';
import { Runtime } from 'helpers/runtime';
import { useConfig } from 'hooks/config';
import _ from 'lodash';
import React from 'react';
import { MuiIconName, ThemeIcon, ThemeIconProps } from 'types/theming';
import { GearsIconType } from 'types/types';
import { decomposeRefPath } from 'utils/path';

import { Box, useTheme } from '@mui/material';

export const CreateGearsIcon = ({ gearsRef, size, icon }: { gearsRef?: string; size: string; label?: string; icon?: string; }) => {
  const gearsIcon = useGearsIconInfo({gearsRef, icon});
  return <GearsIcon icon={gearsIcon} size={size} />
}

type GearsIconProps = {
  icon: GearsIconType | string
  size: string
}

export const GearsIcon = ({icon: anyIcon, size}: GearsIconProps) => {
  const icon: GearsIconType                        = normalizeIcon(anyIcon)
  const { background, backgroundColor, ...iconSx } = icon.style || {}
  const pxSize                                     = Number.parseInt(size);
  const iconSize                                   = `${pxSize * 0.9}px`;

  return (
    <Box className="tile-icon" 
      sx={{
        background,
        backgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        objectFit: 'cover',
        borderRadius: "50%",
        height: pxSize, width: pxSize,
      }}
    >
      { icon?.filename 
        ? <GearsIconImage icon={icon} src={Runtime.icon(icon.filename)} />
        : <MuiIcon sx={iconSx} name={icon.name!} size={iconSize} iconLocation={`gears icon ${icon.name}`} />
      }
    </Box>
  )
}

const normalizeIcon = (icon: GearsIconType | string | null): GearsIconType => {
  if (icon == null) return {__kind: "icon", name: "QuestionMark"};
  if (typeof icon == "string") return {__kind: "icon", name: icon };
  return icon
}

const GearsIconImage = ({icon, src}: {icon: GearsIconType, src: string}) => {
  return (
    <img
      alt="icon"
      src={src}
      style={{
        objectFit: 'contain', // 'scale-down' 
        maxHeight: "calc(100% - 5px)",
        maxWidth: "calc(100% - 5px)"
      }}
    />
  )
}

type GearsIconInfoProps = {
  gearsRef?: string
  icon?: MuiIconName
  override?: boolean
  active?: boolean
}

export const useGearsIconInfo = ({gearsRef, icon, override, active}: GearsIconInfoProps): GearsIconType => {
  const themeIcon    = useThemeIcon(gearsRef, active)
  const theme        = useTheme()

  const defaultColor      = theme.palette.grey[500]
  const config            = useConfig()
  const generateIconColor = config?.props?.generateIconColor 
  
  const iconColor = icon == "Folder" ? defaultColor : ( generateIconColor ? stringToColor(""+gearsRef+icon) : defaultColor )
  const style: React.CSSProperties = {
    color: iconColor,
    ...themeIcon?.style
  } as React.CSSProperties 

  if (override && icon) {
    return { __kind: "icon", name: icon, style }
  } else if (themeIcon?.filename) {
    return { __kind: "icon", filename: themeIcon?.filename, style }
  } else {
    return { __kind: "icon", name: themeIcon?.name || icon || iconFallback(gearsRef) , style }
  }
}

export const useThemeIconProps = (gearsRef?: string): ThemeIconProps | undefined => {
  if (!gearsRef)
    return undefined

  const config                    = useConfig()
  const paths                     = gearsRef.split(':')
  const icons                     = config?.props?.theme?.icons
  const themeIcon: ThemeIconProps = _.get(icons || {}, paths.join('.')) || _.get(icons || {}, paths)

  return themeIcon
}

export const useThemeIcon = (gearsRef?: string, active?: boolean): ThemeIcon | undefined => {
  const icon = useThemeIconProps(gearsRef)

  if (active && icon?.activeIcon)
    return icon.activeIcon

  return icon?.icon
}

function iconFallback(ref?: string): MuiIconName {
  if (!ref)
      return 'BugReport'

  const { domain } = decomposeRefPath(ref)
  switch (domain) {
    case 'process': return "PlayCircleOutlined" 
    case 'list':
    case 'lists': return "ListAltOutlined"
    default: 
      return 'BugReport'
  }
}
