import { useApolloClient }   from '@apollo/client'
import { useParams }         from 'react-router'
import { useNotifier }       from 'hooks/notification'
import { useTranslator }     from 'hooks/translator'
import ListSection           from 'components/view/ListSection'
import GroupSection          from 'components/view/GroupSection'
import Report                from 'helpers/report'
import { GET_DETAIL_DATA }   from 'queries/detail'

const SectionView = ({schema, section}) => {
  const notifier       = useNotifier()
  const { translator } = useTranslator()
  const client         = useApolloClient()
  const params         = useParams()

  const detailKey = params.detail
  const id        = params.id

  const groupDataLoader = (loadingCallback, dataCallback) => {
    const variables  = { key: detailKey, id: id, start: 0, count: 5000 }
    const newRequest = client.query({ query: GET_DETAIL_DATA, variables })
    
    loadingCallback(true)
    newRequest
      .then(
        result => {
          console.log("handle group result: data=%o", result)
          const path = "data.detail.instance.data"
          const data = _.get(result, path)
          dataCallback(data)
        },
        error => {
          console.error("failed to get group data: %o", error.message)
          const report = Report.from(error, translator, { category: Report.backend })
          report.addToNotifier(notifier)
        }
      )
      .catch(reason => {
        notifier.error("Could not retrieve more options")
        console.error("The frontend has an issue with retrieving options: " + reason)
      })
      .finally(() => {
        loadingCallback(false)
      })
  }

  switch (section.type) {
    case 'group': 
      return (
        <GroupSection schema={schema} section={section} loader={groupDataLoader}/>
      )
    
    case 'list' : 
      return (
        <ListSection schema={schema} section={section} pageSize={10}/>
      )

    default: 
      notifier.error("An unknown detail type is provided: %o", section.type) 
      return null
  }
}

export default SectionView
