import React from 'react'
import {OperationVariables, QueryResult} from '@apollo/client'
import CenteredLoader from 'components/common/CenteredLoader'
import { useNotifier } from 'hooks/notification'
import Report from 'helpers/report'
import { handleError } from 'utils/utils'

type HandleResultProps<T1, T2 extends OperationVariables> = {
  result: QueryResult<T1, T2>
  loading? : boolean 
  children: React.ReactNode
  errorCallback?: (error: any) => React.ReactNode
}

const HandleResult = <T1,T2 extends OperationVariables>({result, children, errorCallback}: HandleResultProps<T1,T2>): JSX.Element => {
  const notifier = useNotifier()

  // handle loading
  if (result.loading)
    return <CenteredLoader />

  // handle errors
  if (!!result.error){
    console.error("GraphQL error: %o", result.error)
    const report = Report.error(Report.backend, Report.code.backend.Error)
    report.addToNotifier(notifier)
    return handleError(result.error, notifier, errorCallback)
  }

  // handle data
  console.log('handleResult: data=%o', result.data)
  if (!result.data) 
    return <React.Fragment/>

  return <React.Fragment>{children}</React.Fragment>
}

export default HandleResult