import { Box } from '@mui/material';

const PageContainer = ({center, children}) => {

  return (
    <Box
      id='page-container'
      className='fullsizemax'
      sx={{
        display: "flex",
        backgroundColor: 'background.default',
        p: 3,
        ...(center ? ({
          justifyContent: "center", 
          //alignItems: "center"
        }) : ({}))
      }}
    >
      {children}
    </Box>
  )
}

export default PageContainer;
