import { useTiles } from 'hooks/render';

import { Box } from '@mui/material';

import Tile from './Tile';

const ProcessTileView = () => {
  const tiles = useTiles() 

  return (
    <Box className='tiles' sx={{ padding: "20px", height:"fit-content", display: "flex", maxWidth: "100%", flexWrap: "wrap", overflow: "auto", gap: "10px" }}>
      { tiles.map((tile, index) => 
        <Tile key={index} {...tile} tileProps={{fontSize: "14px"}}/>) 
      }
    </Box>
  )
}

export default ProcessTileView