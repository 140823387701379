import Elevate from 'components/common/Elevate';
import { Function0 } from 'lodash';
import React, { createContext, SetStateAction, useContext, useRef, useState } from 'react';
import { SetState } from 'types/react';

import { Box, BoxProps, Divider, Typography } from '@mui/material';

import {
    PageConfig, PaneLayoutProps, PaneProps, PaneSplitType, WindowManagerConfig
} from '../types';
import { WindowHelper } from './WindowHelper';

export class PageHelper {
  public static clone(configs: PageConfig[]): PageConfig[] {
    return configs.map(config => ({...config}))
  }

  public static shiftRight(configs: PageConfig[]): PageConfig[] {
    return WindowHelper.updateConfigActions(configs, P.shiftConfigRight(configs))
  }

  public static shiftConfigRight(configs: PageConfig[]): PageConfig[] {
    const { enabled, visible } = WindowHelper.getConfigStatus(configs)
    const rightMostEnabled     = P.rightMostEnabled(configs)
    const c                    = P.clone(configs)
    const isShiftable          = rightMostEnabled + 1 < configs.length

    function shiftViewport(c: PageConfig[]): void {
      if (rightMostEnabled + 1 < configs.length) {
        const leftMostEnabled = P.leftMostEnabled(configs)
        c[rightMostEnabled + 1].enabled = true
        c[leftMostEnabled].enabled      = false
      }
    }

    if (!(rightMostEnabled >= 0))
      return c
    else if (visible < enabled) {

      const rightMostVisible = P.rightMostVisible(configs)

      // change viewport if possible neccessary
      if (rightMostVisible == rightMostEnabled)  {
        if (!isShiftable)
          return c
      
        shiftViewport(c)
      }

      // change visible pages
      if (rightMostVisible < rightMostEnabled || rightMostVisible == rightMostEnabled) {
        c[rightMostVisible + 1].state   = 'visible'
        c[rightMostVisible].state       = 'invisible'
        return c
      }
    } else if (enabled < configs.length && rightMostEnabled + 1 < configs.length) {
      shiftViewport(c)
      c[rightMostEnabled + 1].state = 'visible'
      return c
    }

    return c
  }

  public static shiftLeft(configs: PageConfig[]): PageConfig[] {
    return WindowHelper.updateConfigActions(configs, P.shiftConfigLeft(configs))
  }

  public static shiftConfigLeft(configs: PageConfig[]): PageConfig[] {
    const { enabled, visible } = WindowHelper.getConfigStatus(configs)
    const leftMostEnabled      = P.leftMostEnabled(configs)
    const c                    = P.clone(configs)
    const isShiftable          = leftMostEnabled > 0

    function shiftViewport(c: PageConfig[]): void {
      if (isShiftable) {
        const rightMostEnabled = P.rightMostEnabled(configs)
        c[leftMostEnabled - 1].enabled = true
        c[rightMostEnabled]. enabled   = false
      }
    }

    if (!(leftMostEnabled >= 0))
      return c
    else if (visible < enabled) {
      const leftMostVisible = P.leftMostVisible(configs)
      if (leftMostVisible == leftMostEnabled)  {
        // change viewport if possible
        if (!isShiftable)
          return c
      
        shiftViewport(c)
      }

      if (leftMostVisible > leftMostEnabled || leftMostVisible == leftMostEnabled) {
        c[leftMostVisible - 1].state   = 'visible'
        c[leftMostVisible].state       = 'invisible'
        return c
      }
    } else if (enabled < configs.length && leftMostEnabled > 0) {
      shiftViewport(c)
      c[leftMostEnabled - 1].state   = 'visible'
      return c
    }

    return c
  }

  public static enableRightShift(configs: PageConfig[]): boolean {
    const { enabled, visible } = WindowHelper.getConfigStatus(configs)
    const rightMostEnabled = P.rightMostEnabled(configs)

    if (!(rightMostEnabled >= 0))
      return false
    else if (visible < enabled)
      return configs[rightMostEnabled].state == 'invisible' || rightMostEnabled + 1 < configs.length
    else if (enabled < configs.length)
      return rightMostEnabled + 1 < configs.length
    else
      return false
  }

  public static enableLeftShift(configs: PageConfig[]): boolean {
    const { enabled, visible } = WindowHelper.getConfigStatus(configs)
    const leftMostEnabled = P.leftMostEnabled(configs)
  
    if (!(leftMostEnabled >= 0))
      return false
    else if (visible < enabled)
      return configs[leftMostEnabled].state == 'invisible' || leftMostEnabled > 0
    else if (enabled < configs.length)
      return leftMostEnabled > 0
    else
      return false
  }

  public static expand(configs: PageConfig[], index: number) : PageConfig[] {
    const newConfigs = configs.map((config, i) => ({
      ...config, 
      state: config.enabled ? (i == index) ? 'visible' : 'invisible' : config.state
    }))
    
    return WindowHelper.updateConfigActions(configs, newConfigs)
  }

  public static collapse(configs: PageConfig[]): PageConfig[] {
    const newConfigs = configs.map((config, i) => ({
      ...config, 
      state: config.enabled ? 'visible' : config.state
    }))
    return WindowHelper.updateConfigActions(configs, newConfigs)
  }

  public static resize(configs: PageConfig[], index: number): PageConfig[] {
      const {enabled,visible} = WindowHelper.getConfigStatus(configs)
      if (visible == enabled){
        return P.expand(configs, index)
      }
      else if (visible < enabled) 
        return P.collapse(configs)
      else return configs
  }

  public static rightMostEnabled(configs: PageConfig[]): number { 
    return configs.findLastIndex(config => config.enabled)
  }
 
  public static rightMostVisible(configs: PageConfig[]): number { 
    return configs.findLastIndex(config => config.enabled && config.state == 'visible')
  }

  public static leftMostEnabled(configs: PageConfig[]): number { 
    return configs.findIndex(config => config.enabled)
  }

  public static leftMostVisible(configs: PageConfig[]): number { 
    return configs.findIndex(config => config.enabled && config.state == 'visible')
  }
};

const P: typeof PageHelper = PageHelper
