import Settings from 'helpers/settings';
import React, { useEffect, useState } from 'react';
import { SetState } from 'types/react';
import Types, { Primitive } from 'types/types';

export type LocalState<T> = {
  value: T,
  setValue: SetState<T>
}

export const useLocalTypeState = <T extends Primitive | object>(location: string, defaultValue:T): LocalState<T> => {
  const [value, setValue] = useState<T>(() => 
    Settings.read(
      location, 
      defaultValue
    )
  );

  useEffect(() => {
    Settings.write(location, value)
  }, [value])

  useEffect(() => {
    setValue(
      Settings.read(
        location, 
        defaultValue
      )
    )
  }, [location])

  return {value, setValue}
}
    
export const isCallback = (
  maybeFunction: any | ((...args: any[]) => void),
): maybeFunction is (...args: any[]) => void =>
  typeof maybeFunction === 'function'
  

export function useLocalState<S extends Primitive>(location: string, defaultValue: S): [S, SetState<S>] {
  const [value, setValue] = useState(() => Settings.read(location, defaultValue));

  useEffect(() => {
    Settings.write(location, value)
  }, [value])

  useEffect(() => {
    setValue(Settings.read(location, defaultValue))
  }, [location])

  return [value, setValue]
}

export const useSessionState = (location: string, defaultValue: any): any[] => {
  const [value, setValue] = useState(() => Settings.session.read(location, defaultValue));

  useEffect(() => {
    Settings.session.write(location, value)
  }, [value])

  return [value, setValue]
}

