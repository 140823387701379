import React, { createContext } from 'react';
import { SetState } from 'types/react';

import { useWindowContext } from '../hooks/useWindowContext';
import { PageConfig, PaneSplitType, WindowState } from '../types';

interface WindowContextProps extends WindowContextBaseProps {
  setConfigs: SetState<PageConfig[]>
  configs: PageConfig[]
}

type WindowContextBaseProps = {
  orientation: PaneSplitType
  setOrientation: SetState<PaneSplitType>
  windowState: WindowState
  setWindowState: SetState<WindowState>
  width: string
  setWidth: SetState<string>
}

interface WindowProviderProps extends WindowContextBaseProps {
  children: React.ReactNode;
}

export const WindowContext = createContext<WindowContextProps>({} as WindowContextProps);

export const WindowProvider = ({ children, ...props }: WindowProviderProps): JSX.Element => {
  const actions = useWindowContext();

  const { windowState, setWindowState } = props
  const setConfigs: SetState<PageConfig[]> = (configs) => {
    setWindowState(state => ({...state, configs: typeof configs == 'function' ? configs(state.configs) : configs }))
  }

  return (
    <React.Fragment>
      <WindowContext.Provider value={{ ...actions, ...props, setConfigs, configs: windowState.configs}}>
        {children}
      </WindowContext.Provider>
    </React.Fragment>
  );
};


