import Types from 'types/types';

export type RuntimeInputError = {
  type: string
  message: string
}

export type BasicInputError = {
  type: string
  name: string
  message: string
}

export class ErrorHelper { 
  static isRuntimeError (error: any) { 
    return Types.isObject(error) && error.hasOwnProperty('type') && error.hasOwnProperty('message')
  }

  static isBasicError(error: any) {
    return Types.isObject(error) && error.hasOwnProperty('type') && error.hasOwnProperty('name')
  }
}

export type InputError = Error | string | RuntimeInputError | (() => string);
