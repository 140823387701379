
import { Box, Typography } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

// a seperate render component for DatePicker, in order to add week numbers
const DateDay = ({day, dayComponentProps}: {day: Date, dayComponentProps: any }) => {
  return (
    <Box>
      { day.getDay() == 0 ? ( 
      <Box sx={{ position: 'absolute', marginLeft:"-255px", marginTop: "8px" }}> 
        <Typography color="primary" fontWeight={500} fontSize={12} >
          {ISO8601_week_no(day)}
        </Typography>
      </Box>) : null}
      <Box>
        <PickersDay {...dayComponentProps} />
      </Box>
    </Box>
  );
}

function ISO8601_week_no(dt: Date) {
  var tdt = new Date(dt.valueOf());
  var dayn = (dt.getDay() + 6) % 7;
  tdt.setDate(tdt.getDate() - dayn + 3);
  var firstThursday = tdt.valueOf();
  tdt.setMonth(0, 1);
  if (tdt.getDay() !== 4) {
    tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
  }
  return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
}

export const renderDateDay = (day: any, _value: any, DayComponentProps: any): JSX.Element =>
  <DateDay key={Number(day)} day={day} dayComponentProps={DayComponentProps} />

export default DateDay