import { useBreakpoint } from "hooks/breakpoint";

export const useResponsiveColumns = (config, disableAppend) => {
  const breakpoint = useBreakpoint()

  const columns = []
  const isEmpty    = (breakpoint) => !Boolean(config && Array.isArray(config[breakpoint]) && config[breakpoint])
  const addColumns = (breakpoint) => {
    if (!isEmpty(breakpoint))
      config[breakpoint].reverse().forEach(item => columns.push(item))
  }

  const getNonEmptyBreakpoint = (breakpoint) => {
    const sizes = [ 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'] 

    if (isEmpty(breakpoint)) {
      for (let i = sizes.findIndex(v => v === breakpoint)-1; i >= 0; i--) {
        if (!isEmpty(sizes[i]))
          return sizes[i] 
      }
    }

    return breakpoint
  }

  const br = getNonEmptyBreakpoint(breakpoint)
  switch (br) {
    case 'xxl': addColumns('xxl')     ; if(disableAppend) break;
    case 'xl':  addColumns('xl')      ; if(disableAppend) break;
    case 'lg':  addColumns('lg')      ; if(disableAppend) break;
    case 'md':  addColumns('md')      ; if(disableAppend) break;
    case 'sm':  addColumns('sm')      ; if(disableAppend) break;
    case 'xs':  addColumns('xs')      ; if(disableAppend) break;
    case 'xxs': addColumns('xxs')     ; if(disableAppend) break;
    default:    break;
  }
  addColumns('default')

  columns.reverse()

  const begin = config?.begin ? config.begin : []
  const end   = config?.end ? config.end : []

  return [...begin, ...columns, ...end]
}
