import { F } from 'helpers/formatter';
import { T } from 'helpers/translator';
import { useTranslator } from 'hooks/translator';
import React from 'react';
import { useNavigate } from 'react-router';

import { Chip, Link } from '@mui/material';
import Typography from '@mui/material/Typography';

import { Crumb } from './Crumb';

const Breadcrumb = ({id, crumb, clickable, chipped}: {chipped?: boolean, id: number, crumb: Crumb, clickable: boolean}): JSX.Element => {
	const navigate       = useNavigate()
	const { translator } = useTranslator()
	const goToLink       = (event: React.SyntheticEvent) => { if (crumb.link) navigate(crumb.link) }
	const label          = getLabel(translator, crumb)
	const key            = '' + id

  if (crumb.clickable !== false && (clickable || crumb.clickable) && (crumb.link || crumb.onClick)) {
    const onClick = crumb.link ? goToLink : crumb.onClick
    if (chipped)
      return <Chip size="small" label={label} onClick={onClick}/>
    else
      return <Link key={key} sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onKeyPress={onClick} onClick={onClick}>{label}</Link>
  } else if (crumb.link) {
	  return <Typography key={key} color="inherit">{label}</Typography>
  } else {
	  return <Typography key={key} color="text.primary">{label}</Typography>
	}
}

function getLabel(translator: T, crumb: Crumb) {
  const fallback = crumb.label || (crumb.key && F.toSentenceCase(crumb.key)) || undefined
  switch (crumb.type) {
    case "list":
      return translator.toListShortTitle(crumb.key, fallback)

    case "detail":
      return translator.toDetailShortTitle(crumb.key, fallback)

    default:
      if (crumb.key)
        return translator.translate(`common.labels.${crumb.key}`, { fallback, user: true })
      else
        return crumb.label
  }
}


export default Breadcrumb;
