import Fallback from 'components/common/Fallback';
import InputBooleanButton from 'components/form/fields/InputBooleanButton';
import InputCheckField from 'components/form/fields/InputCheckField';
import InputDateField from 'components/form/fields/InputDateField';
import InputDateTimeField from 'components/form/fields/InputDateTimeField';
import InputDecimalField from 'components/form/fields/InputDecimalField';
import InputFileField from 'components/form/fields/InputFileField';
import InputIntegerField from 'components/form/fields/InputIntegerField';
import InputMultipleField from 'components/form/fields/InputMultipleField';
import InputMultipleSelectField from 'components/form/fields/InputMultipleSelectField';
import InputPeriodDurationField from 'components/form/fields/InputPeriodField';
import InputRadioField from 'components/form/fields/InputRadioField';
import InputSingleSelectField from 'components/form/fields/InputSingleSelectField';
import InputSingleSelectSubmit from 'components/form/fields/InputSingleSelectSubmit';
import InputTextField from 'components/form/fields/InputTextField';
import InputTimeField from 'components/form/fields/InputTimeField';
import MultipleImmutableField from 'components/form/fields/MultipleImmutableField';
import { getInputLabel } from 'components/form/utils/form-utils';
import FieldInfoProvider, { Indices, InputProps } from 'contexts/FieldInfoContext';
import { TraitHelper } from 'helpers/traits';
import { useFieldInfo } from 'hooks/field';
import { useFormInfo } from 'hooks/form';
import { useRenderContext } from 'hooks/render';
import { useGearsTranslation } from 'hooks/translation';
import { Field } from 'types/graphql';

/* This component functions as the gateway for all input types */
type InputFieldProps = {
  indices: Indices
  field: Field
} 

const InputField = (props: InputFieldProps) => {
  return (
    <Fallback name={'field'} >
      <StyledInputField {...props} />
    </Fallback>
  )
}

const StyledInputField = (props: InputFieldProps) => {
  const {field}                                    = props
  const { translator }                             = useGearsTranslation()
  const { processDefinition: { key : processKey }} = useFormInfo()
  const {multipleType}                             = useRenderContext()
  
  const inMultiple = field.path.includes('.')
  const inputProps: InputProps = {
    variant: "outlined",
    size:    inMultiple ? "small" : "medium",
    margin:  inMultiple ? "none" : "normal",
    label:   inMultiple 
      ? multipleType != "mutable-table" ? getInputLabel(translator, field, processKey) : undefined 
      : getInputLabel(translator, field, processKey)
  }

  return (
    <FieldInfoProvider indices={props.indices} field={field} inputProps={inputProps} >
      <InputFieldSwitcher />
    </FieldInfoProvider>
  )
}

const InputFieldSwitcher = () => {
  const { info } = useFieldInfo()
  const { multipleType } = useRenderContext()

  switch (info.type) {
    case "MULTIPLE SELECT":
      return <InputMultipleSelectField />

    case "CHECKBOX SELECT":
      return <InputCheckField />

    case "SEGMENTED BOOLEAN BUTTON":
      return <InputBooleanButton />

    case "SINGLE SELECT":
      return <InputSingleSelectField />

    case "RADIO SELECT":
    case "RADIO BOOLEAN SELECT":
      return <InputRadioField />

    case 'MULTIPLE':
      switch (multipleType) {
        case "mutable-table": 
          return <InputMultipleField />
        case "mutable-flex": 
          return <InputMultipleField />
        case "immutable": 
        default:
          return <MultipleImmutableField />
      }

    case 'DATETIME':
      return <InputDateTimeField />

    case 'TIME':
      return <InputTimeField />

    case 'DATE':
      return <InputDateField />

    case 'INTEGER':
      return <InputIntegerField />

    case 'DECIMAL':
      return <InputDecimalField />

    case 'MULTIPLE FILE':
      return <InputFileField multiple={true}/>

    case 'FILE':
      return <InputFileField multiple={false}/>

    case "DURATION":
      return <InputPeriodDurationField type="duration"/>

    case "PERIOD":
      return <InputPeriodDurationField type="period" />

    case "SINGLE SELECT SUBMIT": 
      return <InputSingleSelectSubmit /> 

    case TraitHelper.hasTrait(info.field, "multiline") && 'TEXT':
      return <InputTextField multiline/>

    default:
      return <InputTextField/>
  }
}

export default InputField
