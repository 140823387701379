import { GlobalStyles as MuiGlobalStyles } from '@mui/material';

const globalStyles = (theme) => {

  return {

    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      'WebkitFontSmoothing': 'antialiased',
      'MozOsxFontSmoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
      width: '100%'
    },
    '*::-webkit-scrollbar': {
      background: "transparent",
      width: "16px",
      height: "16px",
    },
    '*::-webkit-scrollbar-track': {
      margin: "1px",
      background: 'transparent',
    },
    '*::-webkit-scrollbar-thumb': {
      border: 'white solid',
      borderWidth: "4px",
      backgroundClip: 'padding-box',
      borderRadius: '999px',
      "&:hover": {
        borderWidth: "3px"
      },
      'WebkitBoxShadow': 'inset 0 0 6px     rgba(0,0,0,0.5)',
      backgroundColor: theme.palette.primary.main,
      ...(theme.component?.scrollbar ? theme.component.scrollbar : {}),
    },
    ".fullsize": {
      width: '100%',
      height: "100%"
    },
    ".fullsizemax": {
      width: '100%',
      height: "100%",
      maxWidth: '100%',
      maxHeight: "100%",
      overflow: "auto"
    },
    ".maxsize": {
      maxWidth: '100%',
      maxHeight: "100%",
      overflow: "auto"
    },
    a: {
      textDecoration: 'none'
    },
    'li.notSelectedRow': {
      '&:hover': {
        background: theme.palette.contrast,
      },
    },
    'li.selectedRow': {
      background: theme.palette.primary.main,
      color: '#ffffff',
    },
    'li.not-selected-row': {
      '&:hover': {
        background: theme.palette.contrast,
      },
    },
    'li.selected-row': {
      background: theme.palette.primary.main,
      color: '#ffffff',
    },
    ".MuiTableCell-head": {
      backgroundColor: `${theme.palette.contrast} !important`,
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    '*:focus': {
      outline: 'none'
    },
    'input::placeholder': {
      opacity: '0 !important'
    },
    'input:hover::placeholder': {
      opacity: '0.5 !important'
    },
    'input:focus::placeholder': {
      opacity: '0.5 !important'
    },
    'table > tbody > tr:last-of-type > td': {
      border: 0
    },
    "task-overload": {
      background: "#990000"
    },

    ".gantt-task-red": {
      background: "#d62929 !important "
    },
    ".gantt-week-full": {
      backgroundColor: "rgba(255, 140, 0,0.3) !important"
    },
    ".gantt-week-high": {
      backgroundColor: "rgba(210,31,60,0.3) !important" // 126, 25, 27
    },
    ".gantt-week-low": {
      backgroundColor: "rgba(240, 230, 140,0.3) !important"
    }


  }
}

const GlobalStyles = () => {
  return <MuiGlobalStyles styles={globalStyles}/>
};

export default GlobalStyles;
