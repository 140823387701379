import { useEffect } from 'react';
import { Card, FormControl, FormHelperText, FormLabel, Typography } from '@mui/material';

import { MultipleTable, MultipleTableBasedOn } from 'components/form/fields/multiple';
import MultipleFlex from 'components/form/fields/multiple/MultipleFlex';
import { nullOrEmpty } from 'components/form/fields/utils/values';
import { getInputLabel } from 'components/form/utils/form-utils';
import { setStepIconTabIndices } from 'components/form/utils/task-utils';
import RenderContextProvider from 'contexts/RenderContext';
import { useFieldInfo } from 'hooks/field';
import { useFormInfo } from 'hooks/form';
import { useRenderContext } from 'hooks/render';
import { useTranslator } from 'hooks/translator';
import { Field } from 'types/graphql';

export type MultipleFeatures = {
  select:  boolean,
  reorder: boolean,
  add:     boolean,
  remove:  boolean,
}

export type MutableMultipleProps = {
  features: MultipleFeatures
}

const InputMultipleField = (): JSX.Element => {
  const { t, translator }                        = useTranslator()
  const { fieldProps, info }                     = useFieldInfo()
  const { processDefinition: { key: processKey}} = useFormInfo()
  const { multipleType }                         = useRenderContext()
  const { mode }                                 = info.field

  useEffect(() => {
    setStepIconTabIndices()
  },[])

  //console.log("InputMultipleField: fieldProps=%o, info=%o", fieldProps, info)
  if (mode === "closed" && nullOrEmpty(fieldProps.value)) {
    return (
      <FormControl fullWidth required={true} >
        <FormLabel sx={{marginBottom: "16px"}} component="legend">{getInputLabel(translator, info.field, processKey)}</FormLabel>
        <Typography>{t('form.multiple.no-lines')}</Typography>
      </FormControl>
    )
  }

  const helperText = fieldProps.error ? (fieldProps.helperText?.trim() ? fieldProps.helperText : t('yup.invalid.multiple')) : fieldProps.helperText
  return (
    <RenderContextProvider parent="multiple" >
      <FormControl fullWidth required={fieldProps.required} error={fieldProps.error} sx={{display: "flex", maxHeight: "100%", overflow: "auto"}}>
        {!info.inMultiple && multipleType != "mutable-flex"
          ? <FormLabel sx={{ marginBottom: "16px" }} component="legend">{getInputLabel(translator, info.field, processKey)}</FormLabel>
          : null
        }
        { info.inMultiple || multipleType != "mutable-flex" ? <MultipleTableBasedOn /> : null }
          <MutableMultipleInput/>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </RenderContextProvider>
  )
}

const createFeatures = (field: Field): MultipleFeatures => {
  if (field.mode !== 'open') return {
    select:  false,
    reorder: false,
    add:     false,
    remove:  false,
  }

  const traitNames = !Array.isArray(field.traits) ? [] : field.traits.map(trait => trait.name)
  return {
    select:  !traitNames.includes("no_select"),
    add:     !traitNames.includes("no_add"),
    remove:  !traitNames.includes("no_remove"),
    reorder: !traitNames.includes("no_reorder"),
  }
}

const MutableMultipleInput = () => {
  const { multipleType }  = useRenderContext()
  const { info } = useFieldInfo()
  const features = createFeatures(info.field)

  switch (multipleType) {
    case 'mutable-flex': 
      return <MultipleFlex features={features}/>

    case 'mutable-table':
    default:
      return (
        <Card sx={{margin: Object.keys(info.indices).length > 1 ? undefined : "5px"}}>
          <MultipleTable features={features}/>
        </Card>
      )
  }
}

export default InputMultipleField
