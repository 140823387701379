import {
  Box,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  useTheme,
  Button,
} from '@mui/material';
import { useMutation }      from '@apollo/client';
import { useNotifier }      from 'hooks/notification';
import { DELETE_MESSAGE   } from 'queries/messages';
import { useTranslation }   from 'react-i18next';
import { useNavigate }      from 'react-router';
import Report               from 'helpers/report';
import { useState }         from 'react';
import Loader               from 'components/common/Loader'
import {
  Delete as DeleteIcon }    from '@mui/icons-material';
import { useTranslator } from 'hooks/translator';

const DeleteMessageButton = ({id}) => {
  const notifier              = useNotifier()
  const [loading, setLoading] = useState(false)
  const navigate              = useNavigate()
  const { translator }        = useTranslator()
  const [doDelete]            = useMutation(DELETE_MESSAGE)
  const [open, setOpen]       = useState(false)

  const onClick = (e) => {
    setLoading(true)
    e.preventDefault()
    e.stopPropagation()
    doDelete({ variables: { id }})
      .then(
        result => navigate(-1),
        error  => {
          const report = Report.from(error, translator, { category: Report.backend})
          report.addToNotifier(notifier)
          console.error("Delete error: %s", report.verboseMessage)
        }
      )
      .finally(() => { setLoading(false) })
  }


  return (
    <>
    <DeleteMessageDialog open={open} setOpen={setOpen} id={id} onClick={onClick}/>
    <Tooltip disableInteractive title={"Delete message"}>
      <span>
        <IconButton tabIndex={-1} size="small" onClick={() => setOpen(true)}  >
          <DeleteIcon fontSize="small" sx={{visibility: loading ? 'hidden': 'visible'}}/>
          { loading ? <Box sx={{marginTop: "-2px", position: "absolute"}}> <Loader size='micro'/></Box>: null }

        </IconButton>
      </span>
    </Tooltip>
    </>
  )
}

const DeleteMessageDialog = ({id, onClick, open, setOpen}) => {
	const {t}         = useTranslation()
	const theme       = useTheme()
	const handleClose = () => { setOpen(false) }

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{fontSize: theme.typography.h3.fontSize}}>
          {t('messages.deleteconfirmation')}
        </DialogTitle>
        <DialogContentText id="alert-dialog-description" sx={{padding: "16pt", mt: 1}}>
          {t('messages.deletewarning')}
        </DialogContentText>

        <DialogActions>
          <Button id={'message-' + id + '-delete-button'} onClick={(e) => { handleClose(e); onClick(e); }}>{t('messages.delete')}</Button>
          <Button id={'message-' + id + '-cancel-button'} onClick={handleClose} autoFocus>{t('cancel')}</Button>
        </DialogActions>
      </Dialog>
  );
}


export default DeleteMessageButton