import { Box }             from '@mui/material';
import PreviousButton      from 'components/message/PreviousButton';
import PrintMessageButton  from 'components/message/PrintMessageButton';
import DeleteMessageButton from 'components/message/DeleteMessageButton';

const MessageBanner = ({messageRef, message}) => (
  <Box sx={{ marginBottom: "5px", display: "flex", flexDirection: "row"}}>
    <PreviousButton/>
    <Box sx={{flexGrow: 1}}/>
    <PrintMessageButton messageRef={messageRef}/>
    <DeleteMessageButton id={message.id} />
  </Box>
)

export default MessageBanner