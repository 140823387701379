import Elevate from 'components/common/Elevate';
import React from 'react';

import { Box } from '@mui/material';

import { WMPageComponent } from '../contexts/WindowManagerContext';
import { TitleComponent } from './TitleComponent';

export const PageComponent: WMPageComponent = ({ children, sx }) => {
  return (
    <Box className='page-root' sx={{ ...sx, height: "100%", padding: "10px" }}>
      {/* @ts-ignore */}
      <Elevate className='pane-elevation' sx={{ flexGrow: 1, display: "flex", flexDirection: "column", height: "100%", maxHeight: "100%", overflow: "auto" }}>
        <TitleComponent />
        <Box className='pane-content' sx={{ display: "flex", flexGrow: 1, overflow: "auto", width: "100%" }}>
          {children}
        </Box>
      </Elevate>
    </Box>
  );
};
