import { useCurrentUser } from 'authentication/main';
import { getProcessName } from 'components/form/utils/form-utils';
import Settings from 'helpers/settings';
import GearsTranslator from 'helpers/translator';
import { useTranslator } from 'hooks/translator';
import React, { createContext } from 'react';
import {
    Form, ID, LinkKind, ProcessDefinition, Task, TaskDocumentation, User
} from 'types/graphql';
import Types from 'types/types';
import { createTaskDescription } from 'utils/descriptions';

export const FormContext = createContext<FormInfo>({} as FormInfo);

export type FormInfo = {
    subject?:             string
    kind :                LinkKind
    prefill :             any
    isStartForm :         boolean
    processForm :         ProcessForm
    form :                Form
    basedOn?:             any
    hasSubmit :           boolean
    id :                  ID
    taskId?:              ID
    processInstanceId? :  ID
    processDefinitionId : ID
    processDefinition :   ProcessDefinition
    title :               string
    description? :        string
    documentation :       TaskDocumentation
    statusUrl?:           string
    reference :           TaskReference
    taskInfo :            TaskInfo
}

type TaskReference = {
  processDefinitionId?: ID
  taskId?:              ID
}

type TaskInfo = {
  nr:  number
  for: string
}

type ProcessForm = {
  task?:              Task
  processDefinition?: ProcessDefinition
  isStartForm :       boolean
}

interface FormInfoProviderProps extends ProcessForm {
  children: React.ReactNode
}

const FormInfoProvider = ({children, ...form}: FormInfoProviderProps) => {
  const { translator } = useTranslator()
  const documentation  = useTaskDocumentation(form)

  const formInfo: FormInfo = form.isStartForm 
    ? createStartFormInfo(translator, form, documentation) 
    : createTaskFormInfo(translator, form, documentation)

  return (
    <FormContext.Provider value={formInfo}>
      {children}
    </FormContext.Provider>
  )
}

const useTaskDocumentation = (form: ProcessForm): TaskDocumentation => {
  const user           = useCurrentUser()
  const { translator } = useTranslator()
  const taskUser       = form.task?.assignee || (user.user as User)

  return getTaskDocumentation({translator, form, user: taskUser})
}

const createStartFormInfo = (translator: GearsTranslator, processForm: ProcessForm, documentation: TaskDocumentation): FormInfo => {
  const processDefinition = FormHelper.getProcessDefinition(processForm) as ProcessDefinition
  const processKey        = processDefinition.key
  const processName       = getProcessName(translator, FormHelper.toTaskLike(processForm))
  const title             = translator.toTaskTitle(processKey, documentation.nr, { variables: documentation, fallback: processName })
  const description       = translator.toTaskDescription(processKey, documentation.nr, { variables: documentation, strict: false, fallback: " " }) 

  const form = processDefinition.startForm as Form 
  return {
    kind:                Settings.session.readOnce("FORM.KIND"), // PROCESS_FILL or PROCESS_SUBMIT
    prefill:             Settings.session.readOnce("FORM.VALUES"),
    isStartForm:         true,
    processForm:         processForm,
    basedOn:             form.values?.__basedOn,
    form:                withSubmitAsLast(form),
    id:                  processDefinition.id,
    hasSubmit:           form.fields.some(field => Types.isSubmitField({form: processDefinition.startForm}, field)),
    taskId:              undefined,
    processInstanceId:   undefined,
    processDefinition:   processDefinition,
    processDefinitionId: processDefinition.id,
    title:               title as string,
    description:         description == " " ? undefined : description,
    documentation:       { ...documentation },
    statusUrl:           undefined, // TODO
    reference:           { processDefinitionId: processDefinition.id },
    taskInfo:            documentation
  }
}

const createTaskFormInfo = (translator: GearsTranslator, processForm: ProcessForm, documentation: TaskDocumentation): FormInfo => {
  const processKey   = FormHelper.getProcessKey(processForm) as string
  const task         = processForm.task as Task
  const title        = translator.toTaskTitle(processKey, documentation.nr, { variables: documentation, fallback: task.name })
  const fallbackDesc = createTaskDescription(translator, task)
  const description  = translator.toTaskDescription(processKey, documentation.nr, { variables: documentation, fallback: fallbackDesc })

  const form = processForm.task?.form as Form
  return {
    subject:             task.subject,
    kind:                Settings.session.readOnce("FORM.KIND"),
    prefill:             Settings.session.readOnce("FORM.VALUES"),
    isStartForm:         false,
    processForm:         processForm,
    basedOn:             form.values?.__basedOn,
    form:                withSubmitAsLast(form),
    hasSubmit:           form.fields.some(field => Types.isSubmitField({form}, field)),
    id:                  task.id,
    taskId:              task.id,
    processInstanceId:   task.processInstanceId,
    processDefinitionId: task.processDefinition?.id as string,
    processDefinition:   task.processDefinition as ProcessDefinition,
    title:               title as string,
    description:         description,
    documentation:       task.documentation,
    statusUrl:           `/gears/status/${task.processInstanceId}/${task.id}`,
    reference:           { taskId: task.id },
    taskInfo:            documentation
  }
}

function withSubmitAsLast(form: Form) {
  const formInfo = {form : form} as FormInfo
  const submits  = form.fields.filter(field => Types.isSubmitField(formInfo, field))

  if (submits.length > 0) {
    return {
      ...form,
      fields: [...form.fields.filter(field => !Types.isSubmitField(formInfo, field)), ...submits]
    }
  } else return form
}

class FormHelper {
  public static getProcessDefinition(form: ProcessForm): ProcessDefinition | undefined {
    return form.isStartForm ? form.processDefinition : form.task?.processDefinition
  }

  public static getProcessKey(form: ProcessForm): string | undefined {
    return this.getProcessDefinition(form)?.key
  }

  public static getForm(form: ProcessForm): Form | undefined {
    return form.isStartForm ? form.task?.form : form.processDefinition?.startForm
  }

  public static toTaskLike(form: ProcessForm): any {
    return form.isStartForm ? form : form.task
  }
}


export function getTaskTitle(translator: GearsTranslator, form: ProcessForm) {
  const processKey = FormHelper.getProcessKey(form) as string
  const taskNr     = form.task?.documentation?.nr || 1
  const taskInfo   = getTaskDocumentation({translator, form}) 

  return translator.toTaskTitle(processKey, taskNr, { variables: taskInfo, fallback: form.task?.name })
}

type TaskDocumentationProps = {
  form :       ProcessForm
  translator : GearsTranslator
  user?:       User
}

export function getTaskDocumentation({translator, form, user}: TaskDocumentationProps): TaskDocumentation {
  const processKey     = FormHelper.getProcessKey(form) as string
  const nr             = form.task?.documentation?.nr || 1
  const taskFor        = form.task?.documentation?.for
  const currentUser    = form.task?.assignee || user
  const username       = currentUser?.username

  const forName = taskFor
    ? translator.toActorName(processKey, taskFor)
    : (username
      ? translator.toActorName(processKey, username, currentUser?.name || currentUser?.username || currentUser?.id)
      : translator.t("you")
    )

  return { nr, for: forName }
}

export default FormInfoProvider
