import { toDisplayValue } from 'components/render/utils/render'
import { useLocale } from 'hooks/locale'

import { RenderSimpleValue } from './RenderSimpleValue';

const RenderPeriodObject = ({value}: {value: any}): JSX.Element => {
  const { language, locale } = useLocale()
  // TODO het lijkt erop dat toDisplayValue 2x wordt aangeroepen: hier en in RenderSimpleValue.
  return  <RenderSimpleValue value={toDisplayValue(value.duration, {language, locale})}/>
}

export default RenderPeriodObject
