import merge from 'deepmerge';
import { T } from 'helpers/translator';
import { Notifier } from 'hooks/notification';
import _ from 'lodash';
import { MenuItem } from 'types/menu';
import { decomposeRefPath } from 'utils/path';

import * as icons from '@mui/icons-material';

export interface PartialMenuItem extends Partial<MenuItem> {}

export const createMenuItems = (translator: T, notifier: Notifier, menu?: PartialMenuItem[]): MenuItem[] => {
  return Array.isArray(menu) ? menu.map(item => createMenuItem(translator, notifier, item)) : []
} 

const createMenuItem = (translator: T, notifier: Notifier, item: PartialMenuItem) : MenuItem => {
  const defaultInfo = getMenuItemInfo(translator, notifier, item)
  const key         = defaultInfo.key || item.key
  const label       = translator.toMenuLabel(key, item.label)
  const children    = Array.isArray(item.children) ? createMenuItems(translator, notifier, item.children) : undefined
  const type        = Array.isArray(item.children) ? (item.type || "cascading") : "item"

  return merge.all([defaultInfo, item, { key, label, type, children }], mergeOptions) as MenuItem
}

const overwriteMerge = (destinationArray: any, sourceArray: any, options: any) => sourceArray
const mergeOptions   = { arrayMerge: overwriteMerge }


export function getMenuItemInfo(translator: T, notifier: Notifier, menuItem: PartialMenuItem) {
  return toRefMenuInfo(translator, notifier, menuItem.ref)
}

export function isValidMenuItem(notifier: any, key: string, href: string, icon: string, activeIcon: string) {
  if (!key) {
    notifier.error("A menu item does not have an id for key: " + key)
    return false
  }

  if (!href) {
    notifier.error("A menu item does not have an href for key: " + key)
    return false
  }

  if (!icon) {
    notifier.error("A menu item does not have an icon for key: " + key)
    return false
  }

  if (!icons[icon as keyof typeof icons]) {
    notifier.error("Icon name '"+ icon + "' does not resolve to an icon for key: " + key)
    return false
  }

  if (!activeIcon) {
    notifier.error("A menu item does not have an active icon for key: " + key)
    return false
  }

  if (!icons[icon as keyof typeof icons]) {
    notifier.error("Icon name '"+ icon + "' does not resolve to an active icon for key: " + key)
    return false
  }

  return true
}

export const defaultMenu = 
[
  {
    "key":  "process.start",
    "ref":  "default:start"
  },
  {
    "key":  "tasks.assigned",
    "ref":  "default:tasks.assigned"
  },
  {
    "key":  "tasks.group",
    "ref":  "default:tasks.group"
  },
  {
    "key":  "tasks.transferable",
    "ref":  "default:tasks.transferable"
  }, 
  {
    "key":  "tasks.all",
    "ref":  "default:tasks.all",
    "roles": ["admin"]
  },
  {
    "key":  "messages",
    "ref":  "default:messages"
  },
  {
    "key":  "documents",
    "ref":  "default:documents"
  },
  {
    "key":  "processes.active.all",
    "ref":  "default:processes.active.all",
    "roles": ["admin"]
  },
  {
    "key":  "deployments.active",
    "ref":  "default:deployments.active",
    "roles": ["admin"]
  }
]

export const defaultMenuInfo = {
  "start": {
    "href": "/gears/processes/start",
    "key": "start",
    "icon": "CottageOutlined",
    "activeIcon": "Cottage"
  },
  "tasks.assigned": {
    "href": "/gears/tasks/assigned",
    "key": "tasks.assigned",
    "icon": "AssignmentTurnedInOutlined",
    "activeIcon": "AssignmentTurnedIn",
    "badge": "assignedBadge",
  },
  "tasks.group": {
    "href": "/gears/tasks/group",
    "key": "tasks.group",
    "icon": "AssignmentIndOutlined",
    "activeIcon": "AssignmentInd",
    "badge": "groupBadge"
  },
  "tasks.transferable": {
    "href": "/gears/tasks/transferable",
    "key": "tasks.transferable",
    "icon": "TransferWithinAStationOutlined",
    "activeIcon": "TransferWithinAStation",
  },
  "tasks.all": {
    "href": "/gears/tasks/all",
    "key": "tasks.all",
    "icon": "AssignmentOutlined",
    "activeIcon": "Assignment",
    "badge": "allBadge"
  },
  "messages": {
    "href": "/gears/messages",
    "key": "messages",
    "icon": "EmailOutlined",
    "activeIcon": "Email",
    "badge": "messagesBadge"
  },
  "documents": {
    "href": "/gears/documents",
    "key": "documents",
    "icon": "InsertDriveFileOutlined",
    "activeIcon": "InsertDriveFile",
  },
  "processes.active.mine": {
    "href": "/gears/instances/mine",
    "key": "instances.mine",
    "icon": "AccountTreeOutlined",
    "activeIcon": "AccountTree",
    "badge": "processBadge"
  },
  "processes.active.all": {
    "href": "/gears/instances/all",
    "key": "instances.all",
    "icon": "AccountTreeOutlined",
    "activeIcon": "AccountTree"
  },
  "deployments.active": {
    "href": "/gears/deployments",
    "key": "deployments",
    "icon": "BallotOutlined",
    "activeIcon": "Ballot",
  }
}

function toDefaultMenuInfo(subdomain: string) {
  return _.get(defaultMenuInfo, subdomain)
}

function toListMenuInfo(subdomain: string) {
  return { href: "/gears/lists/" + subdomain }
}

function toProcessMenuInfo(processKey: string) {
  return { 
    href: "/gears/process/" + processKey,
    processKey
  }
}

export function toRefMenuInfo(translator: T, notifier: Notifier, path?: string): any {
  const { domain, key } = decomposeRefPath(path)

  switch (domain) {
    case "default": 
      const info = toDefaultMenuInfo(key as string)
      if (!info){
        notifier.error("No default menu item exists with key: " + key)
        return {}
      } else 
        return info

    case "lists":
    case "list":    return toListMenuInfo(key as string) 
    case "process": return toProcessMenuInfo(key as string) 
    default:        return {}
  }
}

