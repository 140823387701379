import { Box, CircularProgress, SxProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export type LoaderSize = "large" | "medium" | "small" | "tiny" | "micro"

const Loader = ({size = 'medium'}: {size?: LoaderSize}) => {

  const style = getLoaderStyle(size)
  const content = (
    <Box sx={style.iconContainer}>
      <Icon {...style.icon}/>
    </Box>
  )
  
	return (<CircularProgressWithContent style={style.spinner} color={style.icon.color} content={content} />)
};

function getLoaderStyle(size: LoaderSize) {

	const theme = useTheme();
  const sizes = { 
    large : 60,
    medium: 50,
    small : 30,
    tiny  : 25,
    micro : 20
  }

  const padding = {
    large : {
      pt: '17px',
      pl: '10px'
    },
    medium: {
      pt: '14px',
      pl: '8px'
    },
    small:  {
      pt: '8px',
      pl: '5px'
    },
    tiny  : {
      pt: '4.3px',
      pl: '4px'
    },
    micro  : {
      pt: '1px',
      pl: '3.5px'
    }
  }

  const selectedSize = sizes.hasOwnProperty(size) ? size : 'medium' 
  const pxSize       = sizes[selectedSize]
  const factor       = 0.65
 	const loaderColor  = theme.palette.primary.main ? theme.palette.primary.main : '#5664d2';

  const style        = {
    spinner: {
      height: pxSize + 'px',
      width:  pxSize + 'px'
    }, 
    icon: {
      width: pxSize * factor + 'px',
      color: loaderColor
    },
    iconContainer: {
      ...padding[selectedSize]  
    }
  }

  return style
}

/* add content to the center of the loaded */
function CircularProgressWithContent({color, content, style}: {color?: React.CSSProperties['color'], style: React.CSSProperties, content: JSX.Element}) {
  return (
    <Box sx={{ ...style, position: "relative" }}>
      <CircularProgress style={{ color, ...style }} />
      <Box
        top={0}
        position="absolute"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {content}
        </Typography>
      </Box>
    </Box>
	);
}

/* this is the XLRIT icon */
const Icon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Laag_1" x="0px" y="0px" style={{ fill:props.color, width: '35px', enableBackground: "new 0 0 264.58 136.46", ...props}} xmlSpace="preserve" viewBox="163.09 16.35 41.34 25.01">
  <g>
  	<polygon className="st2" points="163.09,34.77 184.91,25.71 170.95,41.36 170.93,34.55  "/>
  	<g>
  		<path className="st2" d="M177.36,22.07c5.13,1.52,10.03,0.27,10.03,0.27s-3.42-3.72-8.56-5.24c-5.13-1.52-10.03-0.27-10.03-0.27    S172.23,20.55,177.36,22.07"/>
  		<path className="st2" d="M178.41,26.8c5.24-1.07,8.98-4.47,8.98-4.47s-4.77-1.67-10.01-0.61c-5.24,1.07-8.98,4.47-8.98,4.47    S173.17,27.86,178.41,26.8"/>
  		<path className="st2" d="M183.4,31.5c0,0,11.1,7.57,13.14,8.95c2.12,1.44,5.34-0.02,6.79-1.72c2.56-3-0.01-5.57-1.02-6.24    c-1.6-1.06-13.28-9.19-13.28-9.19L183.4,31.5z"/>
  		<path className="st2" d="M190.28,30.09c1.91,0,3.46-1.33,3.46-1.33s-1.55-1.33-3.46-1.33c-1.91,0-3.46,1.33-3.46,1.33    S188.37,30.09,190.28,30.09"/>
  	</g>
  </g>
  </svg>
)

export default Loader;