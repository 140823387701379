import { has } from "lodash"
import { defaultMenu } from "contexts/utils/menu"
import { useGearsIconInfo } from "components/process/GearsIcon"

function isAllowed(item, user) {
  if (!user?.user)
      return false

  if (user.user.isAdmin)
    return true

  const userHasRole = (role) => Boolean(user.user.roles?.some(userRole => userRole.name == role))
  return !item.hasOwnProperty("roles") || item.roles.some(userHasRole)
}

export function toFilteredMenu(menu, user) {
  const hasChildrenArray = (item) => item.hasOwnProperty("children") && Array.isArray(item.children)
  const hasChildren      = (item) => hasChildrenArray(item) && item.children.length != 0
  const isRecursiveType = (item) => item.type == "group" || item.type == "cascading"

  const toFilteredChildren = (item) => {
    if (hasChildrenArray(item))
      item.children = toFilteredMenu(item.children, user) 
    
    return item
  }

  const convertEmptyCascadingItems = (item) => {
    if (item.type == "cascading" && item.hasOwnProperty("href")) {
      if (!hasChildrenArray(item) || hasChildrenArray(item) && item.children.length == 0) {
        item.type = "item"
        if (item.hasOwnProperty("children"))
          delete item.children
      } 
    }
    return item
  }

  const filterEmptyRecusiveTypes = (item) => 
    !(isRecursiveType(item) && (!hasChildrenArray(item) || item.children.length == 0))

  const flattenSingleGroup = (menu) => 
    menu.every(item => item.type == "group") && menu.length == 1 ? menu.flatMap(item => item.children) : menu 

  const menuItems =  menu
    // remove items that are not allowed for the current user
    .filter(item => isAllowed(item, user))
    // create a new item (to make it mutable)
    .map(item => ({...item}))
    // remove children that are not allowed
    .map(item => toFilteredChildren(item))
    // convert empty cascading items to normal items
    .map(convertEmptyCascadingItems)
    // remove recursive items with no children
    .filter(filterEmptyRecusiveTypes)
    // remove unwanted info
    .map(item => { delete item.roles; return item })
   
    return flattenSingleGroup(menuItems)
}

export const useUserMenu = (menu, user) => {
  if (!user?.user)
    return []

  const m = menu ? menu : defaultMenu
  return toFilteredMenu(m, user)
}
