import { IconButton, Tooltip }   from '@mui/material';
import { useNavigate }           from 'react-router';
import { ArrowBack as BackIcon } from '@mui/icons-material';

const PreviousButton = ({}) => {
  const navigate = useNavigate()
  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    navigate(-1)
  }

  return (
    <Tooltip disableInteractive title={"Go back"}>
      <span>
        <IconButton tabIndex={-1} size="small" onClick={onClick}  >
          <BackIcon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default PreviousButton