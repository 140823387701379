import _ from 'lodash'
import moment from 'moment-business-days'

/* mock data for gantt chart testing */
export const mockScheduleData = [
  {
    start: new Date(2023, 1, 30),
    end: new Date(2023, 6, 30),
    name: 'Kasto zaagresource',
    id: 'p004ma01resource01',
    type:'project',
  },
  {
    start: new Date(2023, 1, 30),
    end: new Date(2023, 6, 30),
    name: 'Behro Afkort',
    id: 'p004ma01resource04',
    type:'project',
  },
  {
    start: new Date(2023, 1, 30),
    end: new Date(2023, 6, 30),
    name: 'KMX',
    id: 'p004ma01resource05',
    type:'project'
  },
  {
    start: new Date(2023, 1, 30),
    end: new Date(2023, 6, 30),
    name: 'LT10-H',
    id: 'p004ma01resource06',
    type:'project'
  },
  {
    start: new Date(2023, 1, 30),
    end: new Date(2023, 6, 30),
    name: 'MX-45',
    id: 'p004ma01resource17',
    type:'project'
  },
  {
    start: new Date(2023, 1, 30),
    end: new Date(2023, 6, 30),
    name: 'Parelstraalresource',
    id: 'p004ma01resource20',
    type:'project'
  },
  {
    start: new Date(2023, 1, 30),
    end: new Date(2023, 6, 30),
    name: 'Verzinken',
    id: 'p004ma01ptype20',
    type:'project'
  },
  {
    start: new Date(2023, 1, 30),
    end: new Date(2023, 6, 30),
    name: 'Anodiseren',
    id: 'p004ma01ptype40',
    type:'project'
  },
  {
    start: new Date(2023, 1, 30),
    end: new Date(2023, 6, 30),
    name: 'Afbramen',
    id: 'p004ma01ptype50',
    type:'project'
  },
  {
    start: new Date(2023, 1, 30),
    end: new Date(2023, 6, 30),
    name: 'Assembleren',
    id: 'p004ma01ptype51',
    type:'project'
  },
  {
    start: new Date(2023, 1, 30),
    end: new Date(2023, 6, 30),
    name: 'Inpakken',
    id: 'p004ma01ptype52',
    type:'project'
  },
  {
    start: new Date(2023, 1, 30),
    end: new Date(2023, 6, 30),
    name: 'Logistiek',
    id: 'ttypelog01',
    type:'project'
  },
  {
    start: new Date(2023, 3, 7),
    end: new Date(2023, 3, 8),
    name: 'Order 1001',
    id: 'o1001 t1',
    orderId: 'o1001',
    type:'task',
    project: 'p004ma01resource01'
  },
  {
    start: new Date(2023, 3, 8),
    end: new Date(2023, 3, 9),
    name: 'Order 1001',
    id: 'o1001 t2',
    orderId: 'o1001',
    type:'task',
    project: 'p004ma01resource06',
    dependencies: ['o1001 t1']
  },
  {
    start: new Date(2023, 3, 9),
    end: new Date(2023, 3, 10),
    name: 'Order 1001',
    id: 'o1001 t3',
    orderId: 'o1001',
    type:'task',
    project: 'p004ma01ptype52',
    dependencies: ['o1001 t2']
  },
  {
    start: new Date(2023, 3, 9),
    end: new Date(2023, 3, 10),
    name: 'Order 1001',
    id: 'o1001 t4',
    orderId: 'o1001',
    type:'task',
    project: 'ttypelog01',
    dependencies: ['o1001 t3']
  },
  {
    start: new Date(2023, 3, 8),
    end: new Date(2023, 3, 9),
    name: 'Order 1002',
    id: 'o1002 t1',
    orderId: 'o1002',
    type:'task',
    project: 'p004ma01resource01'
  },
  {
    start: new Date(2023, 3, 8),
    end: new Date(2023, 3, 10),
    name: 'Order 1002',
    id: 'o1002 t2',
    orderId: 'o1002',
    type:'task',
    project: 'p004ma01resource17',
    dependencies: ['o1002 t1']
  },
  {
    start: new Date(2023, 3, 10),
    end: new Date(2023, 3, 11),
    name: 'Order 1002',
    id: 'o1002 t3',
    orderId: 'o1002',
    type:'task',
    project: 'ttypelog01',
    dependencies: ['o1002 t2']
  },
  {
    start: new Date(2023, 3, 10),
    end: new Date(2023, 3, 16),
    name: 'Order 1002',
    id: 'o1002 t4',
    orderId: 'o1002',
    type:'task',
    project: 'p004ma01ptype20',
    dependencies: ['o1002 t3']
  },
  {
    start: new Date(2023, 3, 15),
    end: new Date(2023, 3, 16),
    name: 'Order 1002',
    id: 'o1002 t5',
    orderId: 'o1002',
    type:'task',
    project: 'ttypelog01',
    dependencies: ['o1002 t4']
  },
  {
    start: new Date(2023, 3, 16),
    end: new Date(2023, 3, 17),
    name: 'Order 1002',
    id: 'o1002 t6',
    orderId: 'o1002',
    type:'task',
    project: 'p004ma01ptype52',
    dependencies: ['o1002 t5']
  },
  {
    start: new Date(2023, 3, 16),
    end: new Date(2023, 3, 17),
    name: 'Order 1002',
    id: 'o1002 t7',
    orderId: 'o1002',
    type:'task',
    project: 'ttypelog01',
    dependencies: ['o1002 t6']
  },
  {
    start: new Date(2023, 3, 13),
    end: new Date(2023, 3, 14),
    name: 'Order 1003',
    id: 'o1003 t1',
    orderId: 'o1003',
    type:'task',
    project: 'p004ma01resource01'
  },
  {
    start: new Date(2023, 3, 15),
    end: new Date(2023, 3, 20),
    name: 'Order 1003',
    id: 'o1003 t2',
    orderId: 'o1003',
    type:'task',
    project: 'p004ma01resource05',
    dependencies: ['o1003 t1']
  },
  {
    start: new Date(2023, 3, 20),
    end: new Date(2023, 3, 22),
    name: 'Order 1003',
    id: 'o1003 t3',
    orderId: 'o1003',
    type:'task',
    project: 'p004ma01ptype52',
    dependencies: ['o1003 t2']
  },
  {
    start: new Date(2023, 3, 22),
    end: new Date(2023, 3, 23),
    name: 'Order 1003',
    id: 'o1003 t4',
    orderId: 'o1003',
    type:'task',
    project: 'ttypelog01',
    dependencies: ['o1003 t3']
  },
  {
    start: new Date(2023, 3, 9),
    end: new Date(2023, 3, 11),
    name: 'Order 1004',
    id: 'o1004 t1',
    orderId: 'o1004',
    type:'task',
    project: 'p004ma01resource01'
  },
  {
    start: new Date(2023, 3, 11),
    end: new Date(2023, 3, 13),
    name: 'Order 1004',
    id: 'o1004 t2',
    orderId: 'o1004',
    type:'task',
    project: 'p004ma01resource06',
    dependencies: ['o1004 t1']
  },
  {
    start: new Date(2023, 3, 14),
    end: new Date(2023, 3, 17),
    name: 'Order 1004',
    id: 'o1004 t3',
    orderId: 'o1004',
    type:'task',
    project: 'p004ma01resource17',
    dependencies: ['o1004 t2']
  },
  {
    start: new Date(2023, 3, 17),
    end: new Date(2023, 3, 18),
    name: 'Order 1004',
    id: 'o1004 t4',
    orderId: 'o1004',
    type:'task',
    project: 'p004ma01ptype51',
    dependencies: ['o1004 t3']
  },
  {
    start: new Date(2023, 3, 18),
    end: new Date(2023, 3, 19),
    name: 'Order 1004',
    id: 'o1004 t5',
    orderId: 'o1004',
    type:'task',
    project: 'ttypelog01',
    dependencies: ['o1004 t4']
  },
  {
    start: new Date(2023, 3, 18),
    end: new Date(2023, 3, 23),
    name: 'Order 1004',
    id: 'o1004 t6',
    orderId: 'o1004',
    type:'task',
    project: 'p004ma01ptype40',
    dependencies: ['o1004 t5']
  },
  {
    start: new Date(2023, 3, 22),
    end: new Date(2023, 3, 23),
    name: 'Order 1004',
    id: 'o1004 t7',
    orderId: 'o1004',
    type:'task',
    project: 'ttypelog01',
    dependencies: ['o1004 t6']
  },
  {
    start: new Date(2023, 3, 22),
    end: new Date(2023, 3, 23),
    name: 'Order 1004',
    id: 'o1004 t8',
    orderId: 'o1004',
    type:'task',
    project: 'ttypelog01',
    dependencies: ['o1004 t7']
  },
  {
    start: new Date(2023, 3, 13),
    end: new Date(2023, 3, 14),
    name: 'Order 1005',
    id: 'o1005 t1',
    orderId: 'o1005',
    type:'task',
    project: 'p004ma01resource01',
    dependencies: ['o1004 t1']
  },
  {
    start: new Date(2023, 3, 14),
    end: new Date(2023, 3, 15),
    name: 'Order 1005',
    id: 'o1005 t2',
    orderId: 'o1005',
    type:'task',
    project: 'p004ma01resource06',
    dependencies: ['o1005 t1']
  },
  {
    start: new Date(2023, 3, 15),
    end: new Date(2023, 3, 16),
    name: 'Order 1005',
    id: 'o1005 t3',
    orderId: 'o1005',
    type:'task',
    project: 'p004ma01ptype50',
    dependencies: ['o1005 t2']
  },
  {
    start: new Date(2023, 3, 14),
    end: new Date(2023, 3, 15),
    name: 'Order 1006',
    id: 'o1006 t1',
    orderId: 'o1006',
    type:'task',
    project: 'ttypelog01'
  },
  {
    start: new Date(2023, 3, 10),
    end: new Date(2023, 3, 13),
    name: 'Order 1007',
    id: 'o1007 t1',
    orderId: 'o1007',
    type:'task',
    project: 'p004ma01resource04'
  },
  {
    start: new Date(2023, 3, 13),
    end: new Date(2023, 3, 15),
    name: 'Order 1007',
    id: 'o1007 t2',
    orderId: 'o1007',
    type:'task',
    project: 'p004ma01resource17',
    dependencies: ['o1007 t1']
  },
  {
    start: new Date(2023, 3, 15),
    end: new Date(2023, 3, 17),
    name: 'Order 1007',
    id: 'o1007 t3',
    orderId: 'o1007',
    type:'task',
    project: 'p004ma01resource20',
    dependencies: ['o1007 t2']
  },
  {
    start: new Date(2023, 3, 16),
    end: new Date(2023, 3, 17),
    name: 'Order 1007',
    id: 'o1007 t4',
    orderId: 'o1007',
    type:'task',
    project: 'ttypelog01',
    dependencies: ['o1007 t3']
  }
]

function createSvelteDependenciesPerOrder(data) {
  const tasks        = getTasks(data)
  const groupedTasks = _.groupBy(tasks, 'orderId')

  const deps = Object.entries(groupedTasks).map(([orderId, tasks]) => [orderId, createSvelteDependenciesFromTasks(tasks)])
  return Object.fromEntries(deps)
}

function createSvelteDependenciesFromTasks(tasks) {
  return tasks
    .filter(task  => Array.isArray(task.dependencies) && task.dependencies.length)
    .flatMap(task => task.dependencies.map((fromId, index) => ({id: `${task.orderId}-${fromId}-${task.id}-${index}`, fromId, toId: task.id})))
} 

function createSvelteDependencies(data) {
  const tasks = getTasks(data)
  return createSvelteDependenciesFromTasks(tasks)
}

function createSvelteResources(data){
  return getResources(data)
    .map(resource => ({
      id: resource.id,
      label: resource.name,
    }))
}

const markOverlappingByResource = (tasks) => {
  const byResource = _.groupBy(tasks, "gears.resourceId")
  Object.entries(byResource).forEach(([_, tasks]) => markOverlapping(tasks))    
}

// TODO: add the following function
const markOverlappingByOrder = (tasks) => {
  const byOrder = _.groupBy(tasks, "gears.orderId")
  Object.entries(byOrder).forEach(([_, tasks]) => markOverlapping(tasks))    
}

/* mark any task that starts when another tasks is already running */
function markOverlapping(tasks) {
  const mark = (task, conflict) => {
    task.classes         = "gantt-task-red"
    const conflicts      = task.gears.conflicts || new Set()
    conflicts.add(conflict)
    task.gears.conflicts = conflicts
  }

  const diff     = (aMoment, bMoment) => aMoment.valueOf() - bMoment.valueOf()
  const isBefore = (aMoment, bMoment) => diff(aMoment, bMoment) < 0
  const t        = tasks.sort((t1, t2) => diff(t1.to, t2.to) || diff(t1.from, t2.from))

  for (let i = t.length-1; i > 0; i--) { 
    if (isBefore(t[i].from, t[i-1].to)) {
      if (isBefore(t[i].from, t[i-1].from))
        mark(t[i-1], t[i])
      else if (isBefore(t[i-1].from, t[i].from))
        mark(t[i], t[i-1])
      else {
        mark(t[i], t[i-1])
        mark(t[i-1], t[i])
      }
    } 
  }
}

function createSvelteTasks(data){
  const tasks = getTasks(data)
    .map(task => ({
      id: task.id,
      label: task.name,
      resourceId: `${task.project}-${task.orderId}`,  // <resource id>-<order id> is unique for a gantt row
      gears : {
        resourceId: task.project,
        orderId: task.orderId
      },
      enableDragging: false, 
      from: moment(task.start), 
      to: moment(task.end)}
    ))

  markOverlappingByResource(tasks)
  //markOverlappingByOrder(tasks)

  return tasks
}

function createSvelteRow(data, resource) {
  const tasks    = getTasksOfResource(data, resource)
  const byOrder  = _.groupBy(tasks, "orderId")
  const children = Object.values(byOrder)
    .flatMap(tasks => tasks[0])
    .map(task => ({id: `${task.project}-${task.orderId}`, label: task.name, gears: { resourceId: task.project, orderId: task.orderId }, }))
  
  return {
    id: resource.id,
    label: resource.name,
    gears: {
      resourceId: resource.id 
    },
    children: children.length ? children : undefined,
    expanded: false
  }
}

function createResourceCentricRows(resources, tasks) {
  function createResourceRow(resource) {
    const resourceTasks = tasks.filter(task => task.gears.resourceId == resource.gears.resourceId)
    const byOrder  = _.groupBy(resourceTasks, "gears.orderId")
    const children = Object.values(byOrder)
      .flatMap(tasks => tasks[0])
      .map(task => ({
        id: `${task.gears.resourceId}-${task.gears.orderId}`, 
        label: task.label
      }))
 
    return {
      ...resource,
      expanded: false,
      children: children.length ? children : undefined
    }
  }  

  return resources.map(createResourceRow)
}


export const mockSvelteResources        = createSvelteResources(mockScheduleData)
export const mockSvelteTasks            = createSvelteTasks(mockScheduleData)
export const svelteDependencies         = createSvelteDependencies(mockScheduleData)
export const svelteDependenciesPerOrder = createSvelteDependenciesPerOrder(mockScheduleData)

function getTasks(data) {
  return data.filter(entry => entry.type != 'project')
}

function getResources(data) {
  return data.filter(entry => entry.type == 'project')
}

function getDataByResources(data) {
  const resources = getResources(data)
  return resources.flatMap(resource => getDataByResource(data, resource))
}

function getTasksOfResource(data, resource) {
  return data.filter(entry => entry.id != resource.id && entry.project == resource.id)
}

function getDataByResource(data, resource) {
  const orders = getTasksOfResource(data, resource)
  const startDates = orders.map(order => order.start)
  const endDates   = orders.map(order => order.end)
  const start      = getMinDate(startDates)
  const end        = getMaxDate(endDates)


  const newResource = {...resource,start, end}
  return [newResource, ...orders]
}

function getDataByOrders(data) {
  const orderIds  = getOrderIds(data)
  return orderIds.flatMap(id => getOrderData(data, id))
}

function getOrderIds(data) {
  const ids = {}
  return data
    .filter(e => e.orderId && !(ids[e.orderId]=e.orderId in ids))
    .map(entry => entry.orderId)
}

function getMinDate(dates) {
  return new Date(Math.min.apply(null, dates))
}

function getMaxDate(dates) {
  return new Date(Math.max.apply(null, dates))
}

function getOrderData(data, orderId) {
  const toOrderProject = (orders) => {
    const startDates = orders.map(order => order.start)
    const endDates   = orders.map(order => order.end)
    const start      = getMinDate(startDates)
    const end        = getMaxDate(endDates)

    return {
      start,
      end,
      id: "order " + orders[0].orderId,
      orderId: orders[0].orderId,
      type: 'project',
      name: orders[0].name
    }
  }

  const toResourceTask = (order, suborder) => {
    const resource = data.find(e => e.id == suborder.project)
    return {
      name: resource.name,
      start: suborder.start,
      end: suborder.end,
      id: suborder.id,
      project: order.id,
      dependencies: suborder.dependencies ? suborder.dependencies : []
    }
  }

  const suborders = data.filter(entry => entry.orderId == orderId)
  const order     = toOrderProject(suborders)
  const tasks     = suborders.map(suborder => toResourceTask(order, suborder))

  return [order,...tasks]
}

