import React, { useState, forwardRef, useCallback } from 'react';
import { useSnackbar, SnackbarContent } from 'notistack';
import {  
	Collapse,
  Paper,
  Typography,
  Card,
  CardActions,
  Button,
  IconButton, 
  Box} from '@mui/material';
import {
    Close as CloseIcon, 
    ExpandMore as ExpandMoreIcon, 
    CheckCircle as CheckCircleIcon }  from '@mui/icons-material'; 
import { useNotifier } from 'hooks/notification';
import { useTranslation } from 'react-i18next';
import { download } from 'utils/download';

const notificationSx = {
    root: {
        ...(theme => ({[theme.breakpoints.up('sm')]: {
            minWidth: '344px !important',
        }})),
    },
    card: {
        backgroundColor: '#fddc6c',
        width: '100%',
    },
    typography: {
        fontWeight: 'bold',
    },
    actionRoot: {
        padding: '8px 8px 8px 16px',
        justifyContent: 'space-between',
    },
    icons: {
        marginLeft: 'auto',
    },
    expand: {
        padding: '8px 8px',
        transform: 'rotate(0deg)',
        transition: theme => theme.transitions.create('transform', {
            duration: theme => theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapse: {
        padding: 16,
    },
    checkIcon: {
        fontSize: 20,
        color: '#b3b3b3',
        paddingRight: 4,
    },
    button: {
        padding: 0,
        paddingLeft: 5,
        paddingRight: 5,
        textTransform: 'none',
    },
}

const DataNotification = React.forwardRef((props, ref) => {
    const classes = notificationSx();
    const { close } = useNotifier();
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation()
    
    const handleExpandClick = useCallback(() => {
        setExpanded((oldExpanded) => !oldExpanded);
    }, []);

    const handleDismiss = useCallback(() => {
        close(props.id);
    }, [props.id, close]);

    const onDownload = () => {
      download(props.href, props.filename)
      handleDismiss()
    }

    return (
        <SnackbarContent ref={ref} sx={notificationSx.root}>
            <Card sx={notificationSx.card}>
                <CardActions sx={notificationSx.actionRoot}>
                    <Typography variant="subtitle2" sx={notificationSx.typography}>{props.message}</Typography>
                    <Box sx={notificationSx.icons}>
                        <IconButton
                          id={'snackbar-' + props.id + '-show-more-button'}
                          sx={{...notificationSx.expand, [notificationSx.expandOpen]: expanded}}
                          onClick={handleExpandClick}
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                        <IconButton 
                          id={'snackbar-' + props.id + '-close-button'}
                          sx={notificationSx.expand}
                          onClick={handleDismiss}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Paper sx={notificationSx.collapse} >
                        <Typography gutterBottom>{t('notification.fileready')}</Typography>
                        <Button 
                          id={'snackbar-' + props.id + '-download-button'} 
                          size="small" 
                          sx={notificationSx.button}
                          onClick={onDownload}
                        >
                            <CheckCircleIcon sx={notificationSx.checkIcon} />
                            {t('notification.download')}
                        </Button>
                    </Paper>
                </Collapse>
            </Card>
        </SnackbarContent>
    );
});

export default DataNotification;