import React, { SetStateAction } from 'react';

import {
    PageConfig, PaneContentProps, PaneProps, PaneSplitType, ShowAction, WindowState
} from '../types';
import { PageHelper } from './PageHelper';

type MaxPageProps = {
  trigger1: boolean
  trigger2: boolean 
  orientation: PaneSplitType
  maxVerticalPages: number,
  maxHorizontalPages: number
}

export class WindowHelper {
  public static dormantConfig: PageConfig = { state: "dormant", action: 'none', enabled: false }

  public static dormantPane: PaneProps = { type: 'content', content: <React.Fragment/>}

  public static withDormantPane(panes: PaneProps[]): PaneProps[] {
    return [...panes, H.dormantPane]
  }

  public static withDormantConfig(configs: PageConfig[]): PageConfig[] {
    return [...configs, H.dormantConfig]
  }

  public static getWindowWidth(pages: number, orientation: PaneSplitType) {
    if (orientation == 'vertical')
      return "1000px"
    else {
      switch (pages) {
        case undefined:
        case 1: return "1000px"
        default: return (pages * 650) + "px"
      }
    }
  }

  public static getMaxPages({ trigger1, trigger2, orientation, maxVerticalPages, maxHorizontalPages }: MaxPageProps) {
    if (orientation == 'horizontal') {
      switch (true) {
        case trigger2: return maxVerticalPages
        case trigger1: return 2 < maxVerticalPages ? 2 : maxVerticalPages
        default: return 1
      }
    } else {
      switch (true) {
        case trigger2: return maxHorizontalPages
        case trigger1: return 2 < maxHorizontalPages ? 2 : maxHorizontalPages
        default: return 2 < maxHorizontalPages ? 2 : maxHorizontalPages
      }
    }
  }

  public static getConfigStatus(configs: PageConfig[]) {
    if (!configs)
      return { enabled: 0, visible: 0 }

    const [enabled, visible] = configs.reduce(([pages, visible], config) => {
      if (config.state == 'dormant')
        return [pages, visible]
      else
        return [pages + (config.enabled ? 1 : 0), visible + (config.enabled && config.state == 'visible' ? 1 : 0)]
    }, [0, 0])

    return { enabled, visible }
  }
  
  public static createInitialConfigs(panes: PaneProps[], maxPages: number): PageConfig[] {
    const configs: PageConfig[] = panes.map(_ => ({ state: 'invisible', action: "none", enabled: false }))
    return H.updateConfigActions([...configs], H.updateConfigStateAndEnabled(configs, maxPages))
  }

  public static copy(configs: PageConfig[]): PageConfig[]  {
    return configs.map(config => ({...config}))
  }

  public static updateWindowState(windowState: WindowState, pages: SetStateAction<PaneProps[]>): WindowState {
    const { layout, layout: { panes: previousPages }, configs } = windowState

    const newPages = typeof pages == 'function' ? pages(previousPages) : pages
    const c1       = WindowHelper.updateConfigsStateAndEnabledOnPageChange(previousPages, newPages, configs, windowState.maxPages)
    const c2       = WindowHelper.updateConfigActions(windowState.configs, c1)

    const ws: WindowState = {
      ...windowState,
      layout: {
        ...layout,
        panes: newPages
      },
      configs: c2
    }
    return ws
  }

  private static updateConfigsStateAndEnabledOnPageChange(previousPages: PaneProps[], pages: PaneProps[], configs: PageConfig[], maxPages: number ): PageConfig[] {
    /* precondition: this function assumes that if a page is added, it is added on the right side. at most 1 at a time */
    if (Math.abs(previousPages.length - pages.length) > 1)
      window.alert("Cannot add or remove more than 1 page at a time. not implemented")

    if (previousPages.length < pages.length) {
      const extra: PageConfig[] = Array.from(
        { length: pages.length - previousPages.length },
        (): PageConfig => ({ state: 'invisible', enabled: false, action: 'none' })
      )

      const c1 = [...configs, ...extra]

      const { enabled } = WindowHelper.getConfigStatus(c1)
      const c2 = enabled < maxPages
        ? WindowHelper.updateConfigStateAndEnabled(c1, maxPages)
        : PageHelper.shiftRight(c1)

      const c3 = WindowHelper.updateConfigActions(configs, c2)

      return c3
    }
    else if (previousPages.length > pages.length) {
      const c1 = PageHelper.shiftLeft(configs)
      const c2 = c1.slice(0, pages.length)
      return c2
    }
    else return H.copy(configs)
  }
 
  
  public static updateConfigStateAndEnabled(configs: PageConfig[], maxPages: number): PageConfig[] {
    // decrease the number of pages that are enabled
    const { enabled }      = H.getConfigStatus(configs)
    const enable           = maxPages - enabled
    const rightMostEnabled = PageHelper.rightMostEnabled(configs)
    const leftMostEnabled  = PageHelper.leftMostEnabled(configs)

    if (enabled == 0) {
      // enable [[maxPages]] pages at the end 
      const from = configs.length - maxPages > 0 ? configs.length - maxPages : 0
      const to   = configs.length

      return mapRange(configs, 
        config => ({ ...config, enabled: true,  state: 'visible' }), 
        config => ({ ...config, enabled: false, state: "invisible" }),
        from, 
        to
       )
    
    } else if (enable > 0) {
      
      // enable [[enable]] number of pages
      const cf = configs.map(config => ({ ...config }))

      // determine how many potential pages are available on both sides of the currently enabled pages
      const rightAvailable = (configs.length - 1) - rightMostEnabled
      const leftAvailable  = leftMostEnabled

      // determine how many pages we want to enable on the right and left size of the currently enabled pages
      const rightEnable    = rightAvailable > enable ? rightAvailable - enable : rightAvailable
      const enableLeftover = enable - rightEnable
      const leftEnable     = leftAvailable > enableLeftover ? enableLeftover : leftAvailable

      // enable pages on the right side
      for (let i = rightMostEnabled + 1; i < rightMostEnabled + 1 + rightEnable; i++){
        cf[i].enabled = true
        cf[i].state   = 'visible'
      }

      // enable pages on the left side
      for (let i = leftMostEnabled - leftEnable; i < leftMostEnabled; i++){
        cf[i].enabled = true
        cf[i].state   = 'visible'
      }

      return cf

    } else if (enable < 0) {
      // disable -[[enable]] number of pages, but don't disable all pages
      const disable = enabled + enable > 0 ? -1 * enable : enabled - 1
      const cf = configs.map(config => ({ ...config }))

      // sum all rightmost pages that are enabled but not visible
      const rightAvailable = configs
        .slice(0, rightMostEnabled + 1)
        .reduceRight(({ sum, done }, config) => !done && config.enabled && config.state == 'invisible' ? { sum: sum + 1, done: false } : { sum, done: true }, { sum: 0, done: false }).sum

      // disable pages that aren't visible on the right side
      const rightDisable = rightAvailable > disable ? rightAvailable - disable : rightAvailable
      for (let i = rightMostEnabled; i > rightMostEnabled - rightDisable; i--){
        cf[i].enabled = false
        cf[i].state   = 'invisible'
      }

      // disable pages on the left side
      const disableLeftover = disable - rightDisable
      for (let i = leftMostEnabled; i < leftMostEnabled + disableLeftover; i++){
        cf[i].enabled = false
        cf[i].state   = 'invisible'
      }

      return cf
    }

    return configs
  }
  
  public static updateConfigActions(previous: PageConfig[], next: PageConfig[]): PageConfig[] {

    const length = previous.length < next.length ? previous.length : next.length
    const c1 = H.copy(next)
    for (let i = 0; i < length; i++) 
      c1[i].action = H.determineConfigAction(previous[i], c1[i]) 

    for (let i = length; i < next.length; i++)
      c1[i].action = H.determineConfigAction({action: 'none', enabled: false, state: 'invisible'}, c1[i]) 

    return c1
  }

  public static determineConfigAction(prev: PageConfig, next: PageConfig): ShowAction {
    switch (true) {
      case prev.enabled && !next.enabled: return 'make-invisible'
      case !prev.enabled && next.enabled: return 'make-visible'
      case next.enabled && prev.state == 'visible' && next.state == 'invisible': return 'make-invisible'
      case next.enabled && prev.state == 'invisible' && next.state == 'visible': return 'make-visible'
      default: return 'none'
    }
  }

}

function mapRange<T>(array: T[], map: (value: T) => T, mapOther: ((value: T) => T) | undefined, start: number, end?: number, ): T[] {
  const from = start >= 0 ? start : ( array.length > (-1 * start) ? array.length - (-1 * start) : 0 )
  const to   = end ? end : array.length

  if (from > to)
    return array
  else
    return array.map((value, index) => index >= from && index < to ? map(value) : mapOther ? mapOther(value) : value)
}

const H: typeof WindowHelper = WindowHelper
