import { gql } from '@apollo/client';

export const GET_START_FORM = gql`
  query GetProcessDefinition($id: ID!) {
    processDefinition(id:$id) {
      id
      key
      name
      deploymentId          
      description
      startForm(options: { maxChoices: 99 })
      traits
      hasStartForm
    }
  }`

export const START_PROCESS_WITH_FORM = gql`
  mutation StartProcessWithForm($id: ID!, $values: JSON, $files: [Upload!]) {
    startProcess(id:$id, values:$values, files: $files) { 
      __typename
      ... on ProcessInstance {
        id,
        assignedTasks { id, processDefinition { id, key, traits, hasStartForm }}
      }
      ... on InputErrors {
        errors {
          path, name, type, label, value, status, message 
        }
      }
    }
  }
`
