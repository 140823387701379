import { PublicClientApplication, LogLevel } from '@azure/msal-browser'
import { MsalProvider, useMsal }             from '@azure/msal-react'
import { setContext }                        from '@apollo/client/link/context'
import { useNavigate }                       from 'react-router'
import { env }                               from 'utils/utils'

const createMsalInstance = () => {
  if (env('AUTH_MODE') !== 'external')
    return null

  const clientId      = env('AUTH_CLIENT_ID')
  const authority     = env('AUTH_AUTHORITY')
  const redirectUri   = env('AUTH_REDIRECT_URI')
  const cacheLocation = env('AUTH_CACHE_LOCATION')

  // see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
  return new PublicClientApplication({
    auth: {
      clientId,
      authority,
      redirectUri
    },
    cache: {
      cacheLocation,                   // This configures where your cache will be stored
      storeAuthStateInCookie: false,   // Set this to 'true' if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message)
              return
            case LogLevel.Info:
              console.debug(message)
              return
            case LogLevel.Verbose:
              console.debug(message)
              return
            case LogLevel.Warning:
              console.warn(message)
              return
          }
        }
      }
    }
  })
}

const msalInstance = createMsalInstance()

export const AuthProvider = ({ children }) => (
  <MsalProvider instance={msalInstance}>
    {children}
  </MsalProvider>
)

export const tokenPromise = () => {
  if (msalInstance.getAllAccounts().length === 0) 
    return Promise.reject({ message: "No token" })

  const acquireRequest = {
    account:      msalInstance.getAllAccounts()[0],
    forceRefresh: false,
  }

  return msalInstance.acquireTokenSilent(acquireRequest)
    .then(tokenResponse => {
      console.debug("acquireTokenSilent: response=%o", tokenResponse)
      return tokenResponse.idToken
    })
}

// see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
export const authLink = setContext((request, previousContext) => {
  //console.debug("request", request, "previousContext", previousContext, "accounts", msalInstance.getAllAccounts())

  if (msalInstance.getAllAccounts().length === 0) return previousContext
  const acquireRequest = {
    account:      msalInstance.getAllAccounts()[0],
    forceRefresh: false,
  }

  return msalInstance.acquireTokenSilent(acquireRequest)
    .then(tokenResponse => {
      console.debug("acquireTokenSilent: response=%o", tokenResponse)
      const token = tokenResponse.idToken
      const authorization = token ? `Bearer ${token}` : ''
      const headers = { ...previousContext.headers, authorization }
      return { ...previousContext, headers }
    })
    .catch(e => console.error(e))
})

export const useCurrentUser = () => {
  const { accounts, inProgress } = useMsal()

  const roles    = (account) => account?.idTokenClaims?.roles || []
  const userInfo = (account) => { return { name: account.name, isAdmin: roles(account).includes('admin') } }
  const user     = accounts?.length ? userInfo(accounts[0]) : null

  //console.log("useCurrentUser: user=%o", user)
  return { user, loading: false }
}

export const useAuthNavigation = () => {
  const navigate = useNavigate()
  return {
    login: () => msalInstance.loginPopup({
      scopes: ['openid', 'profile', 'email'],
      redirectUri: '/blank.html'
    }),
    onClickLogin:  () => {
      msalInstance.loginPopup({
        scopes: ['openid', 'profile', 'email'],
        redirectUri: '/blank.html'
      })
      .then(r => console.log(r))
      .catch(e => console.error(e))
    },
    onClickLogout: () => {
      // redirect back to the login page, since the user can't do anything else while logged out
      msalInstance.logoutPopup()
        .then(() => { navigate('/logout') } )
        .catch(e => console.error(e))
    },
  }
}

export const useInternalLogin = (helpers) => {
  throw new Error('not supported by external login mode')
}
