(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory((function webpackLoadOptionalExternalModule() { try { return require("moment-timezone"); } catch(e) {} }()), (function webpackLoadOptionalExternalModule() { try { return require("moment"); } catch(e) {} }()));
	else if(typeof define === 'function' && define.amd)
		define(["moment-timezone", "moment"], factory);
	else {
		var a = typeof exports === 'object' ? factory((function webpackLoadOptionalExternalModule() { try { return require("moment-timezone"); } catch(e) {} }()), (function webpackLoadOptionalExternalModule() { try { return require("moment"); } catch(e) {} }())) : factory(root["moment-timezone"], root["moment"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__528__, __WEBPACK_EXTERNAL_MODULE__416__) => {
return 