import {useEffect, useCallback} from 'react'

type OptionalConfig = Pick<
 KeyboardEvent,
 'altKey' | 'ctrlKey' | 'shiftKey'
>

export interface ShortcutConfig extends Partial<OptionalConfig> {
 code: KeyboardEvent['code'];
 shortcutTarget?: HTMLElement;
}

export type ShortcutAction = (e: KeyboardEvent) => void;

export default function useKeyboardShortcut(
 shortcutAction: ShortcutAction,
 config: ShortcutConfig
) {
 const targetElement = config.shortcutTarget || document;

 const eventHandler = useCallback((e: KeyboardEvent) => {
  const {code, ctrlKey, altKey, shiftKey} = e;
  if(config.code !== code) return;
  if(config.ctrlKey  ? !ctrlKey  : ctrlKey)  return;
  if(config.shiftKey ? !shiftKey : shiftKey) return;
  if(config.altKey   ? !altKey   : altKey)   return;

  shortcutAction(e);
 },[shortcutAction, config])

 useEffect(()=>{
  // @ts-ignore
  targetElement.addEventListener('keydown', eventHandler)

  // @ts-ignore
  return () => targetElement.removeEventListener('keydown', eventHandler )
 }, [targetElement, eventHandler])

}

export function keyboardCodeToChar(code: KeyboardEvent['code']): string {
  switch (code) {
    case 'BracketLeft': return '['
    case 'BracketRight': return '['
    case 'Backslash': return '\\'
    default: 
     return "[?]"
  }
}

