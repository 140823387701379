import { useEffect, useState } from 'react';

import { useMediaQuery } from '@mui/material';

import { WindowHelper } from '../helpers/WindowHelper';
import { PaneLayoutProps, WindowState } from '../types';
import { useWindowManagerContext } from './useWindowManagerContext';
import { useWindowPageContext } from './useWindowPageContext';

function createInitialWindowState(initialLayout: PaneLayoutProps, maxPages: number): WindowState {
  const panes = initialLayout.panes;

  return {
    layout: initialLayout,
    configs: WindowHelper.createInitialConfigs(panes, maxPages),
    maxPages
  };
}

export const useWindowConfiguration = (initialLayout: PaneLayoutProps) => {
  // @ts-ignore
  const trigger1 = useMediaQuery(theme => theme?.breakpoints?.up('lg'));
  // @ts-ignore
  const trigger2 = useMediaQuery(theme => theme?.breakpoints?.up('xl'));

  const { maxVerticalPages, maxHorizontalPages } = useWindowManagerContext();
  const [orientation, setOrientation]            = useState(initialLayout?.split || 'horizontal');
  const maxPages                                 = WindowHelper.getMaxPages(
    {
      trigger1,
      trigger2,
      orientation,
      maxVerticalPages,
      maxHorizontalPages
    }
  );

  const [windowState, setWindowState] = useState<WindowState>(createInitialWindowState(initialLayout, maxPages));
  const { update }                    = useWindowPageContext();
  const { enabled }                   = WindowHelper.getConfigStatus(windowState.configs);
  const [width, setWidth]             = useState(WindowHelper.getWindowWidth(enabled, orientation));

  useEffect(() => {
    update(windowState, setWindowState)
  }, [windowState, setWindowState]);

  useEffect(() => {
    // update what pages are shown
    const maxPages = WindowHelper.getMaxPages({
      trigger1,
      trigger2,
      orientation,
      maxVerticalPages,
      maxHorizontalPages
    })

    const maxedConfigs = WindowHelper.updateConfigStateAndEnabled(windowState.configs, maxPages);

    // commit to new config 
    const newConfigs = WindowHelper.updateConfigActions(windowState.configs, maxedConfigs);
    setWindowState(state => ({...state, configs: newConfigs}))
    
  }, [trigger1, trigger2, orientation]);

  useEffect(() => {
   if (width != "100%") {
      const { enabled } = WindowHelper.getConfigStatus(windowState.configs)
      setWidth(WindowHelper.getWindowWidth(enabled, orientation))
    }
  }, [windowState])

  return { windowState, setWindowState, width, setWidth, orientation, setOrientation };
};
