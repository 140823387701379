import { useRenderContext } from 'hooks/render';
import { GearsFilename } from 'types/types';
import { getExtension, removeExtension } from 'utils/files';

import { Box, Typography } from '@mui/material';

export const RenderFilename = ({ value }: {value: GearsFilename}) => {
  const { props: {sx} } = useRenderContext()
  const ext             = getExtension(value.__value)

  return (
    <Box sx={{ flexShrink: 1, minWidth: 0, display: "flex", flexDirection: "row", alignItems: "center", ...sx }}>
      <Typography
        noWrap
        textOverflow={"ellipsis"}
        fontSize={14}
        overflow="hidden"
        flexShrink={1}
      >
        {removeExtension(value.__value)}
      </Typography>
      { ext 
        ? <Typography
            noWrap
            flexShrink={0}
            overflow="none"
            fontSize={14}
          >
            {`.${ext}`}
          </Typography>
        : null
      }
    </Box>
  );
};
