import {
  Tooltip,
  IconButton }                  from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

const DeleteButton = ({id, onClick, title, disabled, disabledTitle}) => {


  return (
    <Tooltip disableInteractive title={disabled ? disabledTitle ? disabledTitle : "" : title}>
      <span>
        <IconButton tabIndex={-1} id={id} size="small" onClick={onClick} disabled={Boolean(disabled)} >
          <DeleteIcon fontSize="small" /> 
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default DeleteButton