import { createContext, useState } from 'react';
import { SetState } from 'types/react';

export interface SubmitProps {
  setSubmitting: SetState<boolean>,
  submitting: boolean,
}

export interface SubmitProvidedProps {
  children: React.ReactNode,
}

export const SubmitContext = createContext<SubmitProps>({
  submitting: false,
  setSubmitting: () => { console.error("Submit context provider is not initialized") }
})

const SubmitProvider = ({children}: SubmitProvidedProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false)

  return (
    <SubmitContext.Provider value={{submitting, setSubmitting}}>
      {children}
    </SubmitContext.Provider>
  )
}

export default SubmitProvider       
