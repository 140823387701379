import { useRef }         from 'react';
import { useMutation }    from '@apollo/client';
import { Box }            from '@mui/material';
import { useNotifier }    from 'hooks/notification';
import {  READ_MESSAGE }  from 'queries/messages';
import { useEffect }      from 'react';
import MessageSubject     from 'components/message/MessageSubject';
import MessageBody        from 'components/message/MessageBody';
import MessageBanner      from 'components/message/MessageBanner';
import { bannerQueries }  from 'utils/banner-utils';

const Message = ({ message }) => {
  const messageRef = useRef(null)
  const notifier   = useNotifier()
  const [doRead]   = useMutation(READ_MESSAGE, { refetchQueries: bannerQueries })

  // mark message as read
  useEffect(() => {
    if (!message.readAt) {
      doRead({ variables: { id: message.id, read: true }})
        .then(
          success => {}, 
          error => { 
            notifier.error("unable to set message as read")
            console.error("Unable to set message as read: " + error.message)
          }
        ) 
    }
  }, [])

  return (
    <Box sx={{width: "100%", padding: "20px"}}>
      <Box sx={{display: "flex", flexDirection: "column"}}>
        <MessageBanner messageRef={messageRef} message={message} />

        <Box ref={messageRef} sx={{display: "flex", flexDirection: "column", height: "100%"}}>
          <MessageSubject subject={message.subject}/>
          <MessageBody {...message} />
        </Box>
      </Box>
    </Box>
  )
}

export default Message