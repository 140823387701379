import { env } from 'utils/utils'
import { Content } from 'helpers/content';
import Types from 'types/types';

export function viewable(contentLike: any) {
  if (env('ENABLE_VIEWER') != "true") return false;
  
  const type = Types.toContent(contentLike)?.type || "";
  switch (true) {
    case type.startsWith("image/"):
    case type == "text/html":
    case type == "application/pdf":
      return true;
    default:
      return false;
  }
}

export function previewable(contentLike: Partial<Content>) {
  const type = Types.toContent(contentLike)?.type || "";

  switch (true) {
    case type.startsWith("image/"):
      return true;
    default:
      return false;
  }
}

