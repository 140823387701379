import { Box, Link }           from '@mui/material';
import RenderValue             from 'components/render/RenderValue';
import FileTiles               from 'components/tile/FileTiles';
import { useContentOnClick } from 'hooks/content';
import { useNotifier }         from 'hooks/notification';
import { useRenderContext }    from 'hooks/render';
import { useState }            from 'react';

const RenderFiles = ({attachments}) => {
  const {context, parent} = useRenderContext()
  
  if (parent == "table" && context != "group" && context != "step")
    return <RenderFilesList attachments={attachments} />
  else 
    return <FileTiles attachments={attachments} />
}

const RenderFilesList = ({attachments}) => {
  return (
    <Box sx={{dsplay: "flex", flexDirection: "row"}} >
      {attachments.map((attachment, index) => <RenderFilesListRow key={index} attachment={attachment}/>)}
    </Box>
  )
}

const RenderFilesListRow = ({attachment}) => {
  const contentType                = attachment?.__typename == "Document" ? "document" : 'content'
  const {downloadOnClick, loading} = useContentOnClick({id: attachment.__id, filename: attachment.filename, mimeType: attachment.contentType, type: contentType})
  const [cursor, setCursor]        = useState('pointer')
  const notifier                   = useNotifier()

  const handleOnClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (loading)
      notifier.info("We are currently processing your download request")
    else {
      setCursor('progress')
      downloadOnClick(e)
        .finally(() => setCursor("pointer"))
    }
  }

  return (
    <Box>
      <Link onClick={handleOnClick} style={{cursor: cursor}} >
        <RenderValue value={{__kind: 'filename', __value: attachment.filename}} sx={{maxWidth: "250px"}}/>
      </Link>
    </Box>
  )
}

export default RenderFiles