import { createContext } from 'react';
import { isFunction }    from 'lodash'

export const MenuItemContext = createContext();

const MenuItemProvider = ({children, menu}) => {
  const handleMenu = isFunction(menu) ? menu : () => menu

  return (
    <MenuItemContext.Provider value={handleMenu}>
      {children}
    </MenuItemContext.Provider>
  )
}
 
export default MenuItemProvider;