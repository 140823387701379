import { Stack }   from '@mui/material'
import { Center }  from 'components/common/Center'
import Fallback    from 'components/common/Fallback'
import SectionView from 'components/view/SectionView'
import { Schema, Section } from 'types/view'

interface DetailViewProps {
  schema: Schema
  sections: string[]
}

/** Render a detail view, containing group and list sections. */
function DetailView({ schema, sections }: DetailViewProps) {
  const translationBaseKey = `views.details.${schema.key}`
  const augmentedSchema    = { ...schema, kind: "detail", translationBaseKey }
  const isSectionEnabled = (section: Section) => sections.includes(section.key)

  return (
    <Fallback name='detail'>
      <Stack className='details-stack' sx={{ width: '100%', maxWidth: '100%', boxSizing: 'content-box' }} spacing={3}>
        {schema.sections.filter(isSectionEnabled).map((section, index) =>
          <Center maxHeight={`calc(100vh - 175px)`} maxWidth='100%' key={index} direction="horizontal">
            <SectionView schema={augmentedSchema} section={section} />
          </Center>
        )}
      </Stack>
    </Fallback>
  )
}

export default DetailView
