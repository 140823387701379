import { basedOnEntries }                      from 'components/form/utils/form-utils'
import { RenderObjectTable, RenderArrayTable } from 'components/render'
import { useNotifier }                         from 'hooks/notification'
import Types                                   from 'types/types'

export const RenderStructureValue = ({ value }: { value: any }) => {
  const notifier = useNotifier()

  if (Array.isArray(value))
    return <RenderArrayTable array={value} />

  if (Types.isObject(value)) {
    if (basedOnEntries(value).length == 0)
      return null;

    else
      return <RenderObjectTable object={value} />
  }

  notifier.error("No render method implemented for structure value")
  console.error("No render method for structure value: %o", value)
  return null
}
