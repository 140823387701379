import { Stack, Box }    from '@mui/material';
import InputField        from 'components/form/fields/InputField';
import { partitionType } from 'utils/type-utils';

const StepCardFields = ({partition,}) => {
  function toField(field, index) {
    return (
      <Box key={'field' + index}>
        <InputField
          field={field}
          indices={{}}
        />
      </Box>
    )
  }

  return (
    <Stack spacing={0}>
      {partition.fields.map(toField)}
		</Stack>
	)
}

StepCardFields.propTypes = {
	partition : partitionType,
}

export default StepCardFields
