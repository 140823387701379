import { darken, useTheme } from "@mui/material";
import { SidebarContext } from "contexts/SidebarContext";
import { useContext }     from "react";
import { useLocation, useNavigate, useParams }      from 'react-router';
import { isUrl } from "utils/utils";

export const useSidebarContext = () => useContext(SidebarContext)

export const useSidebarItemOnClick = (item) => {
  const navigate = useNavigate()
  const { handleMenuClose } = useSidebarContext()
  const withMenuClose = (onClick) => {
    return (e) => {onClick?.(e); handleMenuClose()}
  }

  if (item.onClick) {
    return withMenuClose(item.onClick)
  } else if (item.href) {
    return withMenuClose(
      (e) => {
        e.preventDefault()
        e.stopPropagation()
        const external = item.key.startsWith('external.') // was: isUrl(item.href)
        if (external)
          window.open(item.href, '_blank');
        else
          navigate(item.href)
      }
    )
      
  } else {
    return (e) => {}
  }
}

export const useSidebarChildItemActive = (item) => {
  const params   = useParams()
  const location = useLocation();

  const getChildActive = (item) => {
    const activeAlt = item.processKey ? item.processKey === params.processKey : false
    const active    = activeAlt || location.pathname.endsWith(item.href)
    return active || getChildrenActive(item?.children)
  }

  const getChildrenActive = (children) => {
    if (Array.isArray(children)){
      return children.some(getChildActive)
    } else {
      return false
    }
  }

  return getChildrenActive(item?.children)
}

export const useSidebarItemActive = (item) => {
  const params    = useParams()
  const location  = useLocation();

  const activeAlt = item.processKey ? item.processKey === params.processKey : false
  const active    = activeAlt || location.pathname.endsWith(item.href)

  return active
}

export const useSidebarItemSX = (item) => {
  const context = useSidebarContext()
  const active  = useSidebarItemActive(item)
  const theme   = useTheme()

  return {
    itemSx: {
      ...SidebarItemSx.item,
      //background: active ? (theme) => theme.palette.grey['200'] : undefined,
      background: active ? theme.palette.background.default : undefined,
      boxShadow: active ? "0px 4px 10px 6px rgba(0,0,0, 0.1);" : undefined,
      marginRight: "-1px", // fixes wierd issue with shadows not being pixel perfect
      fontSize  : context.level > 0 ? "700rem" : SidebarItemSx.item.fontSize,
      "&:hover": {
        background: darken(theme.palette.background.default, 0.1)
      }
    },
    textSx: {
      ...SidebarItemSx.itemText,
      fontWeight: context.level == 0 ? 700 : 400,
      color     : active ? 'primary.main' : SidebarItemSx.itemText.color
    }
  }
}

export const SidebarItemSx = {
  iconContainer: {
    minWidth: "36px"
  },
  groupIconContainer: {
    minWidth: "36px"
  },
  icon: {
    size: "20px",
  },
  primary: {
    color: "#1A2027"
  },
  secondary: {
    color: "#3E5060"
  },
  item: {
    color: 'text.secondary',
  },
  itemText: {
    fontSize: "0.875rem",
    lineHeight: "1.2",
    letterSpacing: "0.00938em",
    fontFamily: 'Roboto,"IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 0,
  },
  group: {
    color: "#3E5060",
    textTransform: 'uppercase',
    fontSize: "0.75rem",
    lineHeight: "1.5",
    letterSpacing: "0.08rem",
    fontFamily: 'Roboto,"IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 900,
  }
}

