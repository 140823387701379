import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import { SubmitButton } from 'components/form/task/SubmitButton';
import { useConfig } from 'hooks/config';
import { useFormInfo } from 'hooks/form';
import { useSubmitting } from 'hooks/submitting';

const TaskSubmit = () => {
  const { submitting }           = useSubmitting()
  const formik                   = useFormikContext()
  const {props: {taskRendering}} = useConfig()
  const formInfo                 = useFormInfo()

  if (formInfo.hasSubmit) 
    return <Box sx={{width: "100%", display: "flex", flexGrow: 0, paddingTop: taskRendering == 'window' ? "20px" : undefined }}/>

  return (
    <Box sx={{ 
      paddingY: taskRendering == 'window' ? "20px" : undefined, 
      paddingTop: taskRendering == 'standard' ? "10px" : undefined,
      display: "flex", 
      flexGrow: 0, 
      width: "100%"
    }}>
      <SubmitButton 
        sx={{}} 
        loading={submitting} 
        onClick={formik.submitForm} 
        disableIcon={false} 
      />
    </Box>
  )
}

export default TaskSubmit
