import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import TileInfoDialog from './TitleInfoDialog';

const TileInfoButton = ({title, description}: {title: string, description: string}) => {
  const theme           = useTheme()
  const [open, setOpen] = useState(false);
  const {t}             = useTranslation()

  const handleOpen = (e: React.SyntheticEvent)=> {
    e.stopPropagation()
    e.preventDefault()
    setOpen(true)
  }

  return (
    <>
      <Tooltip disableInteractive title={t('processes.info')}>
          <Box className='process-info-button' sx={{
            height: "19px",
            width: "19px", 
            borderRadius: "50%", 
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            color: theme.palette.grey[600],
            "&:hover": { 
              backgroundColor: theme.palette.grey[300], 
              color: theme.palette.grey[700] 
            } 
            }} 
            onClick={handleOpen}
          >
            <InfoIcon sx={{fontSize:17}}/>
        </Box>
      </Tooltip>
      <TileInfoDialog title={title} description={description} open={open} setOpen={setOpen}/>
    </>
  )
}

export default TileInfoButton