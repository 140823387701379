import { Typography, Box }            from '@mui/material';
import { MultipleTableHeaderActions } from 'components/form/fields/multiple';

const MultipleHeaderTableCellContents = ({ id, rows, column, features, selected, setSelected }) => {
  const columnTitle = (
    <Typography variant="body2">
      {column.name}{column.required ? "*" : ""}
    </Typography>
  )

  if (features.select && id === 0 && selected.length) {
    return (
      <Box sx={{display: "flex", flexFlow: 'row'}} >
        <MultipleTableHeaderActions
          id={id}
          rows={rows}
          features={features}
          selected={selected}
          setSelected={setSelected}
        />
        <Box sx={{flexGrow: 1, display: "flex", alignItems: "center"}}>
          {columnTitle}
        </Box>
      </Box>
    )
  } else {
    return columnTitle
  }
}

export default MultipleHeaderTableCellContents
