import { useTranslation }             from 'react-i18next';
import React, { useEffect, useRef, useState }        from 'react';
import { Box, Divider }               from '@mui/material'
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox  }                         from "@mui/material";
import ChevronLeftIcon                from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon               from '@mui/icons-material/ChevronRight';
import { Tooltip }                    from '@mui/material';
import IconButton                     from '@mui/material/IconButton';
import { svelteDependenciesPerOrder } from 'components/gantt/mock/schedule';
import { createWindowConfig }                 from 'components/gantt/common';
import { 
  FastForward as FastForwardIcon,
  FastRewind as FastRewindIcon, 
  PlayArrow as PlayArrowIcon,
  Add as AddCircleIcon,
  Remove as RemoveCircleIcon,
  SkipNext as SkipNextIcon,
  SkipPrevious as SkipPreviousIcon,
  Download as DownloadIcon,
  Print as PrintIcon }  from '@mui/icons-material'
import { useReactToPrint }     from 'react-to-print'
import { mformat, setToStartOfNextWeek, setToStartOfWeek } from './utils/date';
import moment from 'moment'
import { useNotifier } from 'hooks/notification';
import html2canvas from 'html2canvas'
import {download} from 'utils/download'

const GanttDrawer = ({config}) => {
  return (
    <Box sx={{display: "flex", width: "100%", flexDirection: "column", paddingTop: "10px"}}>
      <Exporter/>
      
      <Box sx={{padding: "10px", width: "100%"}}>
        <Settings config={config}/>
      </Box>
    </Box>
  )
}

const Exporter = () => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const imageRef = useRef(null)
  const content = () => imageRef.current
  function printImage(src) {
    var win = window.open('about:blank', "_new");
    win.document.open();
    win.document.write([
        '<html>',
        '   <head>',
        '   </head>',
        '   <body onload="window.print()" onafterprint="window.close()">',
        '       <img src="' + src + '"/>',
        '   </body>',
        '</html>'
    ].join(''));
    win.document.close();
}
  function printImage2(src)
        {
            const printWindow = window.open ("", "mywindow", "location=1,status=1,scrollbars=1,width=595,height=842");
            printWindow.document.write("<div style='width:;transform: rotate(90deg); '>");
            printWindow.document.write("<img id='img' style='width: 100%; max-width:100%;max-height:100%;' src='" + src + "' />");
            printWindow.document.write("</div>");
            printWindow.document.close();
            printWindow.print();
        }

  const createCanvas = () => {
    setLoading(true)
    return html2canvas(document.querySelector("#example-gantt"))
      .finally(() => setLoading(false))
  }

  const handleDownload = () => {
    createCanvas()
      .then(canvas => {
        var url        = canvas.toDataURL("image/png");
        const stamp    = moment().format("L LTS").replace(/[.:,-\s]+/g,'_')
        const filename = `schedule_${stamp}` 
        download(url, filename)
      })
  }
  
  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handleDownload(e)
  }


  const onPrintClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    createCanvas()
      .then(canvas => {

        var url        = canvas.toDataURL("image/png");
        printImage2(url)
      } 
    )

  }

  return (
    <Box sx={{display: "flex", flexDirection:"row", paddingLeft: "20px", paddingRight: "20px"}} >
      <Box sx={{flexGrow: 1}}/>
      <Box>
        <Tooltip disableInteractive title={t("gantt.download")}>
          <span>
            <IconButton tabIndex={-1} size="small" onClick={onClick}  >
              <DownloadIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip disableInteractive title={t("gantt.print")}>
          <span>
            <IconButton tabIndex={-1} size="small" onClick={onPrintClick}  >
              <PrintIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  )

}
const DrawerOpener = ({open, setOpen}) => {
  const { t } = useTranslation()
  const handleDrawerSwitch = () => {
    setOpen(open => !open)
  };

  return (
    <Box 
      sx={{position: "relative", width: "40px", 
        left: open ? "0px" : "-40px", 
        borderTopLeftRadius: "10px", 
        borderBottomLeftRadius: "10px", 
        top: "5px", 
        zIndex:3, 
        transitionDelay: "0.2", 
        transition: "left 0.5s 0.3s ease-in-out", 
        background: theme => theme.palette.background.default ,
        borderTop:    !open ? "1px solid": 0,
        borderLeft:   !open ? "1px solid": 0,
        borderBottom: !open ? "1px solid": 0,
        borderColor: theme => theme.palette.grey[400],
      }}
    >
      <Tooltip disableInteractive title={open ? t('close') : t('open')} delay={1500}> 
        <IconButton onClick={handleDrawerSwitch}>
          {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </Tooltip>
    </Box>
  )
}

const Settings = ({config}) => {

  return (
    <>
      <Box sx={{display: "flex", flexDirection:"column"}}>
        {/*<ViewField config={config} setConfig={setConfig}/> */}
        <WeekNavigation config={config} />
        <WeekDurations config={config} />
        <Divider sx={{marginTop: "10px"}}/>

        <Properties config={config} />
      </Box>
    </>
  )
}

const WeekNavigation = ({config}) => {
  const { t } = useTranslation()
  const navigateWeek = (nr) => {
    const from = moment(config.current.gantt.utils.from)
    const to   = moment(config.current.gantt.utils.to)

    if (nr > 0) {
      from.add(nr,"weeks")
      to.add(nr,"weeks")
    } else if (nr < 0) {
      from.subtract(-1*nr,"weeks")
      to.subtract(-1*nr,"weeks")
    }

    config.current.gantt.$set({from: from, to: to, ...createWindowConfig(from, to)})
  }

  return (
    <FormControl component="fieldset" sx={{marginBottom: "10px"}}>
      <Box sx={{display: "flex", }}>
      <FormLabel sx={{width: "auto", marginLeft: "auto", marginRight: "auto"}} component="legend">{t("gantt.navigation")}</FormLabel>
      </Box>
      <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
        <Tooltip disableInteractive title={t("gantt.backnweeks", {nr: 4})} delay={1500}> 
          <span>
            <IconButton onClick={() => navigateWeek(-4)}>
              <FastRewindIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip disableInteractive title={t("gantt.previousweek")} delay={1500}> 
          <span>
            <IconButton onClick={() => navigateWeek(-1)}>
              <PlayArrowIcon style={{transform:"scaleX(-1)"}}/>
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip disableInteractive title={t("gantt.nextweek")} delay={1500}> 
          <span>
            <IconButton onClick={() => navigateWeek(1)}>
              <PlayArrowIcon/>
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip disableInteractive title={t("gantt.forwardnweeks", {nr: 4})} delay={1500}> 
          <span>
            <IconButton onClick={() => navigateWeek(4)}>
              <FastForwardIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </FormControl>
  )
}

const WeekDurations = ({config}) => {
  const { t } = useTranslation()


  const modifyWeekDuration = (nr) => {
    const gantt = config.current.gantt
    const from  = moment(gantt.utils.from)
    const to    = moment(gantt.utils.to)

    // account for from and to date landing within the same week
    if (to.diff(from, "weeks") < 1) {
      setToStartOfWeek(from)
      setToStartOfNextWeek(to)
      nr = nr > 0 ? nr-1 : nr+1
    }

    if (!setToStartOfWeek(from.clone()).isSame(from)) 
      setToStartOfWeek(from)

    if (setToStartOfWeek(to.clone()).isBefore(to)) 
      setToStartOfNextWeek(to)

    if (nr > 0) 
      to.add(nr, "weeks")
    else if (nr < 0) {
      const weekDiff = to.diff(from, "weeks")
      const subtract = weekDiff > (-1*nr) ? (-1*nr) : (Math.max(0,weekDiff-1) || 0) 
      to.subtract(subtract, "weeks")
    }

    gantt.$set({from, to, ...createWindowConfig(from,to)})
  }
  
  return (
    <FormControl component="fieldset">
      <FormLabel sx={{width: "auto", marginLeft: "auto", marginRight: "auto"}} component="legend">{t("gantt.duration")}</FormLabel>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <Tooltip disableInteractive title={t("gantt.removenweeks", {nr: 4})} delay={1500}>
          <span>
            <IconButton onClick={() => modifyWeekDuration(-4)}>
              <FastRewindIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip disableInteractive title={t("gantt.removeweek")} delay={1500}>
          <span>
            <IconButton onClick={() => modifyWeekDuration(-1)}>
              <RemoveCircleIcon style={{ transform: "scaleX(-1)" }} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip disableInteractive title={t('gantt.addweek')} delay={1500}>
          <span>
            <IconButton onClick={() => modifyWeekDuration(1)}>
              <AddCircleIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip disableInteractive title={t("gantt.addnweeks", {nr: 4})} delay={1500}>
          <span>
            <IconButton onClick={() => modifyWeekDuration(4)}>
              <FastForwardIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </FormControl>
  )
}

const PrintGanttButton = () => {

  const content = () => {
    const components = document
      .querySelector('#example-gantt')
    return components
  }


  const handlePrint = useReactToPrint({content, pageStyle:"@page { size: auto; margin: 15mm; } }"})

  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handlePrint(e)
  }

  return (
    <Tooltip disableInteractive title={"Print schedule"}>
      <span>
        <IconButton tabIndex={-1} size="small" onClick={onClick}  >
          <PrintIcon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const Properties = ({config}) => {
  const notifier = useNotifier()
  const { t } = useTranslation()
  const [selected, setSelected] = useState(config.current.view != "resource")
  const [reflect, setReflect] = useState(true)

  const handleChange = () => {
    const gantt       = config.current.gantt
    if (selected) {
      // FIXME: the following does not work, due to a bug in the gantt library
      // config.current.setView("task")
      // gantt.$set({rows: config.current.rows, tasks: config.current.tasks})
      notifier.warning("Refresh the page to go back to resource view")
    } else {
      config.current.setView("task")
      if (!reflect)
        handleReflectChange()

      gantt.$set({rows: config.current.rows, tasks: config.current.tasks})

      setSelected(!selected)
    }

  }

  const modifyReflect = (reflect) => {
    const gantt = config.current.gantt
    const tasks = config.current.ganttGet('tasks')
    gantt.$set({reflectOnParentRows: !reflect, tasks: tasks})
  }

  const handleReflectChange = () => {
    setReflect(reflect => {modifyReflect(reflect); return !reflect})
  }

  return (
    <Box sx={{marginTop: "20px"}}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{t("gantt.settings")}</FormLabel>
        <FormGroup>
          <FormControlLabel
            onClick={() => handleChange()}
            value={selected}
            label={t("gantt.showbyorder")}
            control={<Checkbox checked={selected}/>}
          />
          <FormControlLabel
            onClick={() => handleReflectChange()}
            value={selected}
            label={t("gantt.reflection")}
            control={<Checkbox checked={reflect}/>}
          />
        </FormGroup>
      </FormControl>
    </Box>
  )
}

const DependencyShower = ({gantt}) => {
  const deps  = svelteDependenciesPerOrder
  const { t } = useTranslation()

  const createInitialState = () => {
    const taskIds = Object.keys(deps)
    const entries = taskIds.map(id => [id, false])
    return Object.fromEntries(entries)
  }

  const [selected, setSelected] = useState(createInitialState)

  const createDependencies = (selected) => {
    return Object
      .entries(selected)
      .filter(([_, select]) => select)
      .flatMap(([orderId, _]) => deps[orderId])
  }

  const handleChange = (orderId) => {
    const newSelected  = {...selected, [orderId]: !!!selected[orderId]}
    const dependencies = createDependencies(newSelected)
    gantt.$set({dependencies})
    setSelected(newSelected)

  }

  const Options = () => {
    return Object
      .entries(selected)
      .map(([orderId, checked], index) => 
       <FormControlLabel
        onClick={() => handleChange(orderId)}
        key={index}
        value={checked}
        label={orderId}
        control={<Checkbox checked={checked}/>}
      />
    )
  }

  return (
    <Box sx={{marginTop: "20px"}}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{`Order ${t("dependencies").toLowerCase()}`}</FormLabel>
        <FormGroup>
          <Options/>
        </FormGroup>
      </FormControl>
    </Box>
  )
}

export default GanttDrawer