import React from 'react';

/**
 * This component allows you to write both:
 *   1) <LambdaComponent props={value}> { (props) => ..... } </LambdaComponent>
 *   2) <LambdaComponent props={value}> { func } </LambdaComponent>, where func is a function taking props as argument
 *   3) <LambdaComponent props={value}> <Foo/>   </LambdaComponent>, where 'props' is available inside Foo
 */

type LambdaProps = {
  props: any,
  children: React.ReactNode,
  level?: number,
};

const LambdaComponent = ({props, children, level = 0}: LambdaProps): JSX.Element => {
	if (Array.isArray(children)) {
		if (level <= 1) // only inject one level deep
	    return (
        <React.Fragment>
          { children
              .map((child, index) => 
                <LambdaComponent key={index} props={props} level={level + 1}>{child}</LambdaComponent>
              )
          }
        </React.Fragment>
      )
		else 
      return (
        <React.Fragment>
          {children}
        </React.Fragment>
      )
	}
	else if (React.isValidElement(children))
		return React.cloneElement(children, props) 
	else if (typeof children === 'function') 
  // @ts-ignore
		return React.createElement(children, props) // component as children	
	else {
		console.error("Lambda child is not a function nor a react component: %o (is it a syntax error?)", children)
		return <React.Fragment/>
	}
}

export default LambdaComponent;