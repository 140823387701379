import { Box } from '@mui/material';

import { WindowProvider } from '../contexts/WindowContext';
import { WindowHelper } from '../helpers/WindowHelper';
import { useWindowConfiguration } from '../hooks/useWindowConfiguration';
import { WindowComponentProps } from '../types';
import { PaneLayout } from './Pane';

const Window = ({ focus, layout: initialLayout, index }: WindowComponentProps): JSX.Element => {
  const { windowState, setWindowState, width, setWidth, orientation, setOrientation } = useWindowConfiguration(initialLayout);
  console.log("windowState: %o", windowState)

  return (
    <Box
      id={`window-${index}`}
      sx={{
        display: "flex",
        overflow: "auto",
        flexGrow: 1,
        justifyContent: "center",
        maxWidth: width,
        transition: "max-width 0.3s ease",
        marginLeft: "auto",
        marginRight: "auto"
      }}
    >
      <WindowProvider
        orientation={orientation}
        setOrientation={setOrientation}
        windowState={windowState}
        setWindowState={setWindowState}
        width={width}
        setWidth={setWidth}
      >
        <PaneLayout 
          {...windowState.layout} 
          panes={WindowHelper.withDormantPane(windowState.layout.panes)} 
          root={true} 
        />
      </WindowProvider>
    </Box>
  );
};

export default Window