import { Helmet } from 'react-helmet';
import PageContainer from 'components/common/PageContainer';
import { useTranslation } from 'react-i18next';
import DeploymentTable from 'components/tables/DeploymentsTable';
import { useConfig } from 'hooks/config';

const Deployments = () => {
  const { t }       = useTranslation()
  const { project } = useConfig()

  return (
    <>
      <Helmet>
        <title>{t('menu.deployments')} | {project}</title>
      </Helmet>
      <PageContainer>
        <DeploymentTable />
      </PageContainer>
    </>
  );
}

export default Deployments
