import { T, TranslateFunction } from 'helpers/translator';
import { useFormInfo } from 'hooks/form';
import { useTranslator } from 'hooks/translator';
import { GET_TRANSLATIONS } from 'queries/translation';
import i18n from 'utils/i18n';

import { useQuery } from '@apollo/client';

export const useGearsTranslation = () => {
  const formInfo          = useFormInfo()
  const { t, translator } = useTranslator()

  const tn: TranslateFunction = (key, options) => { 
    const keys          = T.toKeys(key) 
    const fallback      = keys.length > 0 ? keys[0] : undefined
    const processKey    = formInfo.processDefinition.key
    const translateKeys = keys.flatMap(key => processKey 
      ? T.toLabelKeys(processKey, key) 
      : [`common.labels.${key}`]
    )

    return t(translateKeys, { fallback: fallback, ...options, user: true })
  }

  return { t, tn, translator }
}

export const useTranslations = () => {
  const translationsResult = useQuery(GET_TRANSLATIONS)

  if (!translationsResult.loading) {
    const translations        = translationsResult?.data?.translations || {}
    const defaultTranslations = translations?.default

    console.log("Adding translations: %o", translations)

    Object
      .entries(translations)
      .forEach(([language, translations]) => {
        if (language != 'default') {
          // add translations to specific language
          i18n.addResourceBundle(language, T.appDomain, translations, true, true)

          // add default translations to that language
          if (defaultTranslations)
            i18n.addResourceBundle(language, T.defaultDomain, defaultTranslations, true, true)
        }
      })
  } 

  return { loading: translationsResult.loading }
}