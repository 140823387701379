import { Helmet }                          from 'react-helmet';
import ActiveProcessesTable                from 'components/tables/ActiveProcessesTable';
import PageContainer                       from 'components/common/PageContainer';
import { useTranslation }                  from 'react-i18next';
import { useConfig }                       from 'hooks/config';

const AllActiveProcesses = () => {
  const { t }       = useTranslation()
  const { project } = useConfig()

  return (
    <>
      <Helmet>
        <title>{t('menu.instances.all')} | {project}</title>
      </Helmet>
      <PageContainer>
        <ActiveProcessesTable mode="all"/>
      </PageContainer>
    </>
  );
}

export default AllActiveProcesses
