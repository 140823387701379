import moment from "moment";
import _ from 'lodash'

export const YEARS   = "years"
export const MONTHS  = "months"
export const WEEKS   = "weeks"
export const DAYS    = "days"
export const HOURS   = "hours"
export const MINUTES = "minutes"
export const SECONDS = "seconds"
export const MILLIS  = "milliseconds"

// https://www.twilio.com/blog/parse-iso8601-duration-javascript
export const durationFormat = {
  preamble  : "P",
  delimiter : "T",
  [YEARS]   : "Y",
  [MONTHS]  : "M",
  [WEEKS]   : "W",
  [DAYS]    : "D",
  [HOURS]   : "H",
  [MINUTES] : "M",
  [SECONDS] : "S"
}

function isNumber(value) { return _.isNumber(value) && !Number.isNaN(value) }

export function isoToPeriod (iso, asInt) {
  if (iso) {
    const m = moment(iso)
    if (m.isValid) {
      const [periodPart, durationPart] = iso.split("T")

      // parse the duration
      const hours = /(\d+)H/.exec(durationPart)?.[1]
      const minutes = /(\d+)M/.exec(durationPart)?.[1]
      const s = /([\.\d]+)S/.exec(durationPart)
      const [seconds, millis] = s ? s[1].split('.') : []

      // parse the period
      const days = /(\d+)D/.exec(periodPart)?.[1]
      const weeks = /(\d+)W/.exec(periodPart)?.[1]
      const months = /(\d+)M/.exec(periodPart)?.[1]
      const years = /(\d+)Y/.exec(periodPart)?.[1]

      // create the period object
      const toInt = (value) => value ? parseInt(value) : undefined
      const removeEmptyAttributes = (obj) => 
        Object.fromEntries(
          Object.entries(obj)
            .filter(([_, v]) => v != undefined && v != null)
        )  
      
      const period = {
        [YEARS]: toInt(years),
        [MONTHS]: toInt(months),
        [WEEKS]: toInt(weeks),
        [DAYS]: toInt(days),
        [HOURS]: toInt(hours),
        [MINUTES]: toInt(minutes),
        [SECONDS]: toInt(seconds),
        [MILLIS]: toInt(millis)
      }

      return removeEmptyAttributes(period)
    } else {
      console.error("Could not parse %o as ISO period duration", iso)
      return null
    }
  } else {
    return null
  }
}

// input: {an object with PeriodDuration attributes, where each value is a number, absent or null}; output: iso8601
export function periodToIso(period) {
  const getUnitDuration   = (unit) => isNumber(period[unit]) ? `${period[unit]}${durationFormat[unit]}` : ""
  const getSecondDuration = () => {
    var total = null
    if (isNumber(period[MILLIS]))
      total = period[MILLIS] / 1000

    if (isNumber(period[SECONDS]))
      total = total == null ? period[SECONDS] : total + period[SECONDS]

    return total == null ? "" : `${total}${durationFormat[SECONDS]}`
  }

  const timeDuration = `${getUnitDuration(HOURS)}${getUnitDuration(MINUTES)}${getSecondDuration()}`
  const dateDuration = `${getUnitDuration(YEARS)}${getUnitDuration(MONTHS)}${getUnitDuration(WEEKS)}${getUnitDuration(DAYS)}`
  
  if (timeDuration.length > 0)
    return `P${dateDuration}T${timeDuration}`
  else if (dateDuration.length > 0)
    return `P${dateDuration}`
  else
    return null
}


