import { useSidebarContext } from 'hooks/sidebar';
import { Function0 } from 'lodash';
import React, { createContext } from 'react';

type SidebarType = "sidebar" | "menu" | "overlay"

interface SidebarContextProps {
  level: number,
  handleMenuClose: Function0<void>,
  sidebarType: SidebarType,
  filter?: string
}

interface SidebarProviderProps extends SidebarContextProps {
  children: React.ReactElement,
  addLevel: boolean,
  reset?: boolean
}

interface PartialSidebarProviderProps extends Partial<SidebarProviderProps> {}

export const defaultContext: SidebarContextProps = {level: 0, handleMenuClose: () => {}, sidebarType: "sidebar"}
export const SidebarContext = createContext<SidebarContextProps>(defaultContext);

const SidebarContextProvider = ({children, addLevel, reset, ...context}: PartialSidebarProviderProps)  => {
  const ctx             = useSidebarContext()  
  const currentLevel    = context?.level != undefined ? context.level : (ctx?.level != undefined ? ctx.level : undefined)
  const level           = currentLevel != undefined ? (addLevel == true ? currentLevel + 1 : currentLevel) : 0
  const handleMenuClose = () => { ctx.handleMenuClose(); context.handleMenuClose?.(); }

  const value = reset 
    ? defaultContext
    : {
      ...ctx, 
      ...context, 
      level, 
      handleMenuClose,
      reset: false
    }

  return (
    <SidebarContext.Provider value={value}>
      {children}
    </SidebarContext.Provider>
  )
 
}

export default SidebarContextProvider;
