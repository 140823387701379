import { Helmet }         from 'react-helmet';
import TasksTable         from 'components/tables/TasksTable';
import PageContainer      from 'components/common/PageContainer';
import { useTranslation } from 'react-i18next';
import { useConfig }      from 'hooks/config';

const Tasks = ({kind}) => {
  const { t }       = useTranslation()
  const { project } = useConfig()

  return (
    <>
      <Helmet>
        <title>{t(toPageKey(kind))} | {project}</title>
      </Helmet>
      <PageContainer>
        <TasksTable kind={kind} />
      </PageContainer>
    </>
);
}

function toPageKey(kind) {
  switch (kind) {
    case "all":           return 'menu.tasks.all'
    case "transferable":  return 'menu.tasks.transferable'
    default: 
      return "???"
  }

}
export default Tasks ;
