import { Table, TableContainer } from '@mui/material'
import TableHeader from 'components/table/TableHeader'
import TableRows from 'components/table/TableRows'
import { forwardRef } from 'react'

const BasicTable = forwardRef(({columns, rows, loading, tableSx}, ref)  => (
  <TableContainer ref={ref} className='list-table-container'
    sx={{
      maxHeight: '100%',
      height: 'fit-content',
      width: 'auto',
      maxWidth: '100%',
      overflow: 'auto',
      flexGrow: 1
    }}
  >
    <Table size="small" stickyHeader
      sx={{
        width: '1px',
        whiteSpace: 'nowrap',
        minWidth: '500px',
        ...tableSx
      }}
    >
      <TableHeader columns={columns}/>
      <TableRows columns={columns} rows={rows} loading={loading}/>
    </Table>
  </TableContainer>
))

export default BasicTable