import { getRegionAndLanguage, toDisplayLanguage } from "utils/language"
import { env }                                     from 'utils/utils'

export const frontendLocales = [ 'en_US', 'nl_NL' ]

export const userEnabledLanguages = env('LANGS_ENABLED') || ''
export const userEnabledLocales   = userEnabledLanguages
  .split(',')
  .map(lang => lang.toLowerCase().trim())
  .flatMap(lang => frontendLocales.filter(locale => getRegionAndLanguage(locale).language == lang))

export const enabledLocales       = userEnabledLocales.length ? userEnabledLocales : [ frontendLocales[0] ]
export const enabledLocaleOptions = enabledLocales
  .map(locale => { return { 'value': locale, 'label': toDisplayLanguage(locale) } } )

export const defaultLocale        = enabledLocales[0]

console.debug('userEnabledLanguages=%s, userEnabledLocales=%s, defaultLocale=%s', userEnabledLanguages, userEnabledLocales, defaultLocale)
