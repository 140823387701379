import { toDisplayValue } from 'components/render/utils/render';
import { FormInfo, getTaskDocumentation } from 'contexts/FormInfoContext';
import GearsTranslator from 'helpers/translator';
import { Field, ProcessDefinition, Task } from 'types/graphql';
import Types from 'types/types';
import { createTaskDescription } from 'utils/descriptions';

export const basedOnEntries = (basedOn: any): [string, any][] => {
	return Object.entries(Types.asObject(basedOn))
		.filter(([key, _]) => !key.startsWith('__'))
}

export const basedOnKeys = (basedOn: any): string[] => {
  return Object.keys(Types.asObject(basedOn))
    .filter(key => !key.startsWith('__'))
}

export function getInputLabel(translator: GearsTranslator, field: Field, processKey: string) {
  const key      = field.baseName || field.name
  const fallback = field.label || key.replace(/[_,]/g, " ")

  return translator.toLabel(processKey, field.baseName || field.name, fallback )
}

export function findField(object: any, key: string) {
  var value;
  const resolve = function(k: string) {
      if (k === key && object['type'] && object['type'] !== 'MULTIPLE') {
          value = object[k];
          return true;
      }
      if (Types.isObject(object[k])) {
          value = findField(object[k], key);
          return value !== undefined;
      }
  }
  Object.keys(Types.asObject(object)).some(resolve);
  return value;
}

type HasProcessDefinition = {
  processDefinition: ProcessDefinition
}

export function getProcessName(translator: GearsTranslator, pd: HasProcessDefinition) {
  return translator.toProcessTitle(pd?.processDefinition?.key as string, pd?.processDefinition?.name )
}

export function getTaskCreatedAt(task: Task) {
  if (!(task && task.createdAt))
    return ""

  return toDisplayValue(task.createdAt)
}

export function getSubject(obj: any) {
  if (obj && obj.subject && obj.subject != "(none)")
    return obj.subject
  else
    return ""
}

export function getTaskSimpleName(translator: GearsTranslator, formInfo: FormInfo) {
  const documentation = getTaskDocumentation({translator, form: formInfo.processForm})
  const processKey    = formInfo.processDefinition.key
  return translator.toTaskShortTitle(processKey, documentation.nr, { variables: documentation })
}

export function getTaskName(translator: GearsTranslator, task: Task) {
  const processKey    = task?.processDefinition?.key as string
  const documentation = getTaskDocumentation({translator, form: {isStartForm: false,  task}})

  return translator.toTaskTitle(processKey, documentation.nr, { variables: documentation } )
}

export function getTaskDescription(translator: GearsTranslator, task: Task) {
  const documentation = getTaskDocumentation({translator, form: {isStartForm: false,  task}})
  const processKey = task?.processDefinition?.key as string
  // const fallback   = createShortTaskDescription(translator, taskLike)
  const fallback = createTaskDescription(translator, task)
  return translator.toTaskDescription(processKey, documentation.nr, { variables: documentation , fallback: fallback});
}

export function getForName(translator: GearsTranslator, task: Task) {
  const processKey = task?.processDefinition?.key as string
  const forName    = task?.documentation?.for
  return forName ? translator.toActorName(processKey, forName) : undefined
}

