import DashboardBanner from './DashboardBanner';
import SidebarLayout from 'components/layout/common/SidebarLayout';
import { Box, useTheme } from '@mui/material';
import { useLayoutContext } from 'hooks/layout';
import Logo from "components/common/Logo"
import { DashboardSidebarFooter, DashboardSidebarItems } from 'components/layout/dashboard/DashboardSidebar';
import { Outlet } from 'react-router';

const DashboardLayout = () => {
  const theme = useTheme()

  console.debug("Dashboard loading")
  return (
    <SidebarLayout 
      name='main-layout'
      withBanner={true}
      side={'left'}
      sidebarBanner={<SidebarBanner/>}
      sidebarBody={<SidebarBody/>}
      sidebarBodyFooter={<DashboardSidebarFooter/>}
      contentBanner={<ContentBanner/>}
      bannerProps={{
        height: theme.components?.banner?.height,
        shortcut: "Backslash"
      }}
      sidebarProps={{
        shortcut: "BracketLeft"
      }}
    >
      <Outlet/>
    </SidebarLayout>
  )
};

const SidebarBanner = () => {
  const { sidebarState, bannerState, props: { transitionTime } } = useLayoutContext()

  return (
    <Box 
      id='logo-image-container'
      sx={{position: "absolute", 
        display: "flex",
        alignItems: "center",
        justifyContent: sidebarState.expandState == "collapsed" ? "center" : "start",
        marginLeft: bannerState == "expanded" && sidebarState.expandState == "expanded" ? "10px" : "0px",
        left: bannerState == "expanded" || sidebarState.expandState == "collapsed" ? "0" : "50%", 
        transform: bannerState == "expanded" || sidebarState.expandState == "collapsed" ? "translateX(0)": "translateX(-50%)", 
        transition: `all ${transitionTime} ease`, 
        display: "flex", 
        maxWidth: "100%", 
        maxHeight: "100%",  
        height: "100%",
        padding: "3px"
      }}
    >
      <BannerLogo />
    </Box>
  )
}

const BannerLogo = (props) => {
  const {sidebarState, props: {transitionTime}} = useLayoutContext()

  const theme     = useTheme()
  const href      = theme.components.logo.href || window.location.origin
  const src       = theme.components.logo.src

  const hasSmall  = !!theme.components.logoSmall
  const srcSmall  = theme.components.logoSmall?.src
  const hrefSmall = theme.components.logoSmall?.href || href
  
  const onClick = (e, href) => {
    e?.stopPropagation()
    e?.preventDefault()
    window.location.href = href
  }
  
  return (
    <>
      { hasSmall
        ?
        <Logo
            {...props}
            onClick={e => onClick(e, hrefSmall)}
            src={srcSmall}
            style={{
              cursor: "pointer",
              opacity: sidebarState.expandState != "expanded" ? 1 : 0,
              objectFit: 'contain',
              transition: `all ${transitionTime} ease, opacity 1s linear`, 
              maxWidth: sidebarState.expandState != "expanded" ? "42px" : "0px",
              height: "100%",
              width: "auto"
            }}
          />
        : null
      }
      <Logo
        {...props}
        onClick={e => onClick(e, href)}
        src={src}
        style={{
          cursor: "pointer",
          opacity: !hasSmall || sidebarState.expandState == "expanded" ? 1 : 0,
          objectFit: 'contain',
          maxWidth: !hasSmall || sidebarState.expandState == "expanded" ? "100%" : "0%",
          transition: `all ${transitionTime} ease, opacity 1s linear`, 
          height: "100%",
          width: "auto"
        }}
      />
    </>
  )
}

const SidebarBody = () => {
  return (
    <DashboardSidebarItems/>
  )
}


const ContentBanner = () => {
  const theme                           = useTheme()
  const {height, ...bannerSx}           = theme.components.banner

  const color = bannerSx.backgroundColor || bannerSx.background
  return (
    <Box 
      sx={{
        background: color,
        height: "100%", 
        width: "100%",
      }}
    >
      <DashboardBanner onMobileNavOpen={() => setMobileNavOpen(true)} />
    </Box>
  )
}

export default DashboardLayout;
