import { useContext } from 'react';
import { SetState } from 'types/react';

import { WindowPageContext, WindowPageProps, WindowPageState } from '../contexts/WindowPageContext';
import { PaneProps } from '../types';

interface WindowContextProps extends WindowPageProps,WindowPageState {}

export const useWindowPageContext = (): WindowContextProps => {
  const ctx = useContext(WindowPageContext)
  if (!ctx?.update){
    window.alert("The WindowPageProvider is not used ")
    return {} as WindowContextProps 
  }

  return {
    ...ctx,
    get pages() { return ctx.ref.current.pages },
    get setPages(): SetState<PaneProps[]> { return ctx.ref.current.setPages },
    get data(): object { return ctx.ref.current.data }
  }
}
