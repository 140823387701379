import _ from "lodash";
import merge from "deepmerge";

export function createDataLoader(client, requestInfo, toTableRow) {
  var cancelled = false;
  return (page, errorCallback, dataCallback) => {
    client.query(createRequest(requestInfo, page))
      .then(
        result => {
          if (cancelled) return
          //console.debug("dataLoader: result=%o", result)
          const count = _.get(result, requestInfo.countPath)
          const data = _.get(result, requestInfo.dataPath)
          const rows = data ? data.map((row) => toTableRow(row)) : []
          if (cancelled) return
          dataCallback({ rows, count, page })
        },
        error => {
          if (cancelled) return
          errorCallback("Failed to get table data for %s: %o", requestInfo.description, error.message)
        }
      )
      .catch(reason => {
        if (cancelled) return
        errorCallback("A Frontend Issue occured while processing the table data", reason)
      })
      .finally(() => {
        // done succesfully or with an error
      })
    return {
      cancel: () => { 
        console.debug("dataLoader: cancelling request")
        cancelled = true 
      }
    }
  }
}

function createRequest(requestInfo, page) {
  const effectiveFilter = page.filterType === "local" || page.filter?.length === 0 ? null : page.filter
  return merge.all([
    requestInfo,
    {
      variables: {
        filter: effectiveFilter,
        start: page.pageNum * page.pageSize,
        count: page.pageSize
      }
    }
  ])
}