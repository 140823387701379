import TileInfoButton from 'components/process/TileInfoButton';
import { StartProcessFunction, TranslatedProcessDefinition } from 'hooks/process';

import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';

export const ProcessCard = ({ process, onStartProcess }: { process: TranslatedProcessDefinition, onStartProcess: StartProcessFunction }) => {

  const handleStartProcess = (e: any) => {
    onStartProcess(e, process, "PROCESS_OPEN");
  };

  return (
    <Card
      elevation={4}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        ".process-info-button": {
          opacity: 0
        },
        ":hover": {
          ".process-info-button": {
            opacity: 1
          }
        }
      }}
    >
      <CardActionArea
        id={process.key}
        sx={{ width: '100%', height: '100%', justifyContent: 'center' }}
        onClick={handleStartProcess}
      >
        <CardContent
          sx={{ padding: 1.5, width: '100%', height: '100%', display: "flex", flexDirection: 'column', justifyContent: 'center' }}
        >
          <Typography
            justifyContent="center"
            align="center"
            color="textPrimary"
            variant="h5"
            fontStyle="normal"
          >
            {process.title}
          </Typography>

          {process.description && process.description.toLowerCase() !== "null"
            ? <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}> <TileInfoButton title={process.title} description={process.description} /> </Box>
            : null}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProcessCard;
