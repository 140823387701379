import { TableCell }   from "@mui/material"
import { RenderValue } from "components/render"
import Types           from "types/types"

const RenderTableCell = ({basedOn, rowIndex, columnIndex, ...options}) => {
  const sx = Types.isTable(basedOn) ? { padding: 0 } : {}

  return (
    <TableCell {...options} sx={sx} based-on={`based-on-cell-${rowIndex}-${columnIndex}`} >
      <RenderValue>
        {basedOn}
      </RenderValue>
    </TableCell>
  )
}

export default RenderTableCell
