import React, { createContext } from 'react';

import { BoxProps } from '@mui/material';

import { PageComponent } from '../components/PageComponent';
import { WindowManagerConfig } from '../types';

// the window manager
export type WMPageComponent = React.FC<BoxProps> 

interface WindowManagerContextProps extends WindowManagerConfig {
  pageComponent: WMPageComponent// React.ReactElement<BoxProps, any>
}

export interface WindowManagerProviderProps extends WindowManagerConfig {
  children: React.ReactNode,
}

export const WindowManagerContext = createContext<WindowManagerContextProps>({} as WindowManagerContextProps);

export const WindowManagerProvider = ({children, ...props}: WindowManagerProviderProps): JSX.Element => {
  const config: WindowManagerContextProps = {...props, 
    pageComponent: props.pageComponent || PageComponent,
  }

  return (
    <React.Fragment>
      <WindowManagerContext.Provider value={config}>
        {children}
      </WindowManagerContext.Provider>
    </React.Fragment>
  )
}


