import { Box, Typography } from '@mui/material'
import { useFormInfo }     from 'hooks/form'

const TaskTitle = () => {
  const formInfo = useFormInfo()
  return (
    <Box sx={{mt: 1}}>
      <Typography variant="h2">{formInfo.title}</Typography>
    </Box>
  )
}

export default TaskTitle
